@charset "utf-8";

#EventComponent {
	// @media only screen and (max-width : 1023px) {
		width: 100vw;
		height: 100vh;
		overflow-x: hidden;
		overflow-y: auto;

		.BannerWrap img {
			width: 100%;
		}

		.GiftWrap {
			text-align: center;
		}
		.GiftWrap img {
			width: 33%;
		}

		.Container {
			min-width: 90%;
			max-width: 90%;
			scroll-behavior: smooth;
			-webkit-overflow-scrolling: touch;
			overflow: hidden;
			margin: 5vw auto;
			position: relative;
		}
		.TitleWrap {
			text-align: center;
			margin-top: 5vw;
		}
		.TitleWrap p {
			font-size: 7vw;
			color: #f6453d;
			font-weight: 700;
		}
		.DescWrap {
			min-width: 90%;
			max-width: 90%;
			text-align: center;
			margin: 5vw auto;
		}
		.DescWrap p {
			font-size: 3.5vw;
			line-height: 5vw;
			color: #333;
			word-break: keep-all;
		}
		.DescWrap p.TopDesc {
			font-size: 4.2vw;
			font-weight: 700;
			margin-bottom: 7vw;
		}
		.DescWrap p.BottomDesc {
			font-size: 4vw;
			font-weight: 700;
			margin-top: 7vw;
		}
		.DescWrap p a {
			text-decoration: underline;
			text-underline-offset: 3px;
		}
		.Row {
			width: 100%;
			display: flex;
			flex-direction: column;
			margin-top: 4vw;
		}
		.Row.Checkbox {
			flex-direction: row;
		}
		.Row .Title {
			line-height: 9vw;
			color: #333;
			font-size: 3.8vw;
			font-weight: 400;
			letter-spacing: -1px;
		}
		.Row .Input,
		.Row .Select {
			width: 100%;
			height: 11vw;
			background-color: rgba(241, 241, 241, 0.8);
			display: flex;
			justify-content: center;
			border-radius: 0px;
		}
		.Row .Input input,
		.Row .Select select {
			width: 100%;
			height: 100%;
			font-size: 3.8vw;
			color: #000;
			outline: none;
			border: none;
			background-color: rgba(0, 0, 0, 0);
			margin: 0 3vw;
		}
		.Row .Select select {
			background-image:
				linear-gradient(45deg, transparent 50%, #333 50%),
				linear-gradient(135deg, #333 50%, transparent 50%);
			background-position:
				calc(100% - 8px) calc(100% - 5.5vw + 5px),
				calc(100%) calc(100% - 5.5vw + 5px);
			background-size:
				8px 8px,
				8px 8px;
			background-repeat: no-repeat;
		}
		.Row .Second {
			margin-top: 1vw;
		}
		.Row .Group {
			display: flex;
		}
		.Row .Group .Input {
			width: 70%;
		}
		.Row .Group .Input input:read-only {
			color: #a1a1a1;
		}
		.Row .Group button {
			width: 30%;
			color: #fff;
			font-size: 3.5vw;
			font-weight: 600;
			transition: all 0.3s ease;
		}
		.Row .Group button {
			background-color: #f9423a;
		}
		.Row .Group button:disabled {
			background-color: #c1c1c1;
		}

		p.Warning {
			font-weight: 600;
			color: #f6453d;
			text-align: center;
		}

		.Checkbox {
			margin-top: 5vw !important;
		}
		.Checkbox .Label {
			position: relative;
		}
		.Checkbox .Checkmark {
			position: absolute;
			top: 50%;
			left: 0;
			width: 4vw;
			height: 4vw;
			transform: translateY(-50%);
			background-color: #fff;
			border: 1px solid #000;
		}
		.Checkbox .Checkmark:after {
			position: absolute;
			top: 0.8vw;
			left: 0.8vw;
			width: 2.4vw;
			height: 2.4vw;
			background-color: #000;
		}
		.Checkbox .Label {
			color: #333;
			font-size: 3.8vw;
			font-weight: 400;
			position: relative;
			padding-left: 7vw;
			cursor: pointer;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
		.Checkbox .Label input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
		}
		.Checkbox .Label .Checkmark:after {
			content: "";
			position: absolute;
			display: none;
		}
		.Checkbox .Label input:checked ~ .Checkmark:after {
			display: block;
		}
		.Checkbox .PrivacyLink {
			font-size: 3.8vw;
			font-weight: 700;
			text-decoration: underline;
			margin-left: 3vw;
			color: #303030;
			cursor: pointer;
		}
		.BtnArea button {
			width: 100%;
			height: 11vw;
			color: #fff;
			font-size: 4.5vw;
			font-weight: 600;
			transition: all 0.3s ease;
		}
		.BtnArea button.Submit {
			background-color: #f9423a;
		}

		.LayerWrap {
			z-index: 10;
			display: block;
			position: fixed;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			text-align: center;
			background-color: rgba(0, 0, 0, 0.5);
			visibility: hidden;
			opacity: 0;
			-webkit-transition: opacity 0.5s ease, visibility 0.5s ease;
			transition: opacity 0.5s ease, visibility 0.5s ease;
		}
		.LayerWrap.on {
			visibility: visible;
			opacity: 1;
			-webkit-transition: opacity 0.5s ease, visibility 0.5s ease;
			transition: opacity 0.5s ease, visibility 0.5s ease;
		}
		.LayerWrap:before {
			content: "";
			display: inline-block;
			height: 100%;
			vertical-align: middle;
		}
		.PopLayer .BtnClose {
			background-image: url("../images/franchise/m/btn-close.png");
			background-repeat: no-repeat;
			background-size: 50%;
			background-position: center;
			position: absolute;
			width: 9vw;
			height: 9vw;
			top: 2vw;
			right: 2vw;
		}
		.PopLayer {
			display: inline-block;
			vertical-align: middle;
			background-color:#fff;
			border: 4px solid #001f32;
			z-index: 10;
			position: relative;
		}
		.PopLayer .PopContainer {
			position: absolute;
			top: 38%;
			transform: translateY(-38%);
			left: 6vw;
			right: 6vw;
			text-align: center;
			color: #303030;
			word-break: keep-all;
			letter-spacing: -1px;
		}

		#Layer .PopLayer {
			width: 91vw;
			height: 90vw;
		}
		#Layer .PopLayer .PopContainer {
			font-size: 4vw;
			line-height: 5.5vw;
		}
		#Layer .PopLayer .PopContainer p.Title {
			font-size: 5vw;
			font-weight: 700;
		}
		#Layer .PopLayer .PopContainer p.Sub {
			margin: 3vw 0;
		}
		#Layer .PopLayer .PopContainer p.Desc span {
			font-weight: 700;
		}
		#Layer .PopLayer .BtnGroup {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 5vw;
		}
		#Layer .PopLayer .BtnGroup button {
			width: 25vw;
			height: 10vw;
			color: #fff;
			font-size: 4vw;
			font-weight: 600;
			margin: 0 1vw;
			background-color: #001f32;
		}

		#Dialog .PopLayer {
			position: relative;
			width: 91vw;
			min-height: 50vw;
		}
		#Dialog .PopLayer .PopContainer {
			left: 0;
			right: 0;
			font-size: 5vw;
		}
		#Dialog .PopLayer .PopContainer p.Msg {
			font-weight: 700;
		}
		#Dialog .PopLayer .BtnGroup {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 5vw;
		}
		#Dialog .PopLayer .BtnGroup button {
			width: 25vw;
			height: 10vw;
			color: #fff;
			font-size: 4vw;
			font-weight: 600;
			margin: 0 1vw;
		}
		#Dialog .PopLayer .BtnGroup button.Ok {
			background-color: #001f32;
		}
		#Dialog .PopLayer .BtnGroup button.Cancel {
			background-color: #333;
			display: none;
		}
		#Dialog .PopLayer .BtnGroup button {
			filter: invert(10%);
		}
		#Dialog .PopLayer .BtnGroup button.Cancel.Confirm {
			display: inline-block;
		}

		.PostcodeLayer {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: rgba(0,0,0,0.5);
		}
		.PostcodeLayer .PostcodeContainer {
			position: absolute;
			width: 95vw !important;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			display: flex;
			flex-direction: column;
			align-items: flex-end;
		}
		.PostcodeLayer .BtnClose {
			background-image: url("../images/franchise/m/btn-close.png");
			background-repeat: no-repeat;
			background-size: 50%;
			background-position: center;
			width: 13vw;
			height: 13vw;
			filter: brightness(0) invert(1);
		}
	// }
}