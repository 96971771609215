@charset "utf-8";

#BrandComponent {
	@media only screen and (min-width: 1024px) {

		.StoreList {
			min-height: 1030px;
		}
		.StoreList .SubContainer {
			overflow: hidden;
			max-width: 1000px;
			min-width: 1000px;
		}

		.StoreList .TitleWrap {
			display: inline-block;
			width: 100%;
			margin-top: 150px;
			position: relative;
		}
		.StoreList .TitleWrap p.Title {
			float: left;
			font-size: 52px;
			font-weight: 700;
			line-height: 52px;
			color: #142436;
			letter-spacing: -2px;
		}
		.StoreList .TitleWrap ul {
			position: absolute;
			right: 0;
			bottom: 0;
		}
		.StoreList .TitleWrap ul li {
			display: inline-block;
			font-size: 0;
			cursor: pointer;
		}
		.StoreList .TitleWrap ul li:not(:first-child) {
			margin-left: 45px;
		}
		.StoreList .TitleWrap ul li a {
			color: #142436;
			display: inline-block;
			text-align: center;	
			font: normal 24px 'Noto Sans KR';
			text-transform: uppercase;
			font-weight: 400;
		}
		.StoreList .TitleWrap ul li a:hover,
		.StoreList .TitleWrap ul li.Select a {
			color: #142436;
			font-weight: 700;
			-webkit-transition: color 0.5s ease;
			transition: color 0.5s ease;
		}
		.StoreList .TitleWrap ul li a::before {
			display: block;
			content: attr(title);
			font-weight: 400;
			height: 0;
			overflow: hidden;
			visibility: hidden;
		}

		.StoreList .ListView {
			margin-bottom: 150px;
		}
		.StoreList .ListWrap {
			width: 100%;
			margin-top: 20px;
			border-top: 2px solid #152435;
		}
		.StoreList .ListWrap .SubMenu {
			text-align: center;
			line-height: 115px;
		}
		.StoreList .ListWrap .SubMenu button {
			font-size: 18px;
			font-weight: 500;
			margin: 0 10px;
			color: #666;
		}
		.StoreList .ListWrap .SubMenu button:hover,
		.StoreList .ListWrap .SubMenu button.Select {
			color: #f5453d;
		}

		.StoreList .ListWrap ul {
			margin-top: 55px;
			display: block;
			clear: both;
		}
		.StoreList .ListWrap ul li {
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			width: 322px;
			height: 215px;
			margin-bottom: 17px;
			cursor: pointer;
			float: left;
			margin-right: 17px;
		}
		.StoreList .ListWrap ul li:nth-child(3n) {
			margin-right: 0;
		}
		.StoreList .ListWrap ul li.shop {
			background-color: #ef483e;
		}
		.StoreList .ListWrap ul li.install {
			background-color: #6a46ac;
		}
		.StoreList .ListWrap ul li .TypeBox {
			background-color: #fff;
			width: 50px;
			height: 45px;
			text-align: center;
			position: absolute;
			top: 0;
			left: 0;
		}
		.StoreList .ListWrap ul li .TypeBox div {
			font-size: 12px;
			font-weight: 600;
			padding-top: 40%;
		}
		.StoreList .ListWrap ul li .TypeBox.shop div,
		.StoreList .ListWrap ul li .Go.shop span {
			color: #ef483e;
		}
		.StoreList .ListWrap ul li .TypeBox.install div,
		.StoreList .ListWrap ul li .Go.install span {
			color: #6a46ac;
		}
		.StoreList .ListWrap ul li .TitleBox {
			text-align: center;
			color: #fff;
		}
		.StoreList .ListWrap ul li .TitleBox .Title {
			font-size: 14px;
			font-weight: 600;
		}
		.StoreList .ListWrap ul li .TitleBox .StoreName {
			font-size: 34px;
			font-weight: 700;
		}
		.StoreList .ListWrap ul li .Desc {
			color: #ffff9c;
			font-size: 12px;
			font-weight: 700;
			position: absolute;
			bottom: 18px;
			text-align: center;
			left: 0;
			right: 0;
		}
		.StoreList .ListWrap ul li .Go {
			background-color: #ffff9c;
			width: 42px;
			height: 42px;
			border-radius: 100%;
			position: absolute;
			right: 14px;
			bottom: 14px;
			text-align: center;
		}
		.StoreList .ListWrap ul li .Go span {
			font-size: 14px;
			font-weight: 700;
			line-height: 45px;
		}
		
		.StoreList .BtnWrap {
			text-align: center;
		}
		.StoreList .ListView button.More {
			width: 190px;
			height: 52px;
			border: 1px solid #142436;
			font-size: 18px;
			font-weight: 500;
			color: #142436;
			margin-top: 50px;
			-webkit-transition: all 0.3s ease;
			transition: all 0.3s ease;
		}
		.StoreList .ListView button.More:hover {
			background-color: #142436;
			color: #fff;
			border: 1px solid #fff;
			-webkit-transition: all 0.3s ease;
			transition: all 0.3s ease;
		}
		.StoreList .ListView .Nodata {
			font-size: 20px;
			width: 100%;
			text-align: center;
			font-weight: 600;
			padding: 50px 0;
		}

		.StorePopLayer {
			position: absolute;
			top: 50%;
			left: 50%;
			right: 0;
			transform: translate(-50%, -50%);
			background-color:#fff;
			z-index: 10;
			max-width: 900px;
			width: 900px;
			height: 90vh;
			overflow-y: auto;
			-ms-overflow-style: none; /* IE and Edge */
			scrollbar-width: none; /* Firefox */
		}
		.StorePopLayer::-webkit-scrollbar {
			display: none; /* Chrome, Safari, Opera*/
		}

		.StorePopLayer .TopBg {
			width: 100%;
			height: 540px;
			position: relative;
		}
		.StorePopLayer .TopBg img.Bg {
			height: 540px;
		}
		.StorePopLayer .TopBg .ThumbnailWrap {
			position: absolute;
			right: 73px;
			bottom: -53px;
			height: 94px;
		}
		.StorePopLayer .TopBg .ThumbnailWrap.Two {
			width: 267px;
		}
		.StorePopLayer .TopBg .ThumbnailWrap.Three {
			width: 400px;
		}
		.StorePopLayer .TopBg .ThumbnailWrap .Thumbnail {
			height: 94px;
		}
		.StorePopLayer .TopBg .ThumbnailWrap .Thumbnail img {
			width: 98%;
			height: 100%;
		}
		.StorePopLayer .TopBg .ThumbnailWrap .Thumbnail img.Active {
			outline: 2px solid #f5453d;
  		outline-offset: -2px;
		}
		.StorePopLayer .ContentWrap {
			width: 100%;
			overflow: hidden;
		}
		.StorePopLayer .Top {
			display: flex;
			height: 70px;
			position: absolute;
			top: 470px;
			left: 0;
		}
		.StorePopLayer .Top .Left {
			width: 300px;
			height: 100%;
			background-color: #fff;
			text-align: left;
			display: flex;
			align-items: flex-end;
		}
		.StorePopLayer .Top .Left span {
			margin-left: 73px;
			font-size: 16px;
			font-weight: 600;
		}
		.StorePopLayer .Top .Right {
			width: 0px;
			height: 0px;
			border-top: 70px solid transparent;
			border-left: 70px solid #fff;
		}
		.StorePopLayer .StoreName {
			height: 55px;
			margin-left: 73px;
			text-align: left;
			font-weight: 700;
		}
		.StorePopLayer .StoreName span:nth-child(2) {
			font-size: 39px;
			letter-spacing: -3px;
			line-height: 70px;
		}
		.StorePopLayer .StoreName span:nth-child(3) {
			margin-left: 15px;
			font-size: 16px;
			letter-spacing: 3px;
		}
		.StorePopLayer .Main {
			margin: 84px 73px 0 73px;
		}
		.StorePopLayer .Main .ContentRow {
			display: flex;
			flex-direction: row;
			align-items: center;
			padding: 22px 0;
		}
		.StorePopLayer .Main .ContentRow .Title {
			flex: 2.5;
			text-align: left;
			font-size: 20px;
			font-weight: 700;
		}
		.StorePopLayer .Main .ContentRow .Content {
			flex: 7.5;
			text-align: left;
			font-size: 20px;
			font-weight: 500;
			color: #4b4b4b;
		}
		.StorePopLayer .Main .ContentRow .Content p,
		.StorePopLayer .Main .ContentRow .Content span {
			font-size: 20px !important;
			color: #4b4b4b !important;
			font-weight: 500 !important;
		}
		.StorePopLayer .Map {
			margin-top: 62px;
			height: 600px;
		}
		.StorePopLayer .Logo {
			width: 100%;
			height: 130px;
			background-image: url("../images/store/popup-logo.png");
			background-position: center;
			background-repeat: no-repeat;
			background-size: auto;
		}
		.StorePopLayer .BtnClose {
			position: absolute;
			width: 30px;
			height: 30px;
			top: 40px;
			right: 30px;
			background-image: url("../images/store/popup-close.png");
			z-index: 9;
		}
		.StorePopLayer .BtnClose:hover {
			top: 39px;
			right: 29px;
		}

		.StorePopLayer.shop .Top .Left span,
		.StorePopLayer.shop .StoreName,
		.StorePopLayer.shop .Main .ContentRow .Title {
			color: #f0493e;
		}
		.StorePopLayer.shop .Main .MainWrap {
			border-top: 2px solid #f0493e;
			border-bottom: 1px solid #f0493e;
		}
		.StorePopLayer.shop .Main .ContentRow {
			border-bottom: 1px solid #f0493e;
		}
		.StorePopLayer.shop .Map {
			background-color: #f0493e;
		}

		.StorePopLayer.install .Top .Left span,
		.StorePopLayer.install .StoreName,
		.StorePopLayer.install .Main .ContentRow .Title {
			color: #6a46ac;
		}
		.StorePopLayer.install .Main .MainWrap {
			border-top: 2px solid #6a46ac;
			border-bottom: 1px solid #6a46ac;
		}
		.StorePopLayer.install .Main .ContentRow {
			border-bottom: 1px solid #6a46ac;
		}
		.StorePopLayer.install .Map {
			background-color: #6a46ac;
		}

		#Footer .container {
			min-width: 1320px;
			max-width: 1320px;
		}
	}
	
	@media only screen and (max-width : 1023px) {
		.PC { display: none !important; }

		.StoreList {
			margin-bottom: 15vw;
		}
		.StoreList .SubContainer {
			overflow: hidden;
			max-width: 96vw;
			min-width: 96vw;
		}

		.StoreList .TitleWrap {
			display: inline-block;
			width: 100%;
			margin-top: 10vw;
			position: relative;
		}
		.StoreList .TitleWrap p.Title {
			float: left;
			font-size: 6.5vw;
			font-weight: 700;
			line-height: 7.5vw;
			color: #142436;
			letter-spacing: -2px;
		}
		.StoreList .TitleWrap ul {
			position: absolute;
			right: 0;
			bottom: 0;
		}
		.StoreList .TitleWrap ul li {
			display: inline-block;
			font-size: 0;
			cursor: pointer;
		}
		.StoreList .TitleWrap ul li:not(:first-child) {
			margin-left: 5vw;
		}
		.StoreList .TitleWrap ul li a {
			color: #142436;
			display: inline-block;
			text-align: center;	
			font: normal 4vw 'Noto Sans KR';
			text-transform: uppercase;
			font-weight: 400;
		}
		.StoreList .TitleWrap ul li a:hover,
		.StoreList .TitleWrap ul li.Select a {
			color: #f5453d;//#142436;
			font-weight: 700;
			-webkit-transition: color 0.5s ease;
			transition: color 0.5s ease;
		}
		.StoreList .TitleWrap ul li a::before {
			display: block;
			content: attr(title);
			font-weight: 400;
			height: 0;
			overflow: hidden;
			visibility: hidden;
		}

		.StoreList .ListWrap {
			width: 100%;
			margin: 2vw 0 8vw 0;
			border-top: 2px solid #152435;
		}
		.StoreList .ListWrap .SubMenu {
			text-align: center;
			line-height: 13.8vw;
		}
		.StoreList .ListWrap .SubMenu button {
			font-size: 3.5vw;
			font-weight: 600;
			margin: 0 2vw;
			color: #666;
		}
		.StoreList .ListWrap .SubMenu button:hover,
		.StoreList .ListWrap .SubMenu button.Select {
			color: #f5453d;
		}

		.StoreList .ListWrap ul {
			margin-top: 3vw;
			display: block;
			clear: both;
		}
		.StoreList .ListWrap ul li {
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			width: 49%;
			height: 32vw;
			cursor: pointer;
			float: left;
			margin-right: 2%;
			margin-bottom: 2%;
		}
		.StoreList .ListWrap ul li:nth-child(2n) {
			margin-right: 0;
		}
		.StoreList .ListWrap ul li.shop {
			background-color: #ef483e;
		}
		.StoreList .ListWrap ul li.install {
			background-color: #6a46ac;
		}
		.StoreList .ListWrap ul li .TypeBox {
			background-color: #fff;
			width: 9vw;
			height: 7vw;
			text-align: center;
			position: absolute;
			top: 0;
			left: 0;
		}
		.StoreList .ListWrap ul li .TypeBox div {
			font-size: 2vw;
			font-weight: 600;
			line-height: 8vw;
		}
		.StoreList .ListWrap ul li .TypeBox.shop div,
		.StoreList .ListWrap ul li .Go.shop span {
			color: #ef483e;
		}
		.StoreList .ListWrap ul li .TypeBox.install div,
		.StoreList .ListWrap ul li .Go.install span {
			color: #6a46ac;
		}
		.StoreList .ListWrap ul li .TitleBox {
			text-align: center;
			color: #fff;
		}
		.StoreList .ListWrap ul li .TitleBox .Title {
			font-size: 2.5vw;
			font-weight: 600;
			line-height: 3vw;
		}
		.StoreList .ListWrap ul li .TitleBox .StoreName {
			font-size: 5vw;
			font-weight: 700;
			margin: 1vw 0;
		}
		.StoreList .ListWrap ul li .Desc {
			color: #ffff9c;
			font-size: 1.8vw;
			font-weight: 700;
			position: absolute;
			bottom: 2vw;
			text-align: center;
			left: 0;
			right: 0;
		}
		.StoreList .ListWrap ul li .Go {
			background-color: #ffff9c;
			width: 7vw;
			height: 7vw;
			border-radius: 100%;
			position: absolute;
			right: 2vw;
			bottom: 2vw;
			text-align: center;
		}
		.StoreList .ListWrap ul li .Go span {
			font-size: 2.5vw;
			font-weight: 700;
			line-height: 7vw;
		}
		
		.StoreList .BtnWrap {
			width: 100%;
			display: inline-block;
			text-align: center;
		}
		.StoreList .BtnWrap button.More {
			margin: 0 100%;
			width: 35vw;
			height: 12vw;
			border: 1px solid #142436;
			font-size: 5vw;
			font-weight: 500;
			color: #142436;
			margin: 4vw 0 0 0;
			-webkit-transition: all 0.3s ease;
			transition: all 0.3s ease;
		}
		.StoreList .ListView button.More:hover {
			background-color: #142436;
			color: #fff;
			border: 1px solid #fff;
			-webkit-transition: all 0.3s ease;
			transition: all 0.3s ease;
		}
		.StoreList .ListView .Nodata {
			font-size: 20px;
			width: 100%;
			text-align: center;
			font-weight: 600;
			padding: 50px 0;
		}

		.StorePopLayer {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color:#fff;
			z-index: 99;
			overflow-y: auto;
			-ms-overflow-style: none; /* IE and Edge */
			scrollbar-width: none; /* Firefox */
		}
		.StorePopLayer::-webkit-scrollbar {
			display: none; /* Chrome, Safari, Opera*/
		}

		.StorePopLayer .TopBg {
			width: 100%;
			height: 65vw;
			position: relative;
		}
		.StorePopLayer .TopBg img.Bg {
			height: 65vw;
		}
		.StorePopLayer .TopBg .ThumbnailWrap {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			top: 57vw;
			height: 20vw;
		}
		.StorePopLayer .TopBg .ThumbnailWrap.Two {
			width: 54vw;
		}
		.StorePopLayer .TopBg .ThumbnailWrap.Three {
			width: 81vw;
		}
		.StorePopLayer .TopBg .ThumbnailWrap .Thumbnail {
			height: 20vw;
		}
		.StorePopLayer .TopBg .ThumbnailWrap .Thumbnail img {
			width: 98%;
			height: 100%;
		}
		.StorePopLayer .TopBg .ThumbnailWrap .Thumbnail img.Active {
			outline: 2px solid #f5453d;
  		outline-offset: -2px;
		}
		.StorePopLayer .ContentWrap {
			width: 100%;
			overflow: hidden;
		}
		.StorePopLayer .StoreName.ThumbMargin {
			margin-top: 18vw;
		}
		.StorePopLayer .StoreName {
			text-align: center;
			font-weight: 700;
			margin-top: 5vw;
		}
		.StorePopLayer .StoreName p {
			font-size: 3.2vw;
			font-weight: 600;
		}
		.StorePopLayer .StoreName span:nth-child(2) {
			font-size: 10vw;
		}
		.StorePopLayer .StoreName span:nth-child(3) {
			font-size: 4vw;
			display: block;
		}
		.StorePopLayer .Main {
			margin: 5vw 5vw 0 5vw;
		}
		.StorePopLayer .Main .ContentRow {
			display: flex;
			flex-direction: row;
			align-items: center;
			padding: 5vw 0;
		}
		.StorePopLayer .Main .ContentRow .Title {
			flex: 2;
			text-align: left;
			font-size: 4vw;
			font-weight: 700;
		}
		.StorePopLayer .Main .ContentRow .Content {
			flex: 8;
			text-align: left;
			font-size: 4vw;
			font-weight: 500;
			color: #4b4b4b;
		}
		.StorePopLayer .Main .ContentRow .Content p,
		.StorePopLayer .Main .ContentRow .Content span {
			font-size: 20px !important;
			color: #4b4b4b !important;
			font-weight: 500 !important;
		}
		.StorePopLayer .Map {
			margin-top: 8vw;
			height: 70vw;
		}
		.StorePopLayer .Logo {
			width: 100%;
			height: 20vw;
			background-image: url("../images/store/popup-logo.png");
			background-position: center;
			background-repeat: no-repeat;
			background-size: 40%;
		}
		.StorePopLayer .BtnClose {
			position: absolute;
			width: 10vw;
			height: 10vw;
			top: 5vw;
			right: 5vw;
			background-image: url("../images/store/popup-close.png");
			background-repeat: no-repeat;
			background-position: center;
			z-index: 9;
		}
		.StorePopLayer .BtnClose:hover {
			top: 5vw;
			right: 5vw;
		}

		.StorePopLayer.shop .Top .Left span,
		.StorePopLayer.shop .StoreName,
		.StorePopLayer.shop .Main .ContentRow .Title {
			color: #f0493e;
		}
		.StorePopLayer.shop .Main .MainWrap {
			border-top: 2px solid #f0493e;
			border-bottom: 1px solid #f0493e;
		}
		.StorePopLayer.shop .Main .ContentRow {
			border-bottom: 1px solid #f0493e;
		}
		.StorePopLayer.shop .Map {
			background-color: #f0493e;
		}

		.StorePopLayer.install .Top .Left span,
		.StorePopLayer.install .StoreName,
		.StorePopLayer.install .Main .ContentRow .Title {
			color: #6a46ac;
		}
		.StorePopLayer.install .Main .MainWrap {
			border-top: 2px solid #6a46ac;
			border-bottom: 1px solid #6a46ac;
		}
		.StorePopLayer.install .Main .ContentRow {
			border-bottom: 1px solid #6a46ac;
		}
		.StorePopLayer.install .Map {
			background-color: #6a46ac;
		}

		#Footer .container {
			min-width: 96vw;
			max-width: 96vw;
		}
	}
}
