@charset "utf-8";

#FranchiseNewComponent {
	@media only screen and (min-width: 1024px) {
		.Header {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 95px;
			background-color: #fff;
			border-bottom: 1px solid #eeeeee;
			z-index: 9;
			font-weight: 600;
			font-family: 'Pretendard';
		}
		.Header .HeaderArea {
			min-width: 1200px;
			max-width: 1200px;
			height: 100%;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		.Header .HeaderArea .Logo {
			width: 210px;
		}
		.Header .HeaderArea .MenuWrap {
			height: 100%;
			float: right;
			display: table;
		}
		.Header .HeaderArea .MenuWrap ul {
			display: table-cell;
			vertical-align: middle;
			padding-left: 0 !important;
		}
		.Header .HeaderArea .MenuWrap li {
			display: inline-block;
			font-size: 0;
			cursor: pointer;
		}
		.Header .HeaderArea .MenuWrap li a {
			display: inline-block;
			text-align: right;	
			font-size: 22px;
			text-transform: uppercase;
			text-decoration: none !important;
		}
		.Header .HeaderArea .MenuWrap li a {
			color: #142436;
			-webkit-transition: color 0.5s ease;
			transition: color 0.5s ease;
		}
		.Header .HeaderArea .MenuWrap li a::before {
			display: block;
			content: attr(title);
			height: 0;
			overflow: hidden;
			visibility: hidden;
		}
		.Header .HeaderArea .MenuWrap li:last-child {
			margin-left: 20px;
		}
		.Header .HeaderArea .MenuWrap li:hover a, .Header .HeaderArea .MenuWrap li.select a {
			color: #f5453d;
			-webkit-transition: color 0.5s ease;
			transition: color 0.5s ease;
			text-decoration: none !important;
		}
	
		@media only screen and (max-width : 1320px) {

		}
	}
	
	@media only screen and (max-width : 1023px) {
		.Header {
			position: fixed;
			top: 0;
			width: 100%;
			background-color: #fff;;
			z-index: 9;
			font-family: 'Pretendard';
			font-weight: 600;
		}
		.Header .HeaderArea {
			min-width: 100vw;
			max-width: 100vw;
			height: 100%;
			margin: 0 auto;
		}
	
		.Header .HeaderArea .LogoLink {
			width: 100vw;
			height: 12vw;
			border-bottom: 2px solid #eeeeee;
			cursor: pointer;
			display: flex;
			justify-content: center;
		}
		.Header .HeaderArea a.LogoLink img.Logo {
			width: 40%;
			object-fit: contain;
		}

		.Header .HeaderArea .MenuWrap ul {
			width: 100%;
			display: table;
			line-height: 13vw;
			padding: 0 3vw !important;
			margin-bottom: 0 !important;
		}
		.Header .HeaderArea .MenuWrap li {
			display: table-cell;
			width: 33.33%;
			font-size: 4vw;
			cursor: pointer;
			text-align: center;
		}
		.Header .HeaderArea .MenuWrap li a {
			color: #142436;
			-webkit-transition: color 0.5s ease;
			transition: color 0.5s ease;
			text-decoration: none !important;
		}
		.Header .HeaderArea .MenuWrap li:hover a, .Header .HeaderArea .MenuWrap li.select a {
			color: #f5453d;
			-webkit-transition: color 0.5s ease;
			transition: color 0.5s ease;
		}
	}
}
