@charset "utf-8";

#FranchiseNewComponent {
	@media only screen and (min-width: 1024px) {

		.MOBILE { display: none !important; }

		.Container {
			overflow-y: auto;
			// scroll-snap-type: y mandatory;
			width: 100%;
			height: 100vh;
			scroll-behavior: smooth;
			-webkit-overflow-scrolling: touch;
		}

		.SubContainer {
			min-width: 1024px;
			max-width: 1120px;
			height: 100%;
			overflow-y: hidden;
			margin: 0 auto;
			position: relative;
		}

		#Card {
			margin-top: 0;
		}
		

		// .css-1fzpoyk:nth-child(1) > .CardItem div,
		// .css-1fzpoyk:nth-child(5) > .CardItem div {
		// 	opacity: 0.8;
		// }
		// .css-1fzpoyk:nth-child(2) > .CardItem div,
		// .css-1fzpoyk:nth-child(4) > .CardItem div {
		// 	opacity: 0.9;
		// }

		// .css-1fzpoyk:nth-child(1) {
		// 	left: 10%;
		// 	transform: translateY(-50%) translateX(-77%) scale(0.77);
		// }
		// .css-1fzpoyk:nth-child(5) {
		// 	left: 90%;
		// 	transform: translateY(-50%) translateX(-66.6667%) scale(0.666667);
		// }
		.css-1fzpoyk {
			// opacity: 1 !important;
		}

		.Banner {
			background-image: url("../images/franchise/banner.png");
			background-repeat: no-repeat;
			background-size: contain;
			position: fixed;
			top: 110px;
			right: 0;
			width: 184px;
			height: 170px;
			z-index: 8;
			cursor: pointer;
		}
		.Banner2 {
			background-image: url("../images/franchise/banner2.png");
			background-repeat: no-repeat;
			background-size: contain;
			position: fixed;
			top: 290px;
			right: 0;
			width: 184px;
			height: 170px;
			z-index: 8;
			cursor: pointer;
		}

		.Home {
			position: relative;
		}
		.Home .TitleWrap {
			width: 100vw;
		}
		.Home .ArrowLeft {
			left: 20px;
		}
		.Home .ArrowRight {
			right: 20px;
		}
		.Home .ArrowLeft,
		.Home .ArrowRight {
			position: absolute;
			top: 50%;
			transform: translate(0, -50%);
			width: 60px;
			height: 60px;
			background-color: rgba(255, 255, 255, 0.15);
			border-radius: 100%;
			z-index: 10;
			transition: background-color 0.3s ease-in-out;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.Home .ArrowLeft:hover,
		.Home .ArrowRight:hover {
			background-color: rgba(255, 255, 255, 0.5);
		}
		.Home ul.NavContainer {
			position: absolute;
			bottom: 20px;
			left: 50%;
			transform: translateX(-50%);
			z-index: 3;
		}
		.Home ul.NavContainer li {
			float: left;
			margin-right: 26px;
		}
		.Home ul.NavContainer li:last-child {
			margin-right: 12px;
		}
		.Home ul.NavContainer li button {
			width: 36px;
			height: 36px;
			border-radius: 36px;
			border: none;
			background: rgb(255,51,51);
			opacity: 0.3;
			-webkit-transition: opacity 0.5s ease-in-out;
			transition: opacity 0.5s ease;
		}
		.Home ul.NavContainer li button.active {
			width: 36px;
			height: 36px;
			border-radius: 36px;
			border: none;
			background: rgb(255,51,51);
			opacity: 1;
			-webkit-transition: opacity 0.5s ease;
			transition: opacity 0.5s ease;
		}

		.Consulting .MainContent {
			width: 100%;
			height: 550px;
			margin-top: 60px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: start;
		}
		.Consulting .MainContent .Request,
		.Consulting .MainContent .Privacy {
			width: 48%;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			gap: 30px;
		}
		.Consulting .MainContent .Request .Content,
		.Consulting .MainContent .Privacy .Content {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
		}
		.Consulting .MainContent .Request p.MainTitle {
			font-weight: 700;
			color: rgb(255,51,51);
		}
		.Consulting .MainContent .Request p.MainTitle span {
			font-weight: 400;
			color: rgb(255,51,51);
		}
		.Consulting .MainContent .Request .Row {
			width: 100%;
			display: flex;
			justify-content: start;
			align-items: center;
			padding: 10px 0;
		}
		.Consulting .MainContent .Request .Row:last-child {
			padding-bottom: 0;
		}
		.Consulting .MainContent .Request .Row .Title {
			min-width: 190px;
			color: #333;
			font-weight: 500;
			text-align: left;
		}
		.Consulting .MainContent .Request .Row .Input {
			width: 100%;
			height: 100%;
		}
		.Consulting .MainContent .Request .Row .Input input {
			border: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(241, 241, 241, 0.8);
			font-size: 16px;
			color: #000;
			padding: 10px 20px;
			outline: none;
		}
		.Consulting .MainContent .Request .Row .Radio {
			color: #333;
			font-weight: 300;
			letter-spacing: -2px;
		}
		.Consulting .MainContent .Request .Row .Radio label {
			line-height: 2.2rem;
		}
		.Consulting .MainContent .Request .Row .Radio label:not(:last-child) {
			margin-right: 20px;
		}
		.Consulting .Radio .Checkmark {
			position: absolute;
			top: 7px;
			left: 3px;
			width: 22px;
			height: 22px;
			border-radius: 50%;
			background-color: #fff;
			border: 1px solid #000;
		}
		.Consulting .Radio .Checkmark:after {
			position: absolute;
			top: 4px;
			left: 4px;
			width: 12px;
			height: 12px;
			border-radius: 50%;
			background-color: #000;
			border: 1px solid #000;
		}
		.Consulting .Radio .Label {
			position: relative;
			padding-left: 35px;
			cursor: pointer;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
		.Consulting .Radio .Label input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
		}
		.Consulting .Radio .Label .Checkmark:after {
			content: "";
			position: absolute;
			display: none;
		}
		.Consulting .Radio .Label input:checked ~ .Checkmark:after {
			display: block;
		}
		.Consulting .MainContent .Request .Row textarea {
			width: 100%;
			height: 130px;
			background-color: rgba(241, 241, 241, 0.8);
			color: #000;
			padding: 15px 18px;
			outline: none;
			border: none;
		}
		.Consulting .MainContent .Privacy p.MainTitle {
			font-weight: 600;
			color: #000;
		}
		.Consulting .MainContent .Privacy .Content .Row {
			width: 100%;
		}
		.Consulting .MainContent .Privacy .Content .Row p {
			color: #333;
			font-weight: 400;
			word-break: keep-all;
		}
		.Consulting .MainContent .Privacy .Content .Row p span {
			font-weight: 600;
		}
		.Consulting .Privacy .Checkmark {
			position: absolute;
			top: 4px;
			left: 3px;
			width: 22px;
			height: 22px;
			background-color: #fff;
			border: 1px solid #000;
		}
		.Consulting .Privacy .Checkmark:after {
			position: absolute;
			top: 4px;
			left: 4px;
			width: 12px;
			height: 12px;
			background-color: #000;
			border: 1px solid #000;
		}
		.Consulting .Privacy .Label {
			color: #333;
			font-weight: 600;
			line-height: 1.7rem;
			position: relative;
			padding-left: 35px;
			cursor: pointer;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
		.Consulting .Privacy .Label input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
		}
		.Consulting .Privacy .Label .Checkmark:after {
			content: "";
			position: absolute;
			display: none;
		}
		.Consulting .Privacy .Label input:checked ~ .Checkmark:after {
			display: block;
		}
		.Consulting .Privacy .BtnArea {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}
		.Consulting .Privacy .BtnArea button {
			width: 48%;
			text-align: center;
			color: #fff;
			font-weight: 600;
			transition: all 0.3s ease;
		}
		.Consulting .Privacy .BtnArea button:nth-child(1) {
			background: rgb(255,51,51);
		}
		.Consulting .Privacy .BtnArea button:nth-child(1):hover {
			background: rgb(255,51,51);
		}
		.Consulting .Privacy .BtnArea button:nth-child(2) {
			background-color: #999;
		}
		.Consulting .Privacy .BtnArea button:nth-child(2):hover {
			background-color: #7d7b7b;
		}

		.LayerWrap {
			z-index: 2010;
			display: block;
			position: fixed;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			text-align: center;
			background-color: rgba(0, 0, 0, 0.5);
			visibility: hidden;
			opacity: 0;
			-webkit-transition: opacity 0.5s ease, visibility 0.5s ease;
			transition: opacity 0.5s ease, visibility 0.5s ease;
		}
		.LayerWrap.on {
			visibility: visible;
			opacity: 1;
			-webkit-transition: opacity 0.5s ease, visibility 0.5s ease;
			transition: opacity 0.5s ease, visibility 0.5s ease;
		}
		.LayerWrap:before {
			content: "";
			display: inline-block;
			height: 100%;
			vertical-align: middle;
		}
		.PopLayer .BtnClose {
			background-image: url("../images/franchise/m/btn-close.png");
			background-repeat: no-repeat;
			background-size: 50%;
			background-position: center;
			position: absolute;
			width: 50px;
			height: 50px;
			top: 10px;
			right: 10px;
		}
		.PopLayer {
			display: inline-block;
			vertical-align: middle;
			background-color:#fff;
			border: 4px solid #001f32;
			z-index: 10;
			position: relative;
		}
		.PopLayer .PopContainer {
			position: absolute;
			top: 38%;
			transform: translateY(-38%);
			left: 6vw;
			right: 6vw;
			text-align: center;
			color: #303030;
			word-break: keep-all;
			letter-spacing: -1px;
		}

		#Layer .PopLayer {
			width: 800px;
			height: 500px;
		}
		#Layer .PopLayer .PopContainer {
			font-size: 26px;
		}
		#Layer .PopLayer .PopContainer p.Title {
			font-size: 36px;
			font-weight: 700;
		}
		#Layer .PopLayer .PopContainer p.Desc {
			text-align: left;
		}
		#Layer .PopLayer .PopContainer p.Desc span {
			font-weight: 700;
		}

		#Dialog .PopLayer {
			position: relative;
			width: 500px;
			min-height: 300px;
		}
		#Dialog .PopLayer .PopContainer {
			left: 0;
			right: 0;
			font-size: 30px;
			line-height: 40px;
		}
		#Dialog .PopLayer .PopContainer p.Msg {
			font-weight: 700;
		}
		#Dialog .PopLayer .BtnGroup {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 40px;
		}
		#Dialog .PopLayer .BtnGroup button {
			width: 150px;
			height: 50px;
			color: #fff;
			font-size: 25px;
			font-weight: 600;
			margin: 0 15px;
		}
		#Dialog .PopLayer .BtnGroup button.Ok {
			background-color: #001f32;
		}
		#Dialog .PopLayer .BtnGroup button.Cancel {
			background-color: #333;
			display: none;
		}
		#Dialog .PopLayer .BtnGroup button {
			filter: invert(10%);
		}
		#Dialog .PopLayer .BtnGroup button.Cancel.Confirm {
			display: inline-block;
		}

		@media only screen and (max-width : 1120px) {
			.Header .HeaderArea,
			.SubContainer {
				min-width: 95%;
				max-width: 95%;
			}

			.Competitive .Wrap .Content {
				width: 310px;
			}
			.Competitive .Wrap .Content .ContentWrap .DescWrap p:first-child {
				font-size: 21px;
				line-height: 21px;
			}
			.Competitive .Wrap .Content .ContentWrap .DescWrap p:last-child {
				font-size: 14px;
			}

			// .Machine .ContentWrap {
			// 	width: 475px;
			// }
			// .Machine .ContentWrap .Content .Desc p.Title {
			// 	font-size: 13px;
			// line-height: 18px;
			// }
			// .Machine .ContentWrap .Content .Desc p {
			// 	font-size: 13px;
			// }

			// .Open .ContentWrap .Row .RowContent .Content .Detail {
			// 	font-size: 18px;
			// 	top: 20px;
			// 	right: 20px;
			// 	bottom: 20px;
			// 	left: 20px;
			// }
		}
	}

	@media only screen and (max-width : 1023px) {
		.PC { display: none !important; }

		// .Anchor {
		// 	display: block;
		// 	visibility: hidden;
		// 	height: 25.2vw;
		// 	margin-top: -25.2vw;
		// }
		// .Anchor:first-child {
		// 	margin-top: 0;
		// }

		// .Container {
		// 	overflow-y: auto;
		// 	width: 100%;
		// 	height: 100vh;
		// 	scroll-behavior: smooth;
		// 	-webkit-overflow-scrolling: touch;
		// }

		// .SubContainer {
		// 	min-width: 91%;
		// 	max-width: 91%;
		// 	height: 100%;
		// 	overflow: hidden;
		// 	margin: 0 auto;
		// 	position: relative;
		// }

		// .MainContent {
		// 	width: 100%;
		// 	height: 100%;
		// 	overflow: hidden;
		// 	margin: 0 auto;
		// 	position: relative;
		// }

		// .Banner {
		// 	background-image: url("../images/franchise/m/banner.png");
		// 	background-repeat: no-repeat;
		// 	background-size: contain;
		// 	position: fixed;
		// 	top: 30vw;
		// 	right: 0;
		// 	width: 25vw;
		// 	height: 22vw;
		// 	z-index: 8;
		// 	opacity: 0.9;
		// 	cursor: pointer;
		// }
		// .Banner2 {
		// 	background-image: url("../images/franchise/m/banner.png");
		// 	background-repeat: no-repeat;
		// 	background-size: contain;
		// 	position: fixed;
		// 	top: 45vw;
		// 	right: 0;
		// 	width: 25vw;
		// 	height: 22vw;
		// 	z-index: 8;
		// 	opacity: 0.9;
		// 	cursor: pointer;
		// }

		// .Header {
		// 	position: fixed;
		// 	top: 0;
		// 	width: 100%;
		// 	background-color: #fff;
		// 	z-index: 9;
		// }
		// .Header .HeaderArea {
		// 	min-width: 100vw;
		// 	max-width: 100vw;
		// 	height: 100%;
		// 	margin: 0 auto;
		// }
		// .Header .HeaderArea .LogoLink {
		// 	width: 100vw;
		// 	height: 12vw;
		// 	border-bottom: 2px solid #eeeeee;
		// 	cursor: pointer;
		// 	display: flex;
		// 	justify-content: center;
		// }
		// .Header .HeaderArea a.LogoLink img.Logo {
		// 	width: 60%;
		// 	object-fit: contain;
		// }

		// .Header .HeaderArea .MenuWrap ul {
		// 	width: 100%;
		// 	display: table;
		// 	line-height: 13vw;
		// }
		// .Header .HeaderArea .MenuWrap li {
		// 	display: table-cell;
		// 	width: 25%;
		// 	font-size: 4vw;
		// 	font-weight: 600;
		// 	cursor: pointer;
		// 	text-align: center;
		// }
		// .Header .HeaderArea .MenuWrap li:first-child {
		// 	display: none;
		// }
		// .Header .HeaderArea .MenuWrap li:not(:last-child) a {
		// 	color: #142436;
		// 	-webkit-transition: color 0.5s ease;
		// 	transition: color 0.5s ease;
		// }
		// .Header .HeaderArea .MenuWrap li:not(:last-child):hover a, .Header .HeaderArea .MenuWrap li:not(:last-child).select a {
		// 	color: #f5453d;
		// 	-webkit-transition: color 0.5s ease;
		// 	transition: color 0.5s ease;
		// }
		// .Header .HeaderArea .MenuWrap li:last-child a {
		// 	color: #fff;
		// }
		// .Header .HeaderArea .MenuWrap li:last-child a span {
		// 	background-color: #142435;
		// 	text-align: center;
		// 	margin: 0;
		// 	padding: 0 2px;
		// 	-webkit-transition: background-color 0.3s linear;
		// 	transition: background-color 0.3s linear;
		// }
		// .Header .HeaderArea .MenuWrap li:last-child:hover a span {
		// 	background-color: #f5453d;
		// 	-webkit-transition: background-color 0.3s linear;
		// 	transition: background-color 0.3s linear;
		// }

		.Home {
			position: relative;
		}
		.Home .TitleWrap {
			width: 100vw;
		}
		.Home .ArrowLeft {
			left: 2vw;
		}
		.Home .ArrowRight {
			right: 2vw;
		}
		.Home .ArrowLeft,
		.Home .ArrowRight {
			position: absolute;
			top: 50%;
			transform: translate(0, -50%);
			width: 10vw;
			height: 10vw;
			background-color: rgba(255, 255, 255, 0.15);
			border-radius: 100%;
			z-index: 99999;
			transition: background-color 0.3s ease-in-out;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.Home .ArrowLeft:hover,
		.Home .ArrowRight:hover {
			background-color: rgba(255, 255, 255, 0.5);
		}
		.Home ul.NavContainer {
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			z-index: 3;
		}
		.Home ul.NavContainer li {
			float: left;
			margin-right: 3vw;
		}
		.Home ul.NavContainer li:last-child {
			margin-right: 2vw;
		}
		.Home ul.NavContainer li button {
			width: 5vw;
			height: 5vw;
			border-radius: 100%;
			border: none;
			background: rgb(255,51,51);
			opacity: 0.3;
			-webkit-transition: opacity 0.5s ease-in-out;
			transition: opacity 0.5s ease;
		}
		.Home ul.NavContainer li button.active {
			width: 5vw;
			height: 5vw;
			border-radius: 100%;
			border: none;
			background: rgb(255,51,51);
			opacity: 1;
			-webkit-transition: opacity 0.5s ease;
			transition: opacity 0.5s ease;
		}

		// .Why .nav-tabs .nav-item:nth-child(1) .nav-link {
		// 	border-top-left-radius: 10px;
		// 	border-top-right-radius: 0;
		// 	border-right: 0;
		// }
		// .Why .nav-tabs .nav-item:nth-child(2) .nav-link {
		// 	border-top-right-radius: 10px;
		// 	border-top-left-radius: 0;
		// }
		// .Why .nav-tabs .nav-link {
		// 	color: var(--bs-primary);
		// 	border : 1px solid var(--bs-primary);
		// 	width: 25rem;
		// }
		// .Why .nav-tabs .nav-link:nth-child(1) {
		// }
		// .Why .nav-tabs .nav-link:nth-child(2) {
		// }
		// .Why .nav-tabs .nav-link.active {
		// 	background-color: #e9481e;
		// 	color: #fff;
		// 	border: 1px solid var(--bs-primary);
		// }
		// .Why .nav-tabs .nav-link:hover {
		// 	border: 1px solid var(--bs-primary);
		// }
		// .Why #whyTabContent .why-circle {
		// 	width: 40rem;
		// 	height: 5rem;
		// }
		// .Why #whyTabContent .why-circle:nth-child(1) {
		// 	line-height: 5rem;
		// }


		.Competitive {
			position: relative;
			margin: 10vw 0 15vw 0;
		}
		.Competitive .Wrap {
			display: flex;
			flex-direction: column;
		}
		.Competitive .Wrap .TitleWrap p {
			font-size: 7vw;
			color: #142435;
			font-weight: 700;
			text-align: center;
			letter-spacing: -1px;
			word-break: keep-all;
		}
		.Competitive .Wrap .TitleWrap:last-child p {
			font-size: 5.5vw;
		}
		.Competitive .Wrap .TitleWrap p span {
			color: #f5453d;
		}
		.Competitive .Wrap .TitleWrap:last-child {
			margin-top: 10vw;
		}
		.Competitive .Wrap .Content {
			display: flex;
			flex-direction: column;
		}
		.Competitive .Wrap .Content .ContentWrap {
			margin-top: 8vw;
		}
		.Competitive .Wrap .Content .ContentWrap .ImgWrap {
			position: relative;
		}
		.Competitive .Wrap .Content .ContentWrap .ImgWrap img {
			width: 100%;
		}
		.Competitive .Wrap .Content .ContentWrap .ImgWrap .ImgBg {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			background-color: rgb(0, 0, 0);
			opacity: 0.4;
			transition: opacity 0.5s;
		}
		.Competitive .Wrap .Content .ContentWrap .DescWrap {
			margin-top: 5vw;
		}
		.Competitive .Wrap .Content .ContentWrap .DescWrap p.Title {
			color: #142435;
			font-size: 4.8vw;
			line-height: 6.5vw;
			font-weight: 700;
			letter-spacing: -1px;
			text-align: center;
			word-break: keep-all;
		}
		.Competitive .Wrap .Content .ContentWrap .DescWrap p.Title span {
			color: #f6453d;
		}
		.Competitive .Wrap .Content .ContentWrap .DescWrap p.Desc {
			margin-top: 3vw;
			color: #142435;
			font-size: 4vw;
			line-height: 6vw;
			font-weight: 500;
			word-break: break-word;
			text-align: justify;
		}

		// .Machine {
		// 	position: relative;
		// 	height: 210vw;
		// 	background-color: #fff;
		// }
		// .Machine .Bg {
		// 	background-color: #142435;
		// 	position: absolute;
		// 	left: 0;
		// 	right: 0;
		// 	top: 0;
		// 	bottom: 86vw;
		// }
		// .Machine .SubContainer {
		// 	height: 100%;
		// 	position: relative;
		// 	text-align: center;
		// }
		// .Machine .MainContent {
		// 	min-width: 90%;
		// 	max-width: 90%;
		// }
		// .Machine .TitleWrap {
		// 	margin-top: 11vw;
		// }
		// .Machine .TitleWrap p:first-child {
		// 	font-size: 8.8vw;
		// 	line-height: 9.2vw;
		// 	color: #fff;
		// 	font-weight: 700;
		// }
		// .Machine .TitleWrap p:first-child span {
		// 	color: #f5453d;
		// }
		// .Machine .TitleWrap p:last-child {
		// 	margin-top: 4vw;
		// 	font-size: 3.8vw;
		// 	line-height: 6vw;
		// 	color: #fff;
		// 	font-weight: 400;
		// 	word-break: keep-all;
		// }
		// .Machine .DotArea {
		// 	position: absolute;
		// 	left: 0;
		// 	right: 0;
		// 	bottom: 123vw;
		// }
		// .Machine .DotArea button {
		// 	width: 5vw;
		// 	height: 5vw;
		// 	border-radius: 50%;
		// 	background-color: #f6453d;
		// 	opacity: 0.4;
		// 	margin: 0 2vw;
		// 	transition: opacity 0.5s ease;
		// }
		// .Machine .DotArea button.Active {
		// 	opacity: 1;
		// }
		// .Machine .Slide {
		// 	width: 100%;
		// 	height: 120vw;
		// 	position: absolute;
		// 	left: 0;
		// 	right: 0;
		// 	bottom: 0;
		// }
		// .Machine .ContentWrap {
		// 	width: 100%;
		// 	height: 100%;
		// }
		// .Machine .ContentWrap .Content {
		// 	background-color: #fff;
		// 	background-position: center;
		// 	background-repeat: no-repeat;
		// 	background-size: cover;
		// 	position: relative;
		// 	display: table;
		// }
		// .Machine .Slide .Content.Coffee1 {
		// 	width: 100%;
		// 	height: 48vw;
		// 	background-image: url("../images/landing/cafero.png");
		// 	background-size: 60%;
		// }
		// .Machine .Slide .Content.Coffee2 {
		// 	width: 100%;
		// 	height: 48vw;
		// 	background-image: url("../images/landing/release.png");
		// 	background-size: 90%;
		// 	background-position: 50% -10%;
		// }
		// .Machine .Slide .Content.Celeb {
		// 	width: 100%;
		// 	height: 48vw;
		// 	background-image: url("../images/landing/celeb.png");
		// 	background-size: 70%;
		// }
		// .Machine .ContentWrap .Content .TitleWrap {
		// 	position: absolute;
		// 	bottom: 0;
		// 	width: 100%;
		// 	height: 14vw;
		// 	background-color: rgba(0, 0,0, 0.8);
		// 	padding-left: 8vw;
		// 	text-align: left;
		// 	display: table;
		// }
		// .Machine .ContentWrap .Content .TitleWrap .Title {
		// 	display: table-cell;
		// 	vertical-align: middle;
		// 	font-size: 4vw;
		// 	color: #fff;
		// 	font-weight: 600;
		// 	line-height: 4.5vw;
		// }
		// .Machine .ContentWrap .Content .TitleWrap .Title span {
		// 	color: #f6453d;
		// 	font-size: 3.5vw;
		// }

		// .Machine .ContentWrap .Desc {
		// 	margin-top: 6vw;
		// 	text-align: left;
		// }
		// .Machine .ContentWrap .Desc p:not(.Sub) {
		// 	font-size: 3.5vw;
		// 	line-height: 5.5vw;
		// 	color: #001f32;
		// 	font-weight: 400;
		// 	margin-left: 4vw;
		// }
		// .Machine .ContentWrap .Desc p.Sub {
		// 	font-size: 3.8vw;
		// 	line-height: 5vw;
		// 	color: #001f32;
		// 	font-weight: 600;
		// 	margin-bottom: 4vw;
		// 	word-break: keep-all;
		// }

		.Price .InstallWrap {
			margin-top: 15vw;
		}
		.Price .InstallWrap .Table .TableRow .TableCell:first-child {
			width: 22%;
		}
		.Price .InstallWrap .Table .TableRow:nth-child(2) {
			background-color: #f1f1f1;
			width: auto;
		}
		.Price .InstallWrap .Table .TableRow:nth-child(2) .TableCell {
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
		}
		.Price .InstallWrap .Table .TableRow:nth-child(2) .TableCell button span {
			font-size: 2vw;
		}
		.Price .InstallWrap .Table .TableRow:nth-child(2) .TableCell img {
			width: 100%;
			height: auto;
			object-fit: cover;
		}
		.Price .InstallWrap .Table .TableRow:nth-child(3) {
			background-color: #fdeceb;
			height: 15vw;
		}
		.Price .InstallWrap .Table .TableRow:nth-child(4) {
			background-color: #fbc7c5;
			height: 15vw;
		}
		.Price .InstallWrap .Table .TableRow:last-child .TableCell:first-child p {
			display: inline;
		}

		.Open {
			position: relative;
			height: 290vw;
			background-color: #fff;
		}
		.Open .Bg {
			background-color: #efefef;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 26vw;
		}
		.Open .SubContainer {
			height: 100%;
			position: relative;
		}
		.Open .TitleWrap {
			text-align: center;
			margin-top: 12vw;
		}
		.Open .TitleWrap p:first-child {
			color: #142435;
			font-size: 9vw;
			line-height: 9vw;
			font-weight: 700;
		}
		.Open .TitleWrap p:last-child {
			color: #000;
			font-size: 4.4vw;
			font-weight: 400;
			margin-top: 3vw;
		}
		.Open .ContentWrap {
			margin-top: 4vw;
			width: 100%;
			height: 100%;
		}
		.Open .ContentWrap .Row .RowContent {
			position: relative;
			width: 50%;
			height: 60vw;
			float: left;
		}
		.Open .ContentWrap .Row .RowContent .Content {
			position: absolute;
			background-color: #fff;
			border: 1px solid #142435;
			top: 4vw;
			bottom: 0;
		}
		.Open .ContentWrap .Row .RowContent:nth-child(1) .Content {
			left: 0;
			right: 2vw;
		}
		.Open .ContentWrap .Row .RowContent:nth-child(2) .Content {
			left: 2vw;
			right: 0;
		}
		.Open .ContentWrap .Row .RowContent .Content .Detail {
			margin: 2vw 4vw;
		}
		.Open .ContentWrap .Row .RowContent .Content .Detail .Top {
			text-align: center;
		}
		.Open .ContentWrap .Row .RowContent .Content .Detail .Top .Img {
			display: inline-block;
			width: 16vw;
			height: 16vw;
			border-radius: 50%;
			background-color: #f5f5f5;
			background-position: center;
			background-repeat: no-repeat;
			background-size: 60%;
		}
		.Open .Row:nth-child(1) .RowContent:nth-child(1) .Img {
			background-image: url("../images/franchise/img-openstep1.png");
		}
		.Open .Row:nth-child(1) .RowContent:nth-child(2) .Img {
			background-image: url("../images/franchise/img-openstep2.png");
		}
		.Open .Row:nth-child(2) .RowContent:nth-child(1) .Img {
			background-image: url("../images/franchise/img-openstep3.png");
		}
		.Open .Row:nth-child(2) .RowContent:nth-child(2) .Img {
			background-image: url("../images/franchise/img-openstep4.png");
		}
		.Open .Row:nth-child(3) .RowContent:nth-child(1) .Img {
			background-image: url("../images/franchise/img-openstep5.png");
		}
		.Open .Row:nth-child(3) .RowContent:nth-child(2) .Img {
			background-image: url("../images/franchise/img-openstep6.png");
		}
		.Open .Row:nth-child(4) .RowContent:nth-child(1) .Img {
			background-image: url("../images/franchise/img-openstep7.png");
		}
		.Open .Row:nth-child(4) .RowContent:nth-child(2) .Img {
			background-image: url("../images/franchise/img-openstep8.png");
		}

		.Open .ContentWrap .Row .RowContent .Content .Detail .Top,
		.Open .ContentWrap .Row .RowContent .Content .Detail .Bottom {
			height: 50%;
		}
		.Open .ContentWrap .Row .RowContent .Content .Detail .Top p.Title {
			text-align: left;
			font-size: 3.5vw;
			color: #f5453d;
			font-weight: 700;
		}
		.Open .ContentWrap .Row .RowContent .Content .Detail .Bottom p.Title {
			font-size: 3.8vw;
			color: #142435;
			font-weight: 700;
			line-height: 5.3vw;
		}
		.Open .ContentWrap .Row .RowContent .Content .Detail .Bottom p.Desc {
			font-size: 3.1vw;
			color: #142435;
			font-weight: 400;
			line-height: 4.8vw;
		}
		

		.Consulting .MainContent {
			width: 100%;
			margin-top: 60px;
			display: flex;
			flex-direction: column;
			justify-content: start;
			align-items: center;
			gap: 10vw;
		}
		.Consulting .MainContent .Request,
		.Consulting .MainContent .Privacy {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: start;
			gap: 10px;
		}
		.Consulting .MainContent .Request .Content,
		.Consulting .MainContent .Privacy .Content {
			display: flex;
			flex-direction: column;
			justify-content: start;
			align-items: center;
		}
		.Consulting .MainContent .Request p.MainTitle {
			font-weight: 700;
			color: rgb(255,51,51);
		}
		.Consulting .MainContent .Request p.MainTitle span {
			font-weight: 400;
			color: rgb(255,51,51);
		}
		.Consulting .MainContent .Request .Row {
			width: 100%;
			display: flex;
			justify-content: start;
			align-items: center;
			padding: 10px 0;
		}
		.Consulting .MainContent .Request .Row:last-child {
			padding-bottom: 0;
		}
		.Consulting .MainContent .Request .Row .Title {
			min-width: 30%;
			color: #333;
			font-weight: 500;
			text-align: left;
		}
		.Consulting .MainContent .Request .Row .Input {
			width: 100%;
			height: 100%;
		}
		.Consulting .MainContent .Request .Row .Input input {
			border: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(241, 241, 241, 0.8);
			color: #000;
			padding: 5px 10px;
			outline: none;
		}
		.Consulting .MainContent .Request .Row .Radio {
			color: #333;
			font-weight: 300;
			letter-spacing: -2px;
		}
		.Consulting .MainContent .Request .Row .Radio label {
			line-height: 2.2rem;
		}
		.Consulting .MainContent .Request .Row .Radio label:not(:last-child) {
			margin-right: 2rem;
		}
		.Consulting .Radio .Checkmark {
			position: absolute;
			top: 10px;
			left: 8px;
			width: 16px;
			height: 16px;
			border-radius: 50%;
			background-color: #fff;
			border: 1px solid #000;
		}
		.Consulting .Radio .Checkmark:after {
			position: absolute;
			top: 4px;
			left: 4px;
			width: 6px;
			height: 6px;
			border-radius: 50%;
			background-color: #000;
			border: 1px solid #000;
		}
		.Consulting .Radio .Label {
			position: relative;
			padding-left: 35px;
			cursor: pointer;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
		.Consulting .Radio .Label input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
		}
		.Consulting .Radio .Label .Checkmark:after {
			content: "";
			position: absolute;
			display: none;
		}
		.Consulting .Radio .Label input:checked ~ .Checkmark:after {
			display: block;
		}
		.Consulting .MainContent .Request .Row textarea {
			width: 100%;
			height: 130px;
			background-color: rgba(241, 241, 241, 0.8);
			color: #000;
			padding: 15px 18px;
			outline: none;
			border: none;
		}
		.Consulting .MainContent .Privacy p.MainTitle {
			font-weight: 600;
			color: #000;
		}
		.Consulting .MainContent .Privacy .Content .Row {
			width: 100%;
		}
		.Consulting .MainContent .Privacy .Content .Row p {
			color: #333;
			font-weight: 400;
			word-break: keep-all;
		}
		.Consulting .MainContent .Privacy .Content .Row p span {
			font-weight: 600;
		}
		.Consulting .Privacy .Checkmark {
			position: absolute;
			top: 5px;
			left: 8px;
			width: 16px;
			height: 16px;
			background-color: #fff;
			border: 1px solid #000;
		}
		.Consulting .Privacy .Checkmark:after {
			position: absolute;
			top: 4px;
			left: 4px;
			width: 6px;
			height: 6px;
			background-color: #000;
			border: 1px solid #000;
		}
		.Consulting .Privacy .Label {
			color: #333;
			font-weight: 600;
			line-height: 1.7rem;
			position: relative;
			padding-left: 35px;
			cursor: pointer;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
		.Consulting .Privacy .Label input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
		}
		.Consulting .Privacy .Label .Checkmark:after {
			content: "";
			position: absolute;
			display: none;
		}
		.Consulting .Privacy .Label input:checked ~ .Checkmark:after {
			display: block;
		}
		.Consulting .Privacy .BtnArea {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}
		.Consulting .Privacy .BtnArea button {
			width: 48%;
			text-align: center;
			color: #fff;
			font-weight: 600;
			transition: all 0.3s ease;
		}
		.Consulting .Privacy .BtnArea button:nth-child(1) {
			background: rgb(255,51,51);
		}
		.Consulting .Privacy .BtnArea button:nth-child(1):hover {
			background: rgb(255,51,51);
		}
		.Consulting .Privacy .BtnArea button:nth-child(2) {
			background-color: #999;
		}
		.Consulting .Privacy .BtnArea button:nth-child(2):hover {
			background-color: #7d7b7b;
		}


		.Presentation {
			height: 240vw;
			border-top: 1px solid #efefef;
		}
		.Presentation .TitleWrap {
			text-align: center;
			margin-top: 8vw
		}
		.Presentation .TitleWrap p:first-child {
			font-size: 9vw;
			line-height: 9vw;
			color: #f6453d;
			font-weight: 700;
		}
		.Presentation .TitleWrap p:last-child {
			font-size: 3.5vw;
			line-height: 6.5vw;
			color: #303030;
			font-weight: 400;
		}
		.Presentation .MainContent {
			width: 100%;
		}
		.Presentation .MainContent .Request {
			width: 100%;
		}
		.Presentation .MainContent .Request .Content {
			margin-top: 4vw;
		}
		.Presentation .MainContent .Request .Row {
			width: 100%;
			height: 9vw;
			margin-bottom: 2vw;
		}
		.Presentation .MainContent .Request .Row .Title,
		.Presentation .MainContent .Request .Row .Input {
			float: left;
		}
		.Presentation .MainContent .Request .Row .Title {
			width: 25%;
			line-height: 9vw;
			color: #333;
			font-size: 3.8vw;
			font-weight: 400;
			letter-spacing: -1px;
		}
		.Presentation .MainContent .Request .Row .Input {
			width: 75%;
			height: 100%;
		}
		.Presentation .MainContent .Request .Row .Input input {
			border: 0;
			width: 65vw;
			height: 100%;
			background-color: rgba(241, 241, 241, 0.8);
			font-size: 3.8vw;
			color: #000;
			padding: 0 2vw;
			outline: none;
			display: inline-block;
		}
		.Presentation .MainContent .Request .Row .Select {
			width: 75%;
			height: 100%;
			background-color: rgba(241, 241, 241, 0.8);
			display: flex;
			justify-content: center;
		}
		.Presentation .MainContent .Request .Row .Select select {
			width: 97%;
			height: 100%;
			font-size: 3.8vw;
			color: #000;
			outline: none;
			border: none;
			background-color: rgba(0, 0, 0, 0);
		}
		.Presentation .Calendar .react-calendar { 
			width: 100%;
			// height: 469px;
			border: 1px solid #e1e1e1;
			display: flex;
			flex-direction: column;
		}
		.Presentation .Calendar .react-calendar .react-calendar__navigation {
			margin-bottom: 0;
			height: 10vw;
		}
		.Presentation .Calendar .react-calendar .react-calendar__navigation .react-calendar__navigation__prev2-button,
		.Presentation .Calendar .react-calendar .react-calendar__navigation .react-calendar__navigation__next2-button {
			display: none;
		}
		.Presentation .Calendar .react-calendar .react-calendar__navigation .react-calendar__navigation__prev-button {
			background-image: url("../images/landing/arrow-down.png");
			background-repeat: no-repeat;
			background-size: 40%;
			background-position: center;
			transform: rotate(0.25turn);
			// color: #fff;
		}
		.Presentation .Calendar .react-calendar .react-calendar__navigation .react-calendar__navigation__next-button {
			background-image: url("../images/landing/arrow-down.png");
			background-repeat: no-repeat;
			background-size: 40%;
			background-position: center;
			transform: rotate(0.75turn);
			// color: #fff;
		}
		.Presentation .Calendar .react-calendar .react-calendar__navigation button.react-calendar__navigation__label {
			font-size: 3.8vw;
			color: #333;
		}
		.Presentation .Calendar .react-calendar .react-calendar__navigation button:enabled:hover,
		.Presentation .Calendar .react-calendar .react-calendar__navigation button:enabled:focus {
			background-color: none !important;
		}
		.Presentation .Calendar .react-calendar .react-calendar__navigation button.react-calendar__navigation__label:disabled {
			background-color: #fff;
		}
		.Presentation .Calendar .react-calendar button.react-calendar__tile {
			font-size: 3.8vw;
			position: relative;
		}
		.Presentation .Calendar .react-calendar button.react-calendar__tile.basedate abbr {
			font-size: 0 !important;
		}
		.Presentation .Calendar .react-calendar button.react-calendar__tile .circle {
			width: 10vw;
			height: 10vw;
			border-radius: 50%;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			display: flex;
			justify-content: center;
			align-items: center;
			font-weight: 600;
		}
		.Presentation .Calendar .react-calendar button.react-calendar__tile .circle.enable {
			border: 3px solid #f6453d;
			background-color: rgba(246, 69, 61, 0.2);
		}
		.Presentation .Calendar .react-calendar button.react-calendar__tile .circle.disable {
			border: 3px solid #999;
			background-color: rgba(0, 0, 0, 0.2);
		}
		.Presentation .Calendar .react-calendar button.react-calendar__tile .circle.select {
			background-color: rgba(246, 69, 61, 1);
			color: #fff;
		}
		.Presentation .Calendar .react-calendar button.react-calendar__tile--active {
			background-color: #fff;
		}
		.Presentation .Calendar .react-calendar button.react-calendar__tile--now {
			background-color: rgba(20, 36, 53, 0.1);
			border: 1px solid #142435;
			font-weight: 600;
		}
		.Presentation .Calendar .react-calendar button.react-calendar__tile--now:hover {
			background-color: rgba(20, 36, 53, 0.1) !important;
		}
		.Presentation .Calendar .react-calendar .react-calendar__month-view__weekdays {
			padding: 4px 0;
			border-top: 1px solid #e1e1e1;
			border-bottom: 1px solid #e1e1e1;
		}
		.Presentation .Calendar .react-calendar .react-calendar__month-view__weekdays__weekday {
			font-size: 4.2vw;
			font-weight: 500;
		}
		.Presentation .Calendar .react-calendar .react-calendar__month-view__weekdays__weekday abbr {
			border-bottom: none;
			text-decoration: none;
			cursor: none;
		}
		.Presentation .Calendar .react-calendar button.react-calendar__month-view__days__day {
			color: #333;
			padding: 4vw 0;
		}
		.Presentation .Calendar .react-calendar button.react-calendar__month-view__days__day--neighboringMonth {
			color: #757575;
		}
		.Presentation .Calendar .react-calendar button.react-calendar__month-view__days__day--weekend {
			color: #f6453d;
		}
		.Presentation .Calendar .CalendarDescRow {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			margin-top: 1.5vw;
		}
		.Presentation .Calendar .CalendarDescWrap {
			display: flex;
		}
		.Presentation .Calendar .CalendarDescWrap .CalendarDescCircle {
			width: 4vw;
			height: 4vw;
			border-radius: 50%;
		}
		.Presentation .Calendar .CalendarDescWrap .CalendarDescCircle.enable {
			border: 2px solid #f6453d;
			background-color: rgba(246, 69, 61, 0.2);
		}
		.Presentation .Calendar .CalendarDescWrap .CalendarDescCircle.disable {
			border: 2px solid #999;
			background-color: rgba(0, 0, 0, 0.2);
		}
		.Presentation .Calendar .CalendarDescWrap .CalendarDesc {
			font-size: 4vw;
			color: #333;
			font-weight: 700;
			margin-left: 1.5vw;
		}
		.Presentation .Calendar .Row .Radio {
			color: #333;
			font-size: 3.8vw;
			font-weight: 400;
			line-height: 9vw;
		}
		.Presentation .Calendar .Row .Radio label:first-child {
			margin-right: 4vw;
		}
		.Presentation .Calendar .Radio .Checkmark {
			position: absolute;
			top: 50%;
			left: 0;
			width: 4vw;
			height: 4vw;
			border-radius: 50%;
			transform: translateY(-50%);
			background-color: #fff;
			border: 1px solid #000;
		}
		.Presentation .Calendar .Radio .Checkmark:after {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 2vw;
			height: 2vw;
			border-radius: 50%;
			transform: translate(-50%, -50%);
			background-color: #000;
			border: 1px solid #000;
		}
		.Presentation .Calendar .Radio .Label {
			position: relative;
			padding-left: 6vw;
			cursor: pointer;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
		.Presentation .Calendar .Radio .Label input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
		}
		.Presentation .Calendar .Radio .Label .Checkmark:after {
			content: "";
			position: absolute;
			display: none;
		}
		.Presentation .Calendar .Radio .Label input:checked ~ .Checkmark:after {
			display: block;
		}
		.Presentation .Calendar .Checkbox {
			margin-top: 5vw !important;
		}
		.Presentation .Calendar .Checkbox .Label {
			position: relative;
		}
		.Presentation .Calendar .Checkbox .Checkmark {
			position: absolute;
			top: 50%;
			left: 0;
			width: 4vw;
			height: 4vw;
			transform: translateY(-50%);
			background-color: #fff;
			border: 1px solid #000;
		}
		.Presentation .Calendar .Checkbox .Checkmark:after {
			position: absolute;
			top: 0.8vw;
			left: 0.8vw;
			width: 2.4vw;
			height: 2.4vw;
			background-color: #000;
		}
		.Presentation .Calendar .Checkbox .Label {
			color: #333;
			font-size: 3.8vw;
			font-weight: 400;
			position: relative;
			padding-left: 7vw;
			cursor: pointer;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
		.Presentation .Calendar .Checkbox .Label input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
		}
		.Presentation .Calendar .Checkbox .Label .Checkmark:after {
			content: "";
			position: absolute;
			display: none;
		}
		.Presentation .Calendar .Checkbox .Label input:checked ~ .Checkmark:after {
			display: block;
		}
		.Presentation .Calendar .Checkbox .PrivacyLink {
			font-size: 3.8vw;
			font-weight: 700;
			text-decoration: underline;
			margin-left: 3vw;
			color: #303030;
			cursor: pointer;
		}
		.Presentation .Calendar .BtnArea {
			position: relative;
			margin-top: 5vw;
		}
		.Presentation .Calendar .BtnArea button {
			display: inline-block;
			width: 46vw;
			height: 11vw;
			color: #fff;
			font-size: 4.5vw;
			font-weight: 600;
			transition: all 0.3s ease;
			position: absolute;
			top: 4vw;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
		}
		.Presentation .Calendar .BtnArea button.Request {
			background-color: #222;
		}
		.Presentation .Calendar .BtnArea button.Request:hover {
			background-color: #444;
		}

		.LayerWrap {
			z-index: 10;
			display: block;
			position: fixed;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			text-align: center;
			background-color: rgba(0, 0, 0, 0.5);
			visibility: hidden;
			opacity: 0;
			-webkit-transition: opacity 0.5s ease, visibility 0.5s ease;
			transition: opacity 0.5s ease, visibility 0.5s ease;
		}
		.LayerWrap.on {
			visibility: visible;
			opacity: 1;
			-webkit-transition: opacity 0.5s ease, visibility 0.5s ease;
			transition: opacity 0.5s ease, visibility 0.5s ease;
		}
		.LayerWrap:before {
			content: "";
			display: inline-block;
			height: 100%;
			vertical-align: middle;
		}
		.PopLayer .BtnClose {
			background-image: url("../images/franchise/m/btn-close.png");
			background-repeat: no-repeat;
			background-size: 50%;
			background-position: center;
			position: absolute;
			width: 9vw;
			height: 9vw;
			top: 2vw;
			right: 2vw;
		}
		.PopLayer {
			display: inline-block;
			vertical-align: middle;
			background-color:#fff;
			border: 4px solid #001f32;
			z-index: 10;
			position: relative;
		}
		.PopLayer .PopContainer {
			position: absolute;
			top: 38%;
			transform: translateY(-38%);
			left: 6vw;
			right: 6vw;
			text-align: center;
			color: #303030;
			word-break: keep-all;
			letter-spacing: -1px;
		}

		#Layer .PopLayer {
			width: 91vw;
			height: 90vw;
		}
		#Layer .PopLayer .PopContainer {
			font-size: 4vw;
			line-height: 5.5vw;
		}
		#Layer .PopLayer .PopContainer p.Title {
			font-size: 5vw;
			font-weight: 700;
		}
		#Layer .PopLayer .PopContainer p.Sub {
			margin: 3vw 0;
		}
		#Layer .PopLayer .PopContainer p.Desc span {
			font-weight: 700;
		}

		#Dialog .PopLayer {
			position: relative;
			width: 91vw;
			min-height: 50vw;
		}
		#Dialog .PopLayer .PopContainer {
			left: 0;
			right: 0;
			font-size: 6vw;
			line-height: 7vw;
		}
		#Dialog .PopLayer .PopContainer p.Msg {
			font-weight: 700;
		}
		#Dialog .PopLayer .BtnGroup {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 5vw;
		}
		#Dialog .PopLayer .BtnGroup button {
			width: 25vw;
			height: 10vw;
			color: #fff;
			font-size: 4vw;
			font-weight: 600;
			margin: 0 1vw;
		}
		#Dialog .PopLayer .BtnGroup button.Ok {
			background-color: #001f32;
		}
		#Dialog .PopLayer .BtnGroup button.Cancel {
			background-color: #333;
			display: none;
		}
		#Dialog .PopLayer .BtnGroup button {
			filter: invert(10%);
		}
		#Dialog .PopLayer .BtnGroup button.Cancel.Confirm {
			display: inline-block;
		}

		.BottomFloat {
			z-index: 99;
			height: 19vw;
			position: fixed;
			left: 0;
			right: 0;
			bottom: -37vw;
			opacity: 0;
			transition-property: opacity, bottom;
			transition-duration: 0.3s;
			transition-timing-function: ease-in;
			filter: drop-shadow(0 -10px 10px rgba(42, 42, 42, .2));
			
			display: flex;
			flex-direction: column;
			justify-content: space-evenly;
		}
		.BottomFloat.show {
			bottom: -1px;
			opacity: 1;
			transition-property: opacity, bottom;
			transition-duration: 0.3s;
			transition-timing-function: ease-out;
		}
		.BottomFloat .Info {
			flex: 5;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			justify-items: center;
		}
		.BottomFloat .Info p {
			font-family: 'S-CoreDream-9Black';
			color: #fff;
			font-size: 5.5vw;
			line-height: 5.5vw;
		}
		.BottomFloat .Info .InfoLeft,
		.BottomFloat .Info .InfoRight {
			display: flex;
			flex: 1;
			height: 100%;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
		.BottomFloat .InfoLeft {
			background-color: #ef4840;
		}
		.BottomFloat .InfoRight {
			background-color: #ffd500;
			position: relative;
		}
		.BottomFloat .Info p.InfoTop,
		.BottomFloat .Info p.InfoBottom {
			margin-top: 1.5vw;
		}
		.BottomFloat .InfoRight p {
			color: #3b2c30;
		}
		.BottomFloat .InfoRight p:nth-child(2) {
			font-family: 'S-CoreDream-5Medium';
		}
		.BottomFloat .InfoRight img {
			position: absolute;
			top: -1.6vw;
			right: 5vw;
			width: 7vw;
			height: 7.5vw;
		}
		.BottomFloat .Contact {
			flex: 5;
			background-color: #142435;
			display: flex;
		}
	}
}