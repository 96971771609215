@charset "utf-8";

@font-face {
	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 300;
	src: url(../fonts/NotoSansKR-Light.woff2) format('woff2'),
	url(../fonts/NotoSansKR-Light.woff) format('woff'),
	url(../fonts/NotoSansKR-Light.otf) format('opentype');
}
@font-face {
	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 400;
	src: url(../fonts/NotoSansKR-DemiLight.woff2) format('woff2'),
	url(../fonts/NotoSansKR-DemiLight.woff) format('woff'),
	url(../fonts/NotoSansKR-DemiLight.otf) format('opentype');
}
@font-face {
	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	src: url(../fonts/NotoSansKR-Regular.woff2) format('woff2'),
	url(../fonts/NotoSansKR-Regular.woff) format('woff'),
	url(../fonts/NotoSansKR-Regular.otf) format('opentype');
}
@font-face {
	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 600;
	src: url(../fonts/NotoSansKR-Medium.otf) format('woff2'),
	url(../fonts/NotoSansKR-Medium.woff) format('woff'),
	url(../fonts/NotoSansKR-Medium.otf) format('opentype');
}
@font-face {
	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 700;
	src: url(../fonts/NotoSansKR-Bold.woff2) format('woff2'),
	url(../fonts/NotoSansKR-Bold.woff) format('woff'),
	url(../fonts/NotoSansKR-Bold.otf) format('opentype');
}

@font-face {
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 400;
	src: url(../fonts/Pretendard-Regular.woff2) format('woff2'),
	url(../fonts/Pretendard-Regular.woff) format('woff'),
	url(../fonts/Pretendard-Regular.otf) format('opentype');
}

* {margin:0;padding:0;} 
html,body,div,span,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,abbr,address,cite,code,
del,dfn,em,img,ins,kbd,q,samp,small,strong,sub,sup,var,b,i,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,article,aside,canvas,details,figcaption,figure,main,
footer,header,hgroup,menu,nav,section,summary,time,mark,audio,video {border:0;outline:0;background:transparent;font-size:100%;}
table,caption,tbody,tfoot,thead,tr,th,td {outline:0;background:transparent;font-size:100%;}
article,aside,canvas,details,figcaption,figure,main,footer,header,hgroup,menu,nav,section,summary {display:block;}
nav, ul {list-style:none;}
blockquote,q {quotes:none;}
blockquote:before,blockquote:after,
q:before,q:after {content:"";content:none;}

a {margin:0;padding:0;border:0;font-size:100%;}
ins {color:#000;text-decoration:none;}
mark {color:#000;font-style:italic;font-weight:700;}
del,s {text-decoration:line-through;}
abbr[title],dfn[title] {border-bottom:1px dotted #000;cursor:help;}

hr {display:block;height:1px;border:0;border-top:1px solid #cccccc;margin:1em 0;padding:0;}

body {font-size:14px;color:#555;font-family:'Noto Sans KR';}
select,input,button,textarea,button{  font-family:'Noto Sans KR'; }
button{outline: none;border: none;background: none;}
table{font-size:inherit;font:100%;}
pre,code,kbd,samp,tt{font-family:monospace;*font-size:108%;line-height:100%;}

address {font-style:normal;display:inline;}

/* 글자 속성 */
a{color:#555;text-decoration:none;}


/* LIST 요소 기본 */
li {list-style:none;}
ul.fllist li , .fllist > * {float:left;}
ul.frlist li , .frlist > * {float:right;}
.fllist:after , .frlist:after {clear:both;display:block;content:"";}
ol.onum li, .onum li {list-style-type:decimal}
.onum {padding-left:22px}


/* 이미지 요소 기본 */
img {border:0px;vertical-align:middle;}
img.button {cursor:pointer;}
button.imgBtn {padding:0px;margin:0px;border:0px;cursor:pointer;background: none;}
button:focus {outline: 0;}


table { border-collapse: collapse; border-spacing: 0px; width:100%}
table.ashow {empty-cells:show;}


/*기본 스타일 정의*/
/*플로팅*/
.fr {float:right;}
.fl {float:left;}
.cr {clear:both;}
.aftercr:after {clear:both;content:"";display:block;}


/*포지셔닝*/
.pos_d, .pos-d, .pos-st {position:static;} /*기본*/
.pos_r,.pos-r, .pos-rel {position:relative;}
.pos_a,.pos-a, .pos-abs {position:absolute;}


/*박스유형*/
.isbox, .is-rel, .is-box {display:block;position:relative;}
.isabs, .is-abs {position:absolute;}
.inline {display:inline-block;}

/* 글자자름 (inline-block, block 형태로 가로폭 반드시 지정해 줘야함)*/
.ellipsis{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;   }
.wordkeep {white-space:normal;word-break:keep-all;   }

/*숨김*/
.hidden {display:none;}
.blind {width:1px;height:1px;overflow:hidden;position:absolute;top:-9999em;left:-9999em;display:block;text-indent:-9999em;font-size:0px;line-height:0;}

/*마우스오버 손모양*/
label{cursor:pointer}
button{cursor:pointer;}

/* body { overflow-x: hidden; overflow-y: scroll; height: 100%;} */
body { overflow-x: hidden; }

.layer-pop {width:auto; height:auto; position:fixed; top:50%; left:50%; z-index:99999;}
.layer-pop .bg{position:fixed; top:0; left:0; width:100%; height:100%; background:#000; opacity:.5; filter:alpha(opacity=50); transition: all 0.3s ease-out; z-index:-1;}

/* 슬라이드 페이드모드일때 마지막 슬라이드에 가려져서 클릭이벤트 안먹는 현상 수정 */
.slick-initialized .slick-slide.slick-active {
  z-index: 1;
}

.html {
	scroll-padding-top: calc(4.5rem - 1px) !important;
}

.body {
	margin: 0;
	font-family: var(--bs-body-font-family);
	font-size: var(--bs-body-font-size);
	font-weight: var(--bs-body-font-weight);
	line-height: var(--bs-body-line-height);
	color: var(--bs-body-color);
	text-align: var(--bs-body-text-align);
	background-color: var(--bs-body-bg);
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
