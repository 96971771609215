@charset "utf-8";

#BrandComponent {
	@media only screen and (min-width: 1024px) {

		.NewsList {
			min-height: 1030px;
		}
		.NewsList .SubContainer {
			overflow: hidden;
			max-width: 1000px;
			min-width: 1000px;
		}

		.NewsList .TitleWrap {
			display: inline-block;
			width: 100%;
			margin-top: 150px;
			position: relative;
		}
		.NewsList .TitleWrap p.Title {
			float: left;
			font-size: 52px;
			font-weight: 700;
			line-height: 52px;
			color: #142436;
			letter-spacing: -2px;
		}
		.NewsList .TitleWrap ul {
			position: absolute;
			right: 0;
			bottom: 0;
		}
		.NewsList .TitleWrap ul li {
			display: inline-block;
			font-size: 0;
			cursor: pointer;
		}
		.NewsList .TitleWrap ul li:not(:first-child) {
			margin-left: 45px;
		}
		.NewsList .TitleWrap ul li a {
			color: #142436;
			display: inline-block;
			text-align: center;	
			font: normal 24px 'Noto Sans KR';
			text-transform: uppercase;
			font-weight: 400;
		}
		.NewsList .TitleWrap ul li a:hover,
		.NewsList .TitleWrap ul li.Select a {
			color: #142436;
			font-weight: 700;
			-webkit-transition: color 0.5s ease;
			transition: color 0.5s ease;
		}
		.NewsList .TitleWrap ul li a::before {
			display: block;
			content: attr(title);
			font-weight: 400;
			height: 0;
			overflow: hidden;
			visibility: hidden;
		}

		.NewsList .ListView {
			margin-bottom: 150px;
		}
		.NewsList .ListWrap {
			width: 100%;
			margin-top: 20px;
			border-top: 2px solid #152435;
		}
		.NewsList .ListWrap .SubMenu {
			text-align: center;
			line-height: 115px;
		}
		.NewsList .ListWrap .SubMenu button {
			font-size: 18px;
			font-weight: 500;
			margin: 0 10px;
			color: #666;
		}
		.NewsList .ListWrap .SubMenu button:hover,
		.NewsList .ListWrap .SubMenu button.Select {
			color: #f5453d;
		}
		.NewsList .ListWrap ul:not(.EventList) li {
			min-height: 64px;
		}
		.NewsList .ListWrap ul:not(.EventList) li:not(:last-child) {
			border-bottom: 1px solid #152435;
		}
		.NewsList .ListWrap ul:not(.EventList) li a:hover .Title {
			font-weight: 700;
			text-decoration: underline;
		}
		.NewsList .ListWrap ul:not(.EventList) li a span {
			display: inline-block;
			line-height: 64px;
			font-size: 18px;
			font-weight: 500;
		}
		.NewsList .ListWrap ul:not(.EventList) li .Type {
			color: #f5453d;
			width: 9%;
		}
		.NewsList .ListWrap ul:not(.EventList) li .Title {
			color: #333;
			width: 90%;//81%;
		}
		.NewsList .ListWrap ul:not(.EventList) li .Date {
			color: #666;
			width: 10%;
			text-align: right;
		}

		.NewsList .ListWrap ul.EventList {
			display: inline-flex;
			flex-wrap: wrap;
			// margin: -50px 0 0 -65px !important;
			margin: 0 0 0 -65px !important;
			width: calc(100% + 65px);
			margin: 0;
			padding: 0;
		}
		.NewsList .ListWrap ul.EventList li {
			position: relative;
			width: 290px;
			height: 265px;
			margin: 50px 0 0 65px;
		}
		.NewsList .ListWrap ul.EventList li:hover .Title {
			font-weight: 700;
			text-decoration: underline;
		}
		.NewsList .ListWrap ul.EventList li .Thumb {
			width: 290px;
			height: 190px;
			background-color: #ccc;
		}
		.NewsList .ListWrap ul.EventList li .Thumb img {
			width: 290px;
			height: 190px;
			object-fit: fill;
		}
		.NewsList .ListWrap ul.EventList li .TitleWrap {
			position: absolute;
			left: 0;
			bottom: 0;
		}
		.NewsList .ListWrap ul.EventList li .Wrap {
			width: 290px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		.NewsList .ListWrap ul.EventList li .Title {
			font-size: 18px;
			color: #333;
			font-weight: 500;
		}
		.NewsList .ListWrap ul.EventList li .Date {
			font-size: 18px;
			color: #666;
			font-weight: 400;
		}
		
		.NewsList .BtnWrap {
			text-align: center;
		}
		.NewsList .ListView button.More {
			width: 190px;
			height: 52px;
			border: 1px solid #142436;
			font-size: 18px;
			font-weight: 500;
			color: #142436;
			margin-top: 50px;
			-webkit-transition: all 0.3s ease;
			transition: all 0.3s ease;
		}
		.NewsList .ListView button.More:hover {
			background-color: #142436;
			color: #fff;
			border: 1px solid #fff;
			-webkit-transition: all 0.3s ease;
			transition: all 0.3s ease;
		}
		.NewsList .ListView .Nodata {
			font-size: 20px;
			width: 100%;
			text-align: center;
			font-weight: 600;
			padding: 50px 0;
		}

		.NewsList .DetailView .List {
			width: 190px;
			height: 52px;
			background-color: #142436;
			border: 1px solid #142436;
			margin: 30px 0 150px 0;
			-webkit-transition: all 0.3s ease;
			transition: all 0.3s ease;
		}
		.NewsList .DetailView .List {
			color: #fff;
			font-size: 18px;
			font-weight: 500;
		}
		.NewsList .DetailView .List:hover {
			background-color: #fff;
			border: 1px solid #142436;
			-webkit-transition: all 0.3s ease;
			transition: all 0.3s ease;
		}
		.NewsList .DetailView .List:hover {
			color: #142436;
		}
		.NewsList .DetailWrap {
			width: 100%;
			margin-top: 35px;
			border-top: 2px solid #152435;
			border-bottom: 1px solid #152435;
		}
		.NewsList .DetailWrap div.Title {
			height: 95px;
			border-bottom: 1px solid #152435;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}
		.NewsList .DetailWrap p.Type {
			font-size: 18px;
			color: #f5453d;
			font-weight: 500;
		}
		.NewsList .DetailWrap p.Title {
			font-size: 18px;
			color: #333;
			font-weight: 500;
			line-height: 95px;
		}
		.NewsList .DetailWrap p.Date {
			font-size: 18px;
			color: #666;
			font-weight: 400;
			line-height: 95px;
		}
		.NewsList .DetailWrap .Content {
			font-size: 18px;
			color: #333;
			font-weight: 500;
			padding: 35px 0;
			min-height: 600px;
		}
		.NewsList .DetailWrap .Content img {
			max-width: 90%;
		}
		.NewsList .DetailWrap div.Attach {
			height: 95px;
			border-top: 1px solid #152435;
			position: relative;
			font-size: 18px;
			color: #333;
			font-weight: 500;
		}
		.NewsList .DetailWrap div.Attach a {
			line-height: 95px;
			font-weight: 700;
		}
	}
	
	@media only screen and (max-width : 1023px) {
		.PC { display: none !important; }

		.NewsList {
			min-height: 50vh;
		}
		.NewsList .SubContainer {
			overflow: hidden;
			max-width: 96vw;
			min-width: 96vw;
		}

		.NewsList .TitleWrap {
			display: inline-block;
			width: 100%;
			margin-top: 10vw;
			position: relative;
		}
		.NewsList .TitleWrap p.Title {
			float: left;
			font-size: 6.5vw;
			font-weight: 700;
			line-height: 7.5vw;
			color: #142436;
			letter-spacing: -2px;
		}
		.NewsList .TitleWrap ul {
			position: absolute;
			right: 0;
			bottom: 0;
		}
		.NewsList .TitleWrap ul li {
			display: inline-block;
			font-size: 0;
			cursor: pointer;
		}
		.NewsList .TitleWrap ul li:not(:first-child) {
			margin-left: 5vw;
		}
		.NewsList .TitleWrap ul li a {
			color: #142436;
			display: inline-block;
			text-align: center;	
			font: normal 4vw 'Noto Sans KR';
			text-transform: uppercase;
			font-weight: 400;
		}
		.NewsList .TitleWrap ul li a:hover,
		.NewsList .TitleWrap ul li.Select a {
			color: #f5453d;//#142436;
			font-weight: 700;
			-webkit-transition: color 0.5s ease;
			transition: color 0.5s ease;
		}
		.NewsList .TitleWrap ul li a::before {
			display: block;
			content: attr(title);
			font-weight: 400;
			height: 0;
			overflow: hidden;
			visibility: hidden;
		}

		.NewsList .ListWrap {
			width: 100%;
			margin: 2vw 0 8vw 0;
			border-top: 2px solid #152435;
		}
		.NewsList .ListWrap .SubMenu {
			text-align: center;
			line-height: 13.8vw;
		}
		.NewsList .ListWrap .SubMenu button {
			font-size: 3.5vw;
			font-weight: 600;
			margin: 0 2vw;
			color: #666;
		}
		.NewsList .ListWrap .SubMenu button:hover,
		.NewsList .ListWrap .SubMenu button.Select {
			color: #f5453d;
		}
		.NewsList .ListWrap ul:not(.EventList) li {
			min-height: 15vw;
			position: relative;
		}
		.NewsList .ListWrap ul:not(.EventList) li a {
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			transform: translateY(-50%);
		}
		.NewsList .ListWrap ul:not(.EventList) li:not(:last-child) {
			border-bottom: 1px solid #152435;
		}
		.NewsList .ListWrap ul:not(.EventList) li a:hover .Title {
			font-weight: 700;
			text-decoration: underline;
		}
		.NewsList .ListWrap ul:not(.EventList) li a span {
			display: inline-block;
			line-height: 3.5vw;
			font-size: 3.5vw;
			font-weight: 500;
		}
		.NewsList .ListWrap ul:not(.EventList) li .Type {
			color: #f5453d;
			width: 15%;
		}
		.NewsList .ListWrap ul:not(.EventList) li .Title {
			color: #333;
			width: 80%;//65%;
		}
		.NewsList .ListWrap ul:not(.EventList) li .Date {
			color: #666;
			width: 20%;
			text-align: right;
		}

		.NewsList .ListWrap ul.EventList {
			display: inline-flex;
			flex-wrap: wrap;
			// margin: -5vw 0 0 -6vw !important;
			margin: 0 0 0 -6vw !important;
			width: calc(100w + 6vw);
			margin: 0;
			padding: 0;
		}
		.NewsList .ListWrap ul.EventList li {
			position: relative;
			width: 45vw;
			height: 42vw;
			margin: 5vw 0 0 6vw;
		}
		.NewsList .ListWrap ul.EventList li:hover .Title {
			font-weight: 700;
			text-decoration: underline;
		}
		.NewsList .ListWrap ul.EventList li .Thumb {
			width: 100%;
			height: 29vw;
			background-color: #ccc;
		}
		.NewsList .ListWrap ul.EventList li .Thumb img {
			width: 100%;
			height: 29vw;
			object-fit: fill;
		}
		.NewsList .ListWrap ul.EventList li .TitleWrap {
			position: absolute;
			left: 0;
			bottom: 0;
		}
		.NewsList .ListWrap ul.EventList li .Wrap {
			width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		.NewsList .ListWrap ul.EventList li .Title {
			font-size: 3.5vw;
			color: #333;
			font-weight: 500;
		}
		.NewsList .ListWrap ul.EventList li .Date {
			font-size: 3.5vw;
			color: #666;
			font-weight: 400;
		}
		
		.NewsList .BtnWrap {
			text-align: center;
		}
		.NewsList .ListView button.More {
			width: 27vw;
			height: 9vw;
			border: 1px solid #142436;
			font-size: 3.5vw;
			font-weight: 500;
			color: #142436;
			margin: 7vw;
			-webkit-transition: all 0.3s ease;
			transition: all 0.3s ease;
		}
		.NewsList .ListView button.More:hover {
			background-color: #142436;
			color: #fff;
			border: 1px solid #fff;
			-webkit-transition: all 0.3s ease;
			transition: all 0.3s ease;
		}
		.NewsList .ListView .Nodata {
			font-size: 4vw;
			width: 100%;
			text-align: center;
			font-weight: 600;
			padding: 7vw 0;
		}

		.NewsList .DetailView button.List {
			width: 27vw;
			height: 9vw;
			background-color: #142436;
			border: 1px solid #142436;
			margin: 7vw 0 18vw 0;
			-webkit-transition: all 0.3s ease;
			transition: all 0.3s ease;
			color: #fff;
			font-size: 3.5vw;
			font-weight: 500;
		}
		.NewsList .DetailView button.List:hover {
			background-color: #fff;
			border: 1px solid #142436;
			-webkit-transition: all 0.3s ease;
			transition: all 0.3s ease;
		}
		.NewsList .DetailView button.List:hover {
			color: #142436;
		}
		.NewsList .DetailWrap {
			width: 100%;
			margin-top: 2vw;
			border-top: 2px solid #152435;
			border-bottom: 1px solid #152435;
		}
		.NewsList .DetailWrap div.Title {
			height: 15vw;
			border-bottom: 1px solid #152435;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}
		.NewsList .DetailWrap p.Type {
			font-size: 3.5vw;
			color: #f5453d;
			font-weight: 500;
			line-height: 15vw;
		}
		.NewsList .DetailWrap p.Title {
			font-size: 3.5vw;
			color: #333;
			font-weight: 500;
			line-height: 15vw;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			text-align: left;
		}
		.NewsList .DetailWrap p.Date {
			font-size: 3.5vw;
			color: #666;
			font-weight: 400;
			line-height: 15vw;
		}
		.NewsList .DetailWrap .Content {
			font-size: 3.5vw;
			color: #333;
			font-weight: 500;
			padding: 4vw 0;
			min-height: 50vh;
		}
		.NewsList .DetailWrap .Content img {
			max-width: 90%;
		}
		.NewsList .DetailWrap div.Attach {
			height: 15vw;
			border-top: 1px solid #152435;
			position: relative;
			font-size: 3.5vw;
			color: #333;
			font-weight: 500;
		}
		.NewsList .DetailWrap div.Attach a {
			line-height: 15vw;
			font-weight: 700;
		}
	}
}
