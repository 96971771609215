@charset "utf-8";
@font-face {
	font-family: 'S-CoreDream-9Black';
	src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-9Black.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'S-CoreDream-5Medium';
	src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-5Medium.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

#LandingComponent {
	@media only screen and (min-width: 1024px) {
		body { overflow-y: hidden; }
	
		.MOBILE { display: none !important; }

		.Container {
			overflow-y: auto;
			// scroll-snap-type: y mandatory;
			width: 100%;
			height: 100vh;
			scroll-behavior: smooth;
			-webkit-overflow-scrolling: touch;
		}
	
		.SubContainer {
			min-width: 1320px;
			max-width: 1320px;
			height: 100%;
			overflow-y: hidden;
			margin: 0 auto;
			position: relative;
			z-index: 999;
		}

		.Home {
			position: relative;
		}
		.Home .TitleWrap {
			position: relative;
			width: 100vw;
			height: 966px;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: bottom;
			text-align: center;
			display: table-cell;
			vertical-align: middle;
		}
		.Home .TitleWrap.TopBg {
			background-image: url("../images/landing/topBg.png");
		}
		.Home .TitleWrap .Gnb {
			margin-top: 95px;
		}
		.Home .TitleWrap .Desc {
			margin-top: 110px;
		}
		.Home .TitleWrap .Desc p.Top {
			font-size: 42px;
			color: #fff;
			font-weight: 700;
			letter-spacing: 5px;
			text-decoration-line: underline;
			text-decoration-color: #ef4840;
			text-decoration-style: solid;
			text-decoration-thickness: 7px;
			text-underline-offset: 8px;
		}
		.Home .TitleWrap .Desc p.Top span {
			font-size: 32px;
			font-weight: 400;
			letter-spacing: 5px;
		}
		.Home .TitleWrap .Desc p.Middle span {
			font-family: 'S-CoreDream-9Black';
			font-size: 120px;
			color: #ef4840;
		}
		.Home .TitleWrap .Desc p.Middle {
			font-size: 68px;
			font-weight: 400;
			color: #fff;
			margin-top: 23px;
			letter-spacing: 8px;
		}
		.Home .TitleWrap .Desc p.Bottom {
			font-size: 94px;
			line-height: 94px;
			letter-spacing: 7px;
			font-weight: 300;
			color: #fff;
		}
		.Home .TitleWrap .CircleWrap {
			margin: 78px 60px 0 60px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}
		.Home .TitleWrap .CircleWrap .Circle {
			width: 210px;
			height: 210px;
			border-radius: 100%;
			background-color: #ef4840;
			position: relative;
		}
		.Home .TitleWrap .CircleWrap .Circle div {
			position: absolute;
			left: 0;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
		}
		.Home .TitleWrap .CircleWrap .Circle div p {
			color: #fff;
			font-size: 24px;
			font-family: 'S-CoreDream-9Black';
		}
		.Home .TitleWrap .CircleWrap .Circle div p span {
			color: #fff25d;
		}
		.Home .TitleWrap .CircleWrap .Circle div p.Small {
			font-size: 17px;
		}

		.Home .BtnClose {
			background-image: url("../images/franchise/m/btn-close.png");
			background-repeat: no-repeat;
			background-size: 100%;
			background-position: center;
			position: absolute;
			width: 35px;
			height: 35px;
			top: 3vw;
			right: 3vw;
			filter: brightness(0) invert(1);
			z-index: 999;
		}
	
		.Video {
			overflow: hidden;
			position: relative;
			min-height: 44vw;
			max-height: 850px;
			padding: 4vw 0 7vw 0;
			background-color: #142435;
		}

		.Video .SubContainer {
			text-align: center;
		}

		.Video .TitleWrap p {
			font-size: 46px;
			color: #fff;
			font-weight: 700;
			text-align: center;
			margin-bottom: 2.5vw;
		}
		.Video .TitleWrap p span {
			color: #ef4840;
		}

		.Video .VideoWrap {
			position: relative;
			margin: 0 5%;
			padding-bottom: 50.6%;
			height: 0;
		}
		.Video .VideoWrap iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		.Competitive {
			overflow: hidden;
			position: relative;
			background-color: #efefef;
			height: 900px;
		}
		.Competitive .TitleWrap {
			margin-top: 100px;
		}
		.Competitive .TitleWrap p:first-child {
			font-size: 46px;
			line-height: 46px;
			color: #142435;
			font-weight: 700;
			text-align: left;
		}
		.Competitive .Wrap .Content {
			width: 350px;
			height: 100%;
			position: absolute;
			top: 80px;
		}
		.Competitive .Wrap .Content:nth-child(1) {
			left: 0;
		}
		.Competitive .Wrap .Content:nth-child(2) {
			left: 50%;
			transform: translateX(-50%);
		}
		.Competitive .Wrap .Content:nth-child(3) {
			right: 0;
		}
		.Competitive .Wrap .Content .ContentWrap .Img {
			width: 100%;
			height: 215px;
			position: relative;
		}
		.Competitive .Wrap .Content .ContentWrap .Img .Line {
			position: absolute;
			width: 1px;
			height: 50px;
			left: 20px;
			bottom: -18px;
			background-color: #f6453d;
		}
		.Competitive .Wrap .Content {
			margin-top: 120px;
		}
		.Competitive .Wrap .Content .ContentWrap .DescWrap {
			margin: 35px 10px 0 10px;
		}
		.Competitive .Wrap .Content .ContentWrap .DescWrap p:first-child {
			color: #142435;
			font-size: 24px;
			line-height: 24px;
			font-weight: 700;
			letter-spacing: -1px;
		}
		.Competitive .Wrap .Content .ContentWrap .DescWrap p:last-child {
			margin-top: 15px;
			color: #142435;
			font-size: 16px;
			line-height: 23px;
			font-weight: 500;
			word-break: keep-all;
		}
		.Competitive .Wrap .Content .ContentWrap:nth-child(2) {
			margin-top: 65px;
		}
		.Competitive .Wrap .Content:nth-child(2) .ContentWrap {
			margin-top: 110px;
		}  
		.Competitive .Wrap .Content .Img {
			background-repeat: no-repeat;
			background-size: contain;
		}
		.Competitive .Wrap .Content:nth-child(1) .ContentWrap:nth-child(1) .Img {
			background-image: url("../images/landing/img-competitive1.jpg");
		}
		.Competitive .Wrap .Content:nth-child(2) .ContentWrap:nth-child(1) .Img {
			background-image: url("../images/landing/img-competitive2.jpg");
		}
		.Competitive .Wrap .Content:nth-child(3) .ContentWrap:nth-child(1) .Img {
			background-image: url("../images/landing/img-competitive3.jpg");
		}

		.MachineType {
			position: relative;
			min-height: 750px;
			background-color: #142435;
			padding: 80px 0;
		}
		.MachineType .SubContainer {
			height: 100%;
			position: relative;
		}
		.MachineType .TitleWrap p {
			font-size: 46px;
			color: #fff;
			font-weight: 700;
		}
		.MachineType .TitleWrap p:last-child {
			// margin-top: 15px;
			font-size: 18px;
			line-height: 25px;
			color: #fff;
			font-weight: 400;
			word-break: keep-all;
		}
		.MachineType .ContentBox {
			margin-top: 50px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}
		.MachineType .ContentWrap {
			width: 45%;
		}
		.MachineType .ContentWrap .Content {
			width: 100%;
			height: 525px;
			background-color: #fff;
			position: relative;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
		}
		.MachineType .ContentWrap:first-child .Content {
			background-image: url("../images/landing/install-type.jpg");
			background-size: cover;
		}
		.MachineType .ContentWrap:last-child .Content {
			background-image: url("../images/landing/store-type.png");
			background-size: cover;
		}
		.MachineType .ContentWrap .Content .TitleWrap {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			height: 70px;
			background-color: rgba(0, 0, 0, 0.9);
			text-align: center;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.MachineType .ContentWrap .Content .TitleWrap .Title {
			font-size:  20px;
			color: #bbbbbb;
			font-weight: 400;
		}
		.MachineType .ContentWrap .Content .TitleWrap .Title span {
			font-size: 16px;
			color: #f5453d;
			font-weight: 400;
		}
		.MachineType .ContentWrap .Content .TitleWrap .Title span.Event {
			font-size: 14px;
			color: #bbbbbb;
		}
		.MachineType .ContentWrap .DescWrap {
			margin-top: 20px;
		}
		.MachineType .ContentWrap .DescWrap p {
			color: #fff;
			font-weight: 500;
			font-size: 18px;
			line-height: 25px;
			word-break: keep-all;
		}
		.MachineType .ContentWrap .DescWrap p:last-child {
			font-size: 16px;
			line-height: 25px;
			color: #bbbbbb;
		}

		.Price {
			position: relative;
			max-height: 2500px;
			background-color: #fff;
			padding: 100px 0 130px 0;
		}
		.Price .TitleWrap p {
			font-size: 46px;
			color: #142435;
			font-weight: 700;
			text-align: left;
		}
		.Price .TitleWrap p:nth-child(3) {
			margin-top: 15px;
			font-size: 18px;
			line-height: 25px;
			color: #142435;
			font-weight: 400;
			word-break: keep-all;
		}
		.Price .TitleWrap p span {
			color: #f5453d;
		}
		.Price .ContentWrap {
			margin-top: 30px;
		}
		.Price .ContentWrap .Vat {
			text-align: right;
			font-size: 18px;
			font-weight: 500;
		}
		.Price .ContentWrap .Desc {
			margin-top: 10px;
			font-size: 18px;
			font-weight: 500;
		}
		.Price .ContentWrap .Table {
			display: table;
			width: 100%;
		}
		.Price .ContentWrap .Table .TableRow {
			display: table-row;
		}
		.Price .ContentWrap .Table .TableRow:not(:first-child) .TableCell {
			border-top: 1px solid #e1e1e1;
		}
		.Price .ContentWrap .Table .TableRow .TableCell:not(:first-child) {
			border-left: 1px solid #e1e1e1;
		}
		.Price .ContentWrap .Table .TableRow.Title {
			background-color: #142435;
			height: 70px;
		}
		.Price .ContentWrap .Table .TableRow .TableCell {
			display: table-cell;
			color: #333;
			font-weight: 500;
			font-size: 20px;
			text-align: center;
			vertical-align: middle;
			word-break: keep-all;
		}
		.Price .ContentWrap .Table .TableRow:first-child .TableCell,
		.Price .ContentWrap .Table .TableRow .TableCell:first-child {
			color: #fff;
			font-weight: 600;
			font-size: 22px;
			text-align: center;
			background-color: #142435;
		}
		.Price .ContentWrap .Table .TableRow .TableCell:first-child {
			border-left: none;
		}
		.Price .ContentWrap .Table .TableRow .TableCell:last-child {
			border-right: none;
		}
		.Price .ContentWrap .Table .TableRow:first-child {
			border-top: none;
		}
		.Price .ContentWrap .Table .TableRow:last-child {
			border-bottom: none;
		}
		.Price .ContentWrap .Table .TableRow:last-child .TableCell {
			border-bottom: 1px solid #e1e1e1;
		}
		.Price .ContentWrap .Table .TableRow .TableCell:last-child {
			border-right: 1px solid #e1e1e1;
		}
		.Price .ContentWrap .Table .TableRow:first-child .TableCell:last-child {
			border-right: 1px solid #142435;
		}
		.Price .ContentWrap .Table .TableRow:last-child .TableCell:first-child {
			border-bottom: 1px solid #142435;
		}

		.Price .StoreWrap .Table .TableRow .TableCell .Cancel {
			text-decoration: line-through;
			text-decoration-color: #f5453d;
		}
		.Price .StoreWrap .Table .TableRow .TableCell .Important {
			display: inline;
			color: #f5453d;
			font-weight: 600;
			font-size: 22px;
			margin: 0 5%;
		}
		.Price .StoreWrap .Table .TableRow {
			height: 80px;
		}
		.Price .StoreWrap .Table .TableRow:nth-child(8) {
			background-color: #fdeceb;
		}
		.Price .StoreWrap .Table .TableRow:nth-child(9) {
			background-color: #fbc7c5;
		}
		.Price .StoreWrap .Table .TableRow .TableCell:nth-child(1) {
			width: 29%;
		}
		.Price .StoreWrap .Table .TableRow .TableCell:nth-child(2) {
			width: 27%;
		}

		.Price .InstallWrap {
			margin-top: 100px;
		}
		.Price .InstallWrap .Table .TableRow .TableCell:first-child {
			width: 22%;
		}
		.Price .InstallWrap .Table .TableRow:nth-child(2) {
			background-color: #f1f1f1;
			width: auto;
			height: 420px;
		}
		.Price .InstallWrap .Table .TableRow .TableCell {
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
		}
		.Price .InstallWrap .Table .TableRow:nth-child(2) .TableCell:nth-child(2) {
			background-image: url("../images/landing/machine-slim.jpg");
		}
		.Price .InstallWrap .Table .TableRow:nth-child(2) .TableCell:nth-child(3) {
			background-image: url("../images/landing/machine-standard.jpg");
		}
		.Price .InstallWrap .Table .TableRow:nth-child(2) .TableCell:nth-child(4) {
			background-image: url("../images/landing/machine-high.jpg");
		}
		.Price .InstallWrap .Table .TableRow:nth-child(3) {
			background-color: #fdeceb;
			height: 100px;
		}
		.Price .InstallWrap .Table .TableRow:nth-child(4) {
			background-color: #fbc7c5;
			height: 100px;
		}


		.Machine {
			position: relative;
			max-height: 750px;
			background-color: #efefef;
			padding: 100px 0 130px 0;
		}
		.Machine .SubContainer {
			height: 100%;
			position: relative;
		}
		.Machine .TitleWrap p {
			font-size: 46px;
			color: #142435;
			font-weight: 700;
		}
		.Machine .TitleWrap p:nth-child(2) span {
			color: #f5453d;
		}
		.Machine .TitleWrap p:last-child {
			margin-top: 15px;
			font-size: 18px;
			line-height: 25px;
			color: #142435;
			font-weight: 400;
			word-break: keep-all;
		}
		.Machine .ContentBox {
			margin-top: 30px;
			display: flex;
			justify-content: space-between;
			flex-direction: row;
		}
		.Machine .ContentWrap {
			width: 30%;
		}
		.Machine .ContentWrap .Content {
			width: 100%;
			height: 350px;
			background-color: #fff;
			position: relative;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			overflow: hidden;
		}
		.Machine .ContentWrap:first-child .Content {
			background-image: url("../images/landing/cafero.png");
			background-size: 90%;
		}
		.Machine .ContentWrap:nth-child(2) .Content {
			background-image: url("../images/landing/release.png");
			background-size: 150%;
			background-position: 50% 10%;
		}
		.Machine .ContentWrap:last-child .Content {
			background-image: url("../images/landing/celeb.png");
			background-size: 110%;
		}
		.Machine .ContentWrap .Content .DescWrap {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(0, 0, 0, 0.9);
			transition: top 0.5s ease;
			cursor: pointer;
		}
		.Machine .ContentWrap .Content .DescWrap i.Arrow {
			background-image: url("../images/franchise/arrow-top.png");
			background-repeat: no-repeat;
			position: absolute;
			top: 30px;
			right: 15px;
			width: 25px;
			height: 14px;
			transition: all 0.5s ease;
		}
		.Machine .ContentWrap .Content .DescWrap.Off i.Arrow {
			transform: rotate(-180deg);
		}
		.Machine .ContentWrap .Content .DescWrap.Off {
			top: 279px;
		}
		.Machine .ContentWrap .Content .Desc {
			margin: 0 15px;
		}
		.Machine .ContentWrap .Content .Desc p {
			font-size:  16px;
			line-height: 25px;
			color: #bbbbbb;
			font-weight: 400;
			word-break: keep-all;
		}
		.Machine .ContentWrap .Content .Desc p.Title {
			font-size: 20px;
			line-height: 20px;
			padding: 27px 0 20px 0;
			color: #fff;
			font-weight: 600;
			word-break: keep-all;
		}
		.Machine .ContentWrap .Content .Desc p.Title span {
			font-size: 16px;
			color: #f5453d;
			font-weight: 400;
		}
		.Machine .ContentWrap .Content .Desc p.Sub {
			color: #fff;
			margin: 0 0 20px 0;
			word-break: keep-all;
		}

		.Menu {
			background-color: #142435;
			background-image: url("../images/brand/bg-menu.png");
			background-repeat: no-repeat;
			background-size: cover;
			height: 1118px;
			position: relative;
		}
		.Menu .TitleWrap {
			margin-top: 100px;
		}
		.Menu .TitleWrap p:first-child {
			font-size: 46px;
			color: #fff;
			font-weight: 700;
		}
		.Menu .TitleWrap p:first-child span {
			color: #f5453d;
		}
		.Menu .TitleWrap p:last-child {
			margin-top: 15px;
			font-size: 18px;
			line-height: 25px;
			color: #fff;
			font-weight: 400;
			word-break: keep-all;
		}
		.Menu ul.NavContainer {
			position: absolute;
			left: 0;
			top: 400px;
			z-index: 3;
		}
		.Menu ul.NavContainer li {
			float: left;
			margin-right: 26px;
		}
		.Menu ul.NavContainer li button {
			width: 23px;
			height: 23px;
			border-radius: 23px;
			border: none;
			background: rgb(255,51,51);
			opacity: 0.3;
			-webkit-transition: opacity 0.5s ease;
			transition: opacity 0.5s ease;
		}
		.Menu ul.NavContainer li button.active {
			width: 23px;
			height: 23px;
			border-radius: 23px;
			border: none;
			background: rgb(255,51,51);
			opacity: 1;
			-webkit-transition: opacity 0.5s ease;
			transition: opacity 0.5s ease;
		}
		.Menu .Slide {
			z-index: 2;
			width: 50%;
		}
		.Menu .Slide .slick-list {
			height: 918px;
		}
		.Menu .Slide .PageContainer {
			position: relative;
			width: 100%;
			height: 100%;
		}
		.Menu .Slide .PageContainer .PageWrap {
			position: absolute;
			left: 0;
			top: 130px;
			width: 580px;
			height: 100%;
		}
		.Menu .Slide .PageContainer .PageWrap p.Title {
			font-size: 46px;
			color: #fff;
			font-weight: 700;
			margin-bottom: 25px;
		}
		.Menu .Slide .PageContainer .PageWrap p.TitleSub {
			font-size: 24px;
			color: #fff;
			font-weight: 700;
			margin: 30px 0 20px 0;
		}
		.Menu .Slide .PageContainer .PageWrap p.Desc {
			font-size: 18px;
			color: #fff;
			font-weight: 500;
			line-height: 30px;
			letter-spacing: -0.5px;
			word-break: keep-all;
		}
		.Menu .Slide .PageContainer .PageWrap .MenuWrap {
			display: block;
		}
		.Menu .Slide .PageContainer .PageWrap .MenuWrap div {
			float: left;
		}
		.Menu .Slide .PageContainer .PageWrap .MenuWrap div:first-child {
			width: 270px;
		}
		.Menu .Slide .PageContainer .PageWrap p.MenuTitle {
			font-size: 18px;
			color: #fff;
			font-weight: 500;
			line-height: 30px;
			letter-spacing: -0.5px;
			word-break: keep-all;
		}
		.Menu .Slide .PageContainer .PageWrap p.Caution {
			display: inline-block;
			font-size: 13px;
			color: #7e93a9;
			font-weight: 400;
			margin-top: 20px;
			width: 100%;
		}
		.Menu .Slide .PageContainer .PageWrap button.More {
			font-size: 18px;
			color: #999;
			font-weight: 400;
			background-color: rgba(0,0,0,0);
			border: 1px solid #999;
			width: 120px;
			margin-top: 23px;
			padding: 2px 0 5px 0;
		}
		.Menu .Slide .PageContainer .PageWrap button.More:hover {
			background-color: rgba(153,153,153,1);
			color: #fff;
		}
		.Menu .Right {
			width: 660px;
			height: 675px;
			background-color: #fff;
			position: absolute;
			top: 350px;
			right: 0;
			overflow: hidden;
		}
		.Menu .Right .Wrap {
			position: relative;
			margin: 40px 0 0 80px;
		}
		.Menu .Right .Wrap ul {
			display: inline-block;
		}
		.Menu .Right .Wrap ul.on {
			opacity: 1;
			-webkit-transition: opacity 0.5s ease;
			transition: opacity 0.5s ease;
		}
		.Menu .Right .Wrap ul.off {
			opacity: 0;
			-webkit-transition: opacity 0.5s ease;
			transition: opacity 0.5s ease;
		}
		.Menu .Right .Wrap ul li {
			display: inline-block;
			cursor: pointer;
			position: relative;
			padding: 0 18px;
			border-bottom: 0;
			transition: border-bottom 0.5 ease;
		}
		.Menu .Right .Wrap ul li button {
			display: inline-block;
			text-align: center;
			font: normal 18px 'Noto Sans KR';
			text-transform: uppercase;
			font-weight: 400;
			color: hsl(211, 45%, 14%);
			line-height: 8px;
			padding: 0 3px;
			border-bottom: 5px solid rgba(250, 162, 158, 0);
			transition: all 0.5s ease;
		}
		.Menu .Right .Wrap ul li button::before {
			display: block;
			content: attr(title);
			height: 0;
			overflow: hidden;
			visibility: hidden;
		}
		.Menu .Right .Wrap ul li:first-child {
			padding-left: 0;
		}
		.Menu .Right .Wrap ul li.active button,
		.Menu .Right .Wrap ul li:hover button {
			font-weight: 600;
			border-bottom: 5px solid rgba(250, 162, 158, 1);
		}
		.Menu .Right .Wrap ul li:first-child:after {
			display: none;
		}
		.Menu .Right .Wrap ul li:after {
			content: '';
			display: inline-block;
			width: 1px;
			height: 16px;
			background: #ccc;
			position: absolute;
			top: 50%;
			left: 0;
			margin-top: -10px;
		}
		.Menu .Right .Wrap .ElementWrap {
			width: 100%;
			height: 100%;
			position: relative;
			background-color: red;
		}
		.Menu .Right .Wrap .Element {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}
		.Menu .Right .Wrap .Element.on {
			opacity: 1;
			-webkit-transition: opacity 0.5s ease;
			transition: opacity 0.5s ease;
		}
		.Menu .Right .Wrap .Element.off {
			opacity: 0;
			-webkit-transition: opacity 0.5s ease;
			transition: opacity 0.5s ease;
		}
		.Menu .Right .Wrap .Element p {
			margin: 5px 0;
		}
		.Menu .Right .Wrap .Element .Title {
			color: #f5453d;
			font-size: 18px;
			font-weight: 600;
		}
		.Menu .Right .Wrap .Element .Content {
			color: #142435;
			font-size: 18px;
			font-weight: 600;
		}
		.Menu .Right .Wrap .Element .Sub {
			color: #142435;
			font-size: 16px;
			font-weight: 400;
		}
		.Menu .Right .Wrap .Element .Desc {
			font-size: 18px;
			color: #555;
			max-width: 430px;
			font-weight: 400;
			line-height: 29px;
			margin-top: 20px;
			word-break: keep-all;
		}
		.Menu .Right .Wrap .Element:nth-child(1) img {
			margin: 26px 0 0 70px !important;
		}
		.Menu .Right .Wrap .Element:nth-child(1) p.FirstRow {
			margin-top: 20px !important;
		}
		.Menu .Right .Wrap .Element:nth-child(2) img {
			margin: 15px 0 0 120px !important;
		}
		.Menu .Right .Wrap .Element:nth-child(2) p.FirstRow {
			margin-top: 39px !important;
		}
		.Menu .Right .Wrap .Element:nth-child(3) img {
			margin: 70px 0 0 130px !important;
		}
		.Menu .Right .Wrap .Element:nth-child(3) p.FirstRow {
			margin-top: 50px !important;
		}
		.Menu .Right .Wrap .Element:nth-child(4) img {
			margin: 47px 0 0 120px !important;
		}
		.Menu .Right .Wrap .Element:nth-child(4) p.FirstRow {
			margin-top: 40px !important;
		}
		.Menu .Right .Wrap .Element:nth-child(5) img {
			margin: 40px 0 0 80px !important;
		}
		.Menu .Right .Wrap .Element:nth-child(5) p.FirstRow {
			margin-top: 45px !important;
		}
		.Menu .Right .Wrap .Element:nth-child(6) img {
			margin: 40px 0 0 80px !important;
		}
		.Menu .Right .Wrap .Element:nth-child(6) p.FirstRow {
			margin-top: 45px !important;
		}
		.Menu .Right .Wrap .Element:nth-child(7) img {
			margin: 40px 0 0 80px !important;
		}
		.Menu .Right .Wrap .Element:nth-child(7) p.FirstRow {
			margin-top: 45px !important;
		}

		.Store {
			background-color: #fff;
			height: 918px;
			position: relative;
		}
		.Store .Left {
			position: absolute;
			width: 36%;
			height: 676px;
			top: 50%;
			transform: translateY(-50%);
			left: 0;
		}
		.Store .Left .TitleWrap {
			position: absolute;
			left: 0;
			top: 0;
		}
		.Store .Left .TitleWrap p {
			font-size: 46px;
			color: #142435;
			font-weight: 700;
		}
		.Store .Left .TitleWrap p span {
			color: #f5453d;
		}
		.Store .TitleWrap p:last-child {
			margin-top: 15px;
			font-size: 18px;
			line-height: 25px;
			color: #142435;
			font-weight: 400;
			word-break: keep-all;
		}
		.Store .Left .ElementWrap {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 143px;
		}
		.Store .Left .ElementWrap .slick-slide:not(:last-child) {
			width: 140px;
			height: 143px;
		}
		.Store .Left .ElementWrap .Img {
			background-repeat: no-repeat;
			background-size: 97%;
			width: 140px;
			height: 143px;
		}
		.Store .Left .ElementWrap .Thumb1 {
			background-image: url("../images/landing/store/thumb/black-1.png");
		}
		.Store .Left .ElementWrap .Thumb1.Active {
			background-image: url("../images/landing/store/thumb/color-1.png");
		}
		.Store .Left .ElementWrap .Thumb2 {
			background-image: url("../images/landing/store/thumb/black-2.png");
		}
		.Store .Left .ElementWrap .Thumb2.Active {
			background-image: url("../images/landing/store/thumb/color-2.png");
		}
		.Store .Left .ElementWrap .Thumb3 {
			background-image: url("../images/landing/store/thumb/black-3.png");
		}
		.Store .Left .ElementWrap .Thumb3.Active {
			background-image: url("../images/landing/store/thumb/color-3.png");
		}
		.Store .Left .ElementWrap .Thumb4 {
			background-image: url("../images/landing/store/thumb/black-4.png");
		}
		.Store .Left .ElementWrap .Thumb4.Active {
			background-image: url("../images/landing/store/thumb/color-4.png");
		}
		.Store .Left .ElementWrap .Thumb5 {
			background-image: url("../images/landing/store/thumb/black-5.png");
		}
		.Store .Left .ElementWrap .Thumb5.Active {
			background-image: url("../images/landing/store/thumb/color-5.png");
		}
		.Store .Left .ElementWrap .Thumb6 {
			background-image: url("../images/landing/store/thumb/black-6.png");
		}
		.Store .Left .ElementWrap .Thumb6.Active {
			background-image: url("../images/landing/store/thumb/color-6.png");
		}
		.Store .Left .ElementWrap .Thumb7 {
			background-image: url("../images/landing/store/thumb/black-7.png");
		}
		.Store .Left .ElementWrap .Thumb7.Active {
			background-image: url("../images/landing/store/thumb/color-7.png");
		}
		.Store .Left .ElementWrap .Thumb8 {
			background-image: url("../images/landing/store/thumb/black-8.png");
		}
		.Store .Left .ElementWrap .Thumb8.Active {
			background-image: url("../images/landing/store/thumb/color-8.png");
		}
		.Store .Left .ElementWrap .Thumb9 {
			background-image: url("../images/landing/store/thumb/black-9.png");
		}
		.Store .Left .ElementWrap .Thumb9.Active {
			background-image: url("../images/landing/store/thumb/color-9.png");
		}
		.Store .Left .ElementWrap .Thumb10 {
			background-image: url("../images/landing/store/thumb/black-10.png");
		}
		.Store .Left .ElementWrap .Thumb10.Active {
			background-image: url("../images/landing/store/thumb/color-10.png");
		}

		.Store .Right {
			width: 50%;
			height: 676px;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 0;
			overflow: hidden;
		}
		.Store .Right .Wrap .ElementWrap {
			width: 100%;
			height: 100%;
			position: relative;
		}
		.Store .Right .Wrap .ElementWrap .Img {
			background-repeat: no-repeat;
			background-size: cover;
			width: 660px;
			height: 676px;
		}
		.Store .Right .Wrap .ElementWrap .Element1 {
			background-image: url("../images/landing/store/store1.png");
		}
		.Store .Right .Wrap .ElementWrap .Element2 {
			background-image: url("../images/landing/store/store2.png");
		}
		.Store .Right .Wrap .ElementWrap .Element3 {
			background-image: url("../images/landing/store/store3.png");
		}
		.Store .Right .Wrap .ElementWrap .Element4 {
			background-image: url("../images/landing/store/store4.png");
		}
		.Store .Right .Wrap .ElementWrap .Element5 {
			background-image: url("../images/landing/store/store5.png");
		}
		.Store .Right .Wrap .ElementWrap .Element6 {
			background-image: url("../images/landing/store/store6.png");
		}
		.Store .Right .Wrap .ElementWrap .Element7 {
			background-image: url("../images/landing/store/store7.png");
		}
		.Store .Right .Wrap .ElementWrap .Element8 {
			background-image: url("../images/landing/store/store8.png");
		}
		.Store .Right .Wrap .ElementWrap .Element9 {
			background-image: url("../images/landing/store/store9.png");
		}
		.Store .Right .Wrap .ElementWrap .Element10 {
			background-image: url("../images/landing/store/store10.png");
		}
	
		.Faq {
			position: relative;
			min-height: 1070px;
			background-color: #0e1c2c;
		}
		.Faq .SubContainer {
			height: 100%;
			position: relative;
		}
		.Faq .TitleWrap {
			margin-top: 90px;
		}
		.Faq .TitleWrap p:first-child {
			color: #fff;
			font-size: 34px;
			font-weight: 500;
		}
		.Faq .TitleWrap p:last-child {
			color: #fff;
			font-size: 46px;
			font-weight: 700;
		}
		.Faq .ContentRow {
			width: 100%;
			display: flex;
			flex-direction: row;
		}
		.Faq .ContentRow:first-child {
			margin-top: 100px;
		}
		.Faq .Line {
			width: 100%;
			height: 2px;
			background-image: url(../images/landing/dot.png);
			background-repeat: repeat-x;
			margin: 50px 0;
		}
		.Faq .Line:last-child {
			margin-bottom: 100px;
		}
		.Faq .ContentRow .No {
			min-width: 110px;
			color: #fff;
			font-size: 50px;
			line-height: 50px;
			font-weight: 400;
		}

		.Faq .ContentRow .DescWrap p {
			color: #fff;
			font-size: 18px;
			font-weight: 400;
			word-break: keep-all;
		}
		.Faq .ContentRow .DescWrap p.Title {
			color: #fff;
			font-size: 30px;
			line-height: 30px;
			font-weight: 500;
			margin-bottom: 25px;
		}

		.Open {
			position: relative;
			height: 1025px;
			background-color: #fff;
		}
		.Open .Bg {
			background-color: #efefef;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 180px;
		}
		.Open .SubContainer {
			height: 100%;
			position: relative;
		}
		.Open .TitleWrap {
			text-align: left;
			margin-top: 120px;
		}
		.Open .TitleWrap p:first-child {
			color: #142435;
			font-size: 46px;
			font-weight: 700;
		}
		.Open .TitleWrap p:last-child {
			color: #000;
			font-size: 24px;
			font-weight: 400;
			text-align: left;
			word-break: keep-all;
		}
		.Open .ContentWrap {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			height: 740px;
		}
		.Open .ContentWrap .Row {
			height: 50%;
		}
		.Open .ContentWrap .Row .RowContent {
			position: relative;
			width: 25%;
			height: 100%;
			float: left;
		}
		.Open .ContentWrap .Row .RowContent .Content {
			position: absolute;
			background-color: #fff;
			border: 1px solid #142435;
			top: 0;
			bottom: 0;
		}
		.Open .ContentWrap .Row .RowContent:nth-child(1) .Content {
			left: 0;
			right: 15px;
		}
		.Open .ContentWrap .Row .RowContent:nth-child(2) .Content {
			left: 5px;
			right: 10px;
		}
		.Open .ContentWrap .Row .RowContent:nth-child(3) .Content {
			left: 10px;
			right: 5px;
		}
		.Open .ContentWrap .Row .RowContent:nth-child(4) .Content {
			left: 15px;
			right: 0;
		}
		.Open .ContentWrap .Row:first-child .RowContent .Content {
			bottom: 10px;
		}
		.Open .ContentWrap .Row:last-child .RowContent .Content {
			top: 10px;
		}
		.Open .ContentWrap .Row .RowContent .Content .Detail {
			position: absolute;
			top: 40px;
			right: 40px;
			bottom: 40px;
			left: 40px;
		}
		.Open .ContentWrap .Row .RowContent .Content .Detail .Top {
			position: relative;
		}
		.Open .ContentWrap .Row .RowContent .Content .Detail .Top img {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
		.Open .ContentWrap .Row .RowContent .Content .Detail .Top,
		.Open .ContentWrap .Row .RowContent .Content .Detail .Bottom {
			height: 50%;
		}
		.Open .ContentWrap .Row .RowContent .Content .Detail .Top p.Title {
			text-align: left;
			font-size: 24px;
			color: #f5453d;
			font-weight: 700;
		}
		.Open .ContentWrap .Row .RowContent .Content .Detail .Bottom p.Title {
			font-size: 24px;
			color: #142435;
			font-weight: 700;
			margin-bottom: 15px;
		}
		.Open .ContentWrap .Row .RowContent .Content .Detail .Bottom p.Desc {
			font-size: 16px;
			color: #142435;
			font-weight: 400;
			line-height: 25px;
		}
		
		.Consulting {
			height: 870px;
			margin-bottom: 144px;
		}
		.Consulting .TitleWrap {
			text-align: left;
			margin-top: 120px;
		}
		.Consulting .TitleWrap p {
			font-size: 46px;
			color: #f6453d;
			font-weight: 700;
		}
		.Consulting .MainContent {
			width: 100%;
			height: 100%;
			margin-top: 60px;
		}
		.Consulting .MainContent .Request {
			width: 530px;
			height: 100%;
			float: left;
		}
		.Consulting .MainContent .Request .Content {
			margin-top: 40px;
		}
		.Consulting .MainContent p.MainTitle {
			font-size: 36px;
			line-height: 36px;
			font-weight: 600;
			color: #000;
		}
		.Consulting .MainContent p.MainTitle span {
			font-size: 16px;
			font-weight: 400;
			color: #333;
		}
		.Consulting .MainContent .Request .Row {
			width: 100%;
			height: 42px;
			margin-bottom: 10px;
			display: inline-block;
			margin: 0 auto;
		}
		.Consulting .MainContent .Request .Row .Title,
		.Consulting .MainContent .Request .Row .Input {
			float: left;
		}
		.Consulting .MainContent .Request .Row .Title {
			width: 150px;
			line-height: 42px;
			color: #333;
			font-size: 20px;
			font-weight: 400;
		}
		.Consulting .MainContent .Request .Row .Input {
			width: 340px;
			height: 100%;
		}
		.Consulting .MainContent .Request .Row .Input input {
			border: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(241, 241, 241, 0.8);
			font-size: 16px;
			color: #000;
			padding: 0 20px;
			outline: none;
		}
		.Consulting .MainContent .Request .Row .Radio {
			color: #333;
			font-size: 20px;
			font-weight: 400;
			line-height: 42px;
		}
		.Consulting .MainContent .Request .Row .Radio label:first-child {
			margin-right: 20px;
		}
		.Consulting .Radio .Checkmark {
			position: absolute;
			top: 1px;
			left: 3px;
			width: 22px;
			height: 22px;
			border-radius: 50%;
			background-color: #fff;
			border: 1px solid #000;
		}
		.Consulting .Radio .Checkmark:after {
			position: absolute;
			top: 4px;
			left: 4px;
			width: 12px;
			height: 12px;
			border-radius: 50%;
			background-color: #000;
			border: 1px solid #000;
		}
		.Consulting .Radio .Label {
			position: relative;
			padding-left: 35px;
			cursor: pointer;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
		.Consulting .Radio .Label input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
		}
		.Consulting .Radio .Label .Checkmark:after {
			content: "";
			position: absolute;
			display: none;
		}
		.Consulting .Radio .Label input:checked ~ .Checkmark:after {
			display: block;
		}
		.Consulting .MainContent .Request .Row textarea {
			width: 474px;
			height: 130px;
			background-color: rgba(241, 241, 241, 0.8);
			font-size: 16px;
			color: #000;
			margin-top: 20px;
			padding: 25px 28px;
			outline: none;
			border: none;
		}

		.Consulting .MainContent .Privacy {
			width: 530px;
			height: 100%;
			float: right;
		}
		.Consulting .MainContent .Privacy .Content {
			margin-top: 40px;

		}
		.Consulting .MainContent .Privacy .Content .Row {
			width: 530px;
		}
		.Consulting .MainContent .Privacy .Content .Row:nth-child(2) {
			margin-top: 25px;
		}
		.Consulting .MainContent .Privacy .Content .Row p {
			font-size: 20px;
			color: #333;
			font-weight: 400;
			word-break: keep-all;
			line-height: 30px;
		}
		.Consulting .MainContent .Privacy .Content .Row p span {
			font-weight: 600;
		}
		.Consulting .Privacy .Checkmark {
			position: absolute;
			top: 1px;
			left: 3px;
			width: 22px;
			height: 22px;
			background-color: #fff;
			border: 1px solid #000;
		}
		.Consulting .Privacy .Checkmark:after {
			position: absolute;
			top: 4px;
			left: 4px;
			width: 12px;
			height: 12px;
			background-color: #000;
			border: 1px solid #000;
		}
		.Consulting .Privacy .Label {
			color: #333;
			font-size: 20px;
			font-weight: 400;
			position: relative;
			padding-left: 35px;
			cursor: pointer;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
		.Consulting .Privacy .Label input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
		}
		.Consulting .Privacy .Label .Checkmark:after {
			content: "";
			position: absolute;
			display: none;
		}
		.Consulting .Privacy .Label input:checked ~ .Checkmark:after {
			display: block;
		}
		.Consulting .Privacy .BtnArea {
			margin-top: 50px;
		}
		.Consulting .Privacy .BtnArea button {
			width: 255px;
			height: 68px;
			text-align: center;
			color: #fff;
			font-size: 24px;
			font-weight: 400;
			transition: all 0.3s ease;
		}
		.Consulting .Privacy .BtnArea button.Request {
			background-color: #222;
			float: left;
		}
		.Consulting .Privacy .BtnArea button.Request:hover {
			background-color: #444;
		}
		.Consulting .Privacy .BtnArea button.Cancel {
			background-color: #999;
			float: right;
		}
		.Consulting .Privacy .BtnArea button.Cancel:hover {
			background-color: #7d7b7b;
		}

		.BottomFloat {
			z-index: 999;
			background-color: #ef4840;
			height: 170px;
			position: fixed;
			left: 0;
			right: 0;
			bottom: -170px;
			opacity: 0;
			transition-property: opacity, bottom;
			transition-duration: 0.3s;
			transition-timing-function: ease-in;
			display: flex;
			flex-direction: column;
			justify-content: space-evenly;
		}
		.BottomFloat.show {
			bottom: 0;
			opacity: 1;
			transition-property: opacity, bottom;
			transition-duration: 0.3s;
			transition-timing-function: ease-out;
		}
		.BottomFloat .Info {
			flex: 6;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			margin: 0 5%;
		}
		.BottomFloat .Info p {
			font-family: 'S-CoreDream-9Black';
			font-size: 1.8vw;
			color: #fff;
		}
		.BottomFloat .Info p:first-child {
			font-size: 1.8vw;
		}
		.BottomFloat .Info p:first-child span {
			color: #ffa19d;
		}
		.BottomFloat .Info p:last-child {
			font-size: 3.5vw;
			line-height: 3.5vw;
		}
		.BottomFloat .Contact {
			flex: 4;
			background-color: #142435;
			display: flex;
		}
		.BottomFloat .ContactWrap {
			flex: 1;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin: 0 5%;
		}
		.BottomFloat .ContactWrap span.Desc {
			flex: 1;
			color: #fff;
			font-size: 1.3vw;
			text-align: left;
		}
		.BottomFloat .ContactWrap .Channel {
			display: flex;
			flex: 1;
			justify-content: space-between;
			align-items: center;
		}
		.BottomFloat .ContactWrap a {
			font-size: 1.3vw;
			color: #ccc;
			font-weight: 500;
			display: flex;
			align-items: center;
		}
		.BottomFloat .ContactWrap a:hover {
			font-weight: 700;
			text-decoration: underline;
			filter: brightness(0) invert(1);
		}
		.BottomFloat .ContactWrap button {
			margin-right: 10px;
		}
		.BottomFloat .ContactWrap button.Instagram {
			background-image: url("../images/brand/icon-instagram.png");
			width: 30px;
			height: 30px;
		}
		.BottomFloat .ContactWrap button.Youtube {
			background-image: url("../images/brand/icon-youtube.png");
			width: 30px;
			height: 30px;
		}
		.BottomFloat .ContactWrap button.Naver {
			background-image: url("../images/brand/icon-naver.png");
			width: 30px;
			height: 30px;
		}
		.BottomFloat .ContactWrap button.Facebook {
			background-image: url("../images/brand/icon-facebook.png");
			width: 16px;
			height: 27px;
		}
		.BottomFloat .ContactWrap button.Blog {
			background-image: url("../images/brand/icon-blog.png");
			width: 20px;
			height: 25px;
		}

		.ConsultingFloat {
			z-index: 9999;
			position: fixed;
			top: 260px;
			right: 0;
			width: 440px;
			height: 390px;
			transition-property: right;
			transition-duration: 0.3s;
			transition-timing-function: ease-in;
		}
		.ConsultingFloat.hide {
			right: -250px;
			transition-property: right;
			transition-duration: 0.3s;
			transition-timing-function: ease-out;
		}
		.ConsultingFloat .Folder {
			width: 210px;
			height: 120px;
			background-color: #5e3be1;
			border-top-left-radius: 18px;
			border-bottom-left-radius: 18px;
			position: absolute;
			right: 230px;
			cursor: pointer;
		}
		.ConsultingFloat .Folder .Content {
			position: absolute;
			left: 18px;
			top: 15px;
			right: 35px;
			bottom: 18px;
			display: flex;
			justify-content: space-between;
			flex-direction: column;
		}
		.ConsultingFloat .Folder.Tel .Content p {
			color: #fff;
		}
		.ConsultingFloat .Folder.Tel .Content .Box:first-child p:first-child {
			font-family: 'S-CoreDream-9Black';
			color: #bab3f7;
			font-size: 10px;
			letter-spacing: 1px;
		}
		.ConsultingFloat .Folder.Tel .Content .Box:first-child p:last-child {
			font-family: 'S-CoreDream-9Black';
			font-size: 26px;
			margin-top: 7px;
		}
		.ConsultingFloat .Folder.Tel .Content .Box:last-child p {
			font-size: 16px;
			margin-top: 10px;
		}
		.ConsultingFloat .Folder.Kakao {
			background-color: #fed831;
			top: 125px;
		}
		.ConsultingFloat .Folder.Kakao .Content p {
			color: #3b2c30;
		}
		.ConsultingFloat .Folder.Kakao .Content .Box:first-child p {
			font-family: 'S-CoreDream-9Black';
			font-size: 10px;
			letter-spacing: 1px;
		}
		.ConsultingFloat .Folder.Kakao .Content .Box:last-child p {
			line-height: 22px;
		}
		.ConsultingFloat .Folder.Kakao .Content .Box:last-child p:first-child {
			font-family: 'S-CoreDream-9Black';
			font-size: 22px;
		}
		.ConsultingFloat .Folder.Kakao .Content .Box:last-child p:first-child img {
			width: 24px;
			height: 26px;
			margin-bottom: 8px;
		}
		.ConsultingFloat .Folder.Kakao .Content .Box:last-child p:last-child {
			font-family: 'S-CoreDream-5Medium';
			font-size: 22px;
			letter-spacing: -2px;
		}
		
		.ConsultingFloat .ContentWrap {
			position: absolute;
			right: 0;
			width: 250px;
			height: 410px;
			background-color: #fff;
			border-top-left-radius: 18px;
			border-bottom-left-radius: 18px;
			filter: drop-shadow(4px 7px 10px rgba(42, 42, 42, .25));
		}
		.ConsultingFloat .ContentWrap .Content {
			position: absolute;
			left: 17px;
			top: 17px;
			right: 17px;
			bottom: 17px;
		}
		.ConsultingFloat .ContentWrap .Content .Title {
			font-family: 'S-CoreDream-9Black';
			color: #5e3be1;
			font-size: 24px;
			text-align: left;
			letter-spacing: 2px;
			margin-bottom: 15px;
		}
		.ConsultingFloat .ContentWrap .Content input[type="text"],
		.ConsultingFloat .ContentWrap .Content input[type="tel"] {
			width: 100%;
			height: 34px;
			background: none;
			border: none;
			border-bottom: 1px solid #9f9f9f;
			font-weight: 700;
			color: #9f9f9f;
		}
		.ConsultingFloat .ContentWrap .Content input[type="text"]:focus,
		.ConsultingFloat .ContentWrap .Content input[type="tel"]:focus {
			outline: none;
		}
		.ConsultingFloat .ContentWrap .Content input[type="text"]::placeholder,
		.ConsultingFloat .ContentWrap .Content input[type="tel"]::placeholder {
			color: #ccc;
		}
		.ConsultingFloat .ContentWrap .Content .Sub {
			font-size: 12px;
			color: #818181;
			font-weight: 500;
			word-break: keep-all;
			margin-top: 15px;
		}
		.ConsultingFloat .ContentWrap .Content .Desc {
			background: #ebebeb;
			border-radius: 8px;
			font-size: 12px;
			color: #818181;
			font-weight: 500;
			word-break: keep-all;
			margin-top: 10px;
			padding: 8px 10px;
		}
		.ConsultingFloat .ContentWrap .Content .LabelWrap {
			margin-top: 15px;
		}
		.ConsultingFloat .ContentWrap .Content .Label {
			font-size: 12px;
			color: #575757;
			position: relative;
			padding-left: 25px;
			cursor: pointer;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
		.ConsultingFloat .ContentWrap .Content .Label input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
		}
		.ConsultingFloat .ContentWrap .Content .Label .Checkmark {
			position: absolute;
			top: 1px;
			left: 3px;
			width: 12px;
			height: 12px;
			background-color: #fff;
			border: 1px solid #575757;
		}
		.ConsultingFloat .ContentWrap .Content .Label .Checkmark:after {
			position: absolute;
			top: 2px;
			left: 2px;
			width: 6px;
			height: 6px;
			background-color: #575757;
			border: 1px solid #575757;
			content: "";
			display: none;
		}
		.ConsultingFloat .ContentWrap .Content .Label input:checked ~ .Checkmark:after {
			display: block;
		}
		.ConsultingFloat .ContentWrap .Content button {
			width: 100%;
			height: 56px;
			background-color: #5e3be1;
			border-radius: 8px;
			font-family: 'S-CoreDream-9Black';
			color: #fff;
			font-size: 22px;
			line-height: 60px;
			letter-spacing: 2px;
			margin-top: 15px;
		}
		.ConsultingFloat .ContentWrap .Content button:hover {
			background-color: #633bfa;
		}

		.LayerWrap {
			z-index: 9999;
			display: block;
			position: fixed;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			text-align: center;
			background-color: rgba(0, 0, 0, 0.5);
			visibility: hidden;
			opacity: 0;
			-webkit-transition: opacity 0.5s ease, visibility 0.5s ease;
			transition: opacity 0.5s ease, visibility 0.5s ease;
		}
		.LayerWrap.on {
			visibility: visible;
			opacity: 1;
			-webkit-transition: opacity 0.5s ease, visibility 0.5s ease;
			transition: opacity 0.5s ease, visibility 0.5s ease;
		}
		.LayerWrap:before {
			content: "";
			display: inline-block;
			height: 100%;
			vertical-align: middle;
		}
		.PopLayer .BtnClose {
			background-image: url("../images/franchise/m/btn-close.png");
			background-repeat: no-repeat;
			background-size: 50%;
			background-position: center;
			position: absolute;
			width: 50px;
			height: 50px;
			top: 10px;
			right: 10px;
		}
		.PopLayer {
			display: inline-block;
			vertical-align: middle;
			background-color:#fff;
			border: 4px solid #001f32;
			z-index: 10;
			position: relative;
		}
		.PopLayer .PopContainer {
			position: absolute;
			top: 38%;
			transform: translateY(-38%);
			left: 6vw;
			right: 6vw;
			text-align: center;
			color: #303030;
			word-break: keep-all;
			letter-spacing: -1px;
		}

		#Dialog .PopLayer {
			position: relative;
			width: 500px;
			min-height: 300px;
		}
		#Dialog .PopLayer .PopContainer {
			left: 0;
			right: 0;
			font-size: 30px;
			line-height: 40px;
		}
		#Dialog .PopLayer .PopContainer p.Msg {
			font-weight: 700;
		}
		#Dialog .PopLayer .BtnGroup {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 40px;
		}
		#Dialog .PopLayer .BtnGroup button {
			width: 150px;
			height: 50px;
			color: #fff;
			font-size: 25px;
			font-weight: 600;
			margin: 0 15px;
		}
		#Dialog .PopLayer .BtnGroup button.Ok {
			background-color: #001f32;
		}
		#Dialog .PopLayer .BtnGroup button.Cancel {
			background-color: #333;
			display: none;
		}
		#Dialog .PopLayer .BtnGroup button {
			filter: invert(10%);
		}
		#Dialog .PopLayer .BtnGroup button.Cancel.Confirm {
			display: inline-block;
		}

		@media only screen and (max-width : 1320px) {
			.SubContainer {
				min-width: 95%;
				max-width: 95%;
			}
		
			.Home .TitleWrap .Gnb {
				margin-top: 95px;
			}
			.Home .TitleWrap .Desc {
				margin-top: 110px;
			}
			.Home .TitleWrap .Desc p.Top {
				font-size: 42px;
				letter-spacing: 5px;
			}
			.Home .TitleWrap .Desc p.Top span {
				font-size: 32px;
			}
			.Home .TitleWrap .Desc p.Middle span {
				font-size: 120px;
			}
			.Home .TitleWrap .Desc p.Middle {
				font-size: 68px;
				margin-top: 23px;
				letter-spacing: 8px;
			}
			.Home .TitleWrap .Desc p.Bottom {
				font-size: 94px;
				line-height: 94px;
			}
			.Home .TitleWrap .CircleWrap {
				margin: 78px 60px 0 60px;
			}
			.Home .TitleWrap .CircleWrap .Circle {
				width: 15vw;
				height: 15vw;
			}
			.Home .TitleWrap .CircleWrap .Circle div p {
				font-size: 1.6vw;
			}
			.Home .TitleWrap .CircleWrap .Circle div p.Small {
				font-size: 1.2vw;
			}
		}
	}
	
	@media only screen and (max-width : 1023px) {
		.PC { display: none !important; }

		.Container {
			overflow-y: auto;
			width: 100%;
			height: 100vh;
			scroll-behavior: smooth;
			-webkit-overflow-scrolling: touch;
		}

		.SubContainer {
			min-width: 87%;
			max-width: 87%;
			height: 100%;
			overflow: hidden;
			margin: 0 auto;
			position: relative;
		}
	
		.MainContent {
			min-width: 90%;
			max-width: 90%;
			height: 100%;
			overflow: hidden;
			margin: 0 auto;
			position: relative;
		}

		.Home {
			position: relative;
		}
		.Home .TitleWrap {
			width: 100vw;
			height: 190vw;
			text-align: center;
		}
		.Home .TitleWrap.TopBg {
			background-image: url("../images/landing/topBg.png");
			background-position: top;
			background-repeat: no-repeat;
			background-size: 100% 100%;
		}
		.Home .TitleWrap .Gnb {
			width: 100%;
		}
		.Home .TitleWrap .Gnb Img {
			margin-top: 12vw;
		}
		.Home .TitleWrap .Desc {
			margin-top: 15vw;
		}
		.Home .TitleWrap .Desc p.Top {
			font-size: 5vw;
			color: #fff;
			font-weight: 700;
			letter-spacing: 0.5vw;
			text-decoration-line: underline;
			text-decoration-color: #ef4840;
			text-decoration-style: solid;
			text-decoration-thickness: 7px;
			text-underline-offset: 6px;
		}
		.Home .TitleWrap .Desc p.Top span {
			font-size: 4.7vw;
			font-weight: 400;
		}
		.Home .TitleWrap .Desc p.Middle {
			font-size: 7vw;
			font-weight: 400;
			color: #fff;
			margin-top: 6vw;
		}
		.Home .TitleWrap .Desc p.Middle span {
			font-family: 'S-CoreDream-9Black';
			font-size: 14vw;
			letter-spacing: 0.5vw;
			color: #ef4840;
		}
		.Home .TitleWrap .Desc p.Bottom {
			font-size: 13vw;
			font-weight: 300;
			color: #fff;
			letter-spacing: 0.5vw;
			line-height: 13vw;
		}
		.Home .TitleWrap .CircleWrap {
			margin-top: 14vw;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
		.Home .TitleWrap .CircleWrap .Circle {
			width: 100%;
			height: 15vw;
			border-radius: 10vw;
			background-color: #ef4840;
			position: relative;
			margin-top: 2vw;
		}
		.Home .TitleWrap .CircleWrap .Circle div {
			position: absolute;
			left: 0;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
		}
		.Home .TitleWrap .CircleWrap .Circle div p {
			color: #fff;
			font-size: 5.2vw;
			font-family: 'S-CoreDream-9Black';
		}
		.Home .TitleWrap .CircleWrap .Circle:last-child div p {
			font-size: 3.8vw;
		}
		.Home .TitleWrap .CircleWrap .Circle div p span {
			color: #fff25d;
		}

		.Home .BtnClose {
			background-image: url("../images/landing/icon-close.png");
			background-repeat: no-repeat;
			background-size: 100%;
			background-position: center;
			position: absolute;
			width: 5vw;
			height: 5vw;
			top: 5vw;
			right: 5vw;
			filter: brightness(0) invert(1);
			z-index: 999;
		}

		.Competitive {
			background-color: #efefef;
		}
		.Competitive .TitleWrap {
			margin-top: 11vw;
		}
		.Competitive .TitleWrap p {
			font-size: 6vw;
			line-height: 7vw;
			color: #142435;
			font-weight: 700;
			text-align: left;
		}
		.Competitive .Wrap .Content {
			width: 100%;
			height: 100%;
			margin: 10vw 0 15vw 0;
			position: unset;
		}
		.Competitive .Wrap .Content .ContentWrap {
			position: relative;
		}
		.Competitive .Wrap .Content .ContentWrap .Img {
			width: 100%;
			height: 35vw;
			position: relative;
		}
		.Competitive .Wrap .Content .ContentWrap .Img .LineWrap {
			width: 100%;
			height: 100%;
			position: relative;
		}
		.Competitive .Wrap .Content .ContentWrap .Img .Line {
			position: absolute;
			width: 7vw;
			height: 1px;
			right: 36%;
			top: 50%;
			transform: translate(0, -50%);
			background-color: #f6453d;
		}
		.Competitive .Wrap .Content .ContentWrap .Img p {
			color: #142435;
			word-break: keep-all;
			font-weight: 700;
			position: absolute;
			width: 35%;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
			line-height: 4.9vw;
			font-size: 4vw;
		}
		.Competitive .Wrap .Content .DescWrap {
			margin-top: 4vw;
			display: inline-block;
		}
		.Competitive .Wrap .Content .DescWrap p {
			color: #142435;
			font-size: 3.8vw;
			line-height: 6vw;
			font-weight: 400;
			word-break: keep-all;
		}
		.Competitive .Wrap .Content .ContentWrap:not(:first-child) {
			margin-top: 10vw;;
		}
		.Competitive .Wrap .Content .Img {
			background-repeat: no-repeat;
			background-size: 60% 100%;
		}
		.Competitive .Wrap .ContentWrap:nth-child(1) .Img {
			background-image: url("../images/landing/img-competitive1.jpg");
		}
		.Competitive .Wrap .ContentWrap:nth-child(2) .Img {
			background-image: url("../images/landing/img-competitive2.jpg");
		}
		.Competitive .Wrap .ContentWrap:nth-child(3) .Img {
			background-image: url("../images/landing/img-competitive3.jpg");
		}

		.Video {
			overflow: hidden;
			position: relative;
			min-height: 44vw;
			max-height: 850px;
			padding: 4vw 0 7vw 0;
			background-color: #142435;
		}

		.Video .SubContainer {
			text-align: center;
		}

		.Video .TitleWrap p {
			font-size: 6vw;
			color: #fff;
			font-weight: 700;
			text-align: center;
			margin-bottom: 2.5vw;
			word-break: keep-all;
		}
		.Video .TitleWrap p span {
			color: #ef4840;
		}

		.Video .VideoWrap {
			position: relative;
			padding-bottom: 56%;
			height: 0;
		}
		.Video .VideoWrap iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		.MachineType {
			position: relative;
			height: 200vw;
			background-color: #142435;
		}
		.MachineType .Bg {
			background-color: #142435;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 86vw;
		}
		.MachineType .SubContainer {
			height: 100%;
			position: relative;
			text-align: center;
		}
		.MachineType .MainContent {
			min-width: 90%;
			max-width: 90%;
		}
		.MachineType .TitleWrap {
			margin-top: 11vw;
		}
		.MachineType .TitleWrap p:first-child {
			font-size: 6vw;
			line-height: 7vw;
			color: #fff;
			font-weight: 700;
			text-align: left;
			word-break: keep-all;
		}
		.MachineType .TitleWrap p:first-child span {
			color: #f5453d;
		}
		.MachineType .TitleWrap p:last-child {
			margin-top: 4vw;
			font-size: 3.6vw;
			line-height: 6vw;
			color: #fff;
			font-weight: 400;
			word-break: keep-all;
			text-align: left;
		}
		.MachineType .DotArea {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 123vw;
		}
		.MachineType .DotArea button {
			width: 5vw;
			height: 5vw;
			border-radius: 50%;
			background-color: #f6453d;
			opacity: 0.4;
			margin: 0 2vw;
			transition: opacity 0.5s ease;
		}
		.MachineType .DotArea button.Active {
			opacity: 1;
		}
		.MachineType .Slide {
			width: 100%;
			height: 120vw;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
		}
		.MachineType .ContentWrap {
			width: 100%;
			height: 100%;
		}
		.MachineType .ContentWrap .Content {
			background-color: #fff;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			position: relative;
			display: table;
		}
		.MachineType .Slide .Content.Install {
			width: 100%;
			height: 72vw;
			background-image: url("../images/landing/install-type.jpg");
			background-size: cover;
		}
		.MachineType .Slide .Content.Store {
			width: 100%;
			height: 72vw;
			background-image: url("../images/landing/store-type.png");
			background-size: cover;
		}
		.MachineType .ContentWrap .Content .TitleWrap {
			position: absolute;
			bottom: 0;
			width: 100%;
			height: 14vw;
			background-color: rgba(0, 0,0, 0.8);
			padding-left: 8vw;
			text-align: left;
			display: table;
		}
		.MachineType .ContentWrap .Content .TitleWrap .Title {
			display: table-cell;
			vertical-align: middle;
			font-size: 4vw;
			color: #fff;
			font-weight: 600;
			line-height: 4.5vw;
		}
		.MachineType .ContentWrap .Content .TitleWrap .Title span {
			color: #f6453d;
			font-size: 3.5vw;
		}
		.MachineType .ContentWrap .Content .TitleWrap .Title span.Event {
			font-size: 3vw;
			color: #bbbbbb;
		}

		.MachineType .ContentWrap .Desc {
			margin-top: 6vw;
			text-align: left;
		}
		.MachineType .ContentWrap .Desc p.Sub {
			font-size: 3.8vw;
			line-height: 5vw;
			color: #fff;
			font-weight: 600;
			margin-bottom: 2vw;
			word-break: keep-all;
		}
		.MachineType .ContentWrap .Desc p:not(.Sub) {
			font-size: 3.5vw;
			line-height: 5.5vw;
			color: #fff;
			font-weight: 400;
			margin-left: 4vw;
			word-break: keep-all;
		}

		.Price {
			position: relative;
			background-color: #fff;
			padding: 11vw 0;
		}
		.Price .TitleWrap p {
			font-size: 6vw;
			line-height: 7vw;
			color: #142435;
			font-weight: 700;
			text-align: left;
			word-break: keep-all;
		}
		.Price .TitleWrap p span {
			color: #f5453d;
		}
		.Price .TitleWrap p:nth-child(3) {
			margin-top: 4vw;
			font-size: 3.6vw;
			line-height: 6vw;
			color: #142435;
			font-weight: 400;
			word-break: keep-all;
		}
		.Price .TitleWrap p span {
			color: #f5453d;
		}
		.Price .TitleWrap p span.Small {
			color: #142435;
			font-size: 3vw;
		}
		.Price .ContentWrap {
			margin-top: 5vw;
		}
		.Price .ContentWrap .Vat {
			text-align: right;
			font-size: 3vw;
			font-weight: 500;
		}
		.Price .ContentWrap .Desc {
			margin-top: 2vw;
			font-size: 3vw;
			font-weight: 500;
			word-break: keep-all;
		}
		.Price .ContentWrap .Table {
			display: table;
			width: 100%;
		}
		.Price .ContentWrap .Table .TableRow {
			display: table-row;
		}
		.Price .ContentWrap .Table .TableRow:not(:first-child) .TableCell {
			border-top: 1px solid #e1e1e1;
		}
		.Price .ContentWrap .Table .TableRow .TableCell:not(:first-child) {
			border-left: 1px solid #e1e1e1;
		}
		.Price .ContentWrap .Table .TableRow.Title {
			background-color: #142435;
			height: 10vw;
		}
		.Price .ContentWrap .Table .TableRow .TableCell {
			display: table-cell;
			color: #333;
			font-weight: 500;
			font-size: 3vw;
			text-align: center;
			vertical-align: middle;
			word-break: keep-all;
		}
		.Price .ContentWrap .Table .TableRow:first-child .TableCell,
		.Price .ContentWrap .Table .TableRow .TableCell:first-child {
			color: #fff;
			font-weight: 600;
			font-size: 3vw;
			text-align: center;
			background-color: #142435;
		}
		.Price .ContentWrap .Table .TableRow .TableCell:first-child {
			border-left: none;
			padding: 0 2%;
		}
		.Price .ContentWrap .Table .TableRow .TableCell:last-child {
			border-right: none;
		}
		.Price .ContentWrap .Table .TableRow:first-child {
			border-top: none;
		}
		.Price .ContentWrap .Table .TableRow:last-child {
			border-bottom: none;
		}
		.Price .ContentWrap .Table .TableRow:last-child .TableCell {
			border-bottom: 1px solid #e1e1e1;
		}
		.Price .ContentWrap .Table .TableRow:last-child .TableCell:first-child {
			border-bottom: 1px solid #142435;
		}

		.Price .StoreWrap .Table .TableRow .TableCell .Cancel {
			text-decoration: line-through;
			text-decoration-color: #f5453d;
		}
		.Price .StoreWrap .Table .TableRow .TableCell .Important {
			color: #f5453d;
			font-weight: 600;
			font-size: 3.1vw;
			margin: 0 5%;
		}
		.Price .StoreWrap .Table .TableRow {
			height: 14vw;
		}
		.Price .StoreWrap .Table .TableRow:nth-child(8) {
			background-color: #fdeceb;
		}
		.Price .StoreWrap .Table .TableRow:nth-child(9) {
			background-color: #fbc7c5;
		}
		.Price .StoreWrap .Table .TableRow .TableCell:nth-child(1) {
			width: 29%;
		}
		.Price .StoreWrap .Table .TableRow .TableCell:nth-child(2) {
			width: 27%;
		}
		.Price .InstallWrap {
			margin-top: 15vw;
		}
		.Price .InstallWrap .Table .TableRow .TableCell:first-child {
			width: 22%;
		}
		.Price .InstallWrap .Table .TableRow:nth-child(2) {
			background-color: #f1f1f1;
			width: auto;
		}
		.Price .InstallWrap .Table .TableRow:nth-child(2) .TableCell {
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
		}
		.Price .InstallWrap .Table .TableRow:nth-child(2) .TableCell button span {
			font-size: 2vw;
		}
		.Price .InstallWrap .Table .TableRow:nth-child(2) .TableCell img {
			width: 100%;
			height: auto;
			object-fit: cover;
		}
		.Price .InstallWrap .Table .TableRow:nth-child(3) {
			background-color: #fdeceb;
			height: 15vw;
		}
		.Price .InstallWrap .Table .TableRow:nth-child(4) {
			background-color: #fbc7c5;
			height: 15vw;
		}
		.Price .InstallWrap .Table .TableRow:last-child .TableCell:first-child p {
			display: inline;
		}

		.Machine {
			position: relative;
			height: 210vw;
			background-color: #efefef;
		}
		.Machine .Bg {
			background-color: #efefef;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 86vw;
		}
		.Machine .SubContainer {
			height: 100%;
			position: relative;
			text-align: center;
		}
		.Machine .MainContent {
			min-width: 90%;
			max-width: 90%;
		}
		.Machine .TitleWrap {
			margin-top: 11vw;
		}
		.Machine .TitleWrap p:first-child {
			font-size: 6vw;
			line-height: 7vw;
			color: #142435;
			font-weight: 700;
			text-align: left;
		}
		.Machine .TitleWrap p:first-child span {
			color: #f5453d;
		}
		.Machine .TitleWrap p:last-child {
			margin-top: 4vw;
			font-size: 3.6vw;
			line-height: 6vw;
			color: #142435;
			font-weight: 400;
			word-break: keep-all;
			text-align: left;
		}
		.Machine .DotArea {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 123vw;
		}
		.Machine .DotArea button {
			width: 5vw;
			height: 5vw;
			border-radius: 50%;
			background-color: #f6453d;
			opacity: 0.4;
			margin: 0 2vw;
			transition: opacity 0.5s ease;
		}
		.Machine .DotArea button.Active {
			opacity: 1;
		}
		.Machine .Slide {
			width: 100%;
			height: 120vw;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
		}
		.Machine .ContentWrap {
			width: 100%;
			height: 100%;
		}
		.Machine .ContentWrap .Content {
			background-color: #fff;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			position: relative;
			display: table;
		}
		.Machine .Slide .Content.Coffee1 {
			width: 100%;
			height: 48vw;
			background-image: url("../images/landing/cafero.png");
			background-size: 60%;
		}
		.Machine .Slide .Content.Coffee2 {
			width: 100%;
			height: 48vw;
			background-image: url("../images/landing/release.png");
			background-size: 90%;
			background-position: 50% -10%;
		}
		.Machine .Slide .Content.Celeb {
			width: 100%;
			height: 48vw;
			background-image: url("../images/landing/celeb.png");
			background-size: 70%;
		}
		.Machine .ContentWrap .Content .TitleWrap {
			position: absolute;
			bottom: 0;
			width: 100%;
			height: 14vw;
			background-color: rgba(0, 0,0, 0.8);
			padding-left: 8vw;
			text-align: left;
			display: table;
		}
		.Machine .ContentWrap .Content .TitleWrap .Title {
			display: table-cell;
			vertical-align: middle;
			font-size: 4vw;
			color: #fff;
			font-weight: 600;
			line-height: 4.5vw;
		}
		.Machine .ContentWrap .Content .TitleWrap .Title span {
			color: #f6453d;
			font-size: 3.5vw;
		}

		.Machine .ContentWrap .Desc {
			margin-top: 6vw;
			text-align: left;
		}
		.Machine .ContentWrap .Desc p:not(.Sub) {
			font-size: 3.5vw;
			line-height: 5.5vw;
			color: #142435;
			font-weight: 400;
			margin-left: 4vw;
			word-break: keep-all;
		}
		.Machine .ContentWrap .Desc p.Sub {
			font-size: 3.8vw;
			line-height: 5vw;
			color: #142435;
			font-weight: 600;
			margin-bottom: 4vw;
			word-break: keep-all;
		}

		.Menu {
			background-color: #fff;
			background-image: url("../images/brand/bg-menu.png");
			background-repeat: no-repeat;
			background-size: 100% 70%;
			height: 275vw;
			position: relative;
		}
		.Menu .TitleWrap {
			margin-top: 11vw;
		}
		.Menu .TitleWrap p:first-child {
			font-size: 5.3vw;
			line-height: 7vw;
			color: #fff;
			font-weight: 700;
			text-align: left;
			word-break: keep-all;
		}
		.Menu .TitleWrap p:first-child span {
			color: #f5453d;
		}
		.Menu .TitleWrap p:last-child {
			margin-top: 4vw;
			font-size: 3.3vw;
			line-height: 6vw;
			color: #fff;
			font-weight: 400;
			word-break: keep-all;
			text-align: left;
		}
		.Menu .MainContent {
			position: absolute;
			top: 62vw;
			left: 0;
			right: 0;
			bottom: 0;
		}
		.Menu ul.NavContainer {
			position: absolute;
			left: 0;
			right: 0;
			top: 2vw;
			z-index: 3;
		}
		.Menu ul.NavContainer li {
			float: left;
			margin-right: 4vw;
		}
		.Menu ul.NavContainer li button {
			width: 5vw;
			height: 5vw;
			border-radius: 50%;
			border: none;
			background: rgb(255,51,51);
			opacity: 0.3;
			-webkit-transition: opacity 0.5s ease;
			transition: opacity 0.5s ease;
		}
		.Menu ul.NavContainer li button.active {
			width: 5vw;
			height: 5vw;
			border-radius: 50%;
			border: none;
			background: rgb(255,51,51);
			opacity: 1;
			-webkit-transition: opacity 0.5s ease;
			transition: opacity 0.5s ease;
		}
		.Menu .Slide {
			z-index: 2;
		}
		.Menu .Slide .PageContainer .PageWrap {
			position: relative;
			margin-top: 8vw;
			height: 68vw;
		}
		.Menu .Slide .PageContainer .PageWrap p.Title {
			font-size: 6vw;
			color: #fff;
			font-weight: 700;
			margin-bottom: 5vw;
		}
		.Menu .Slide .PageContainer .PageWrap p.TitleSub {
			font-size: 4.4vw;
			color: #fff;
			font-weight: 600;
			margin: 6vw 0 3vw 0;
		}
		.Menu .Slide .PageContainer .PageWrap p.Desc {
			font-size: 3.6vw;
			color: #fff;
			font-weight: 300;
			line-height: 5vw;
			word-break: keep-all;
		}
		.Menu .Slide .PageContainer .PageWrap .MenuWrap {
			display: block;
		}
		.Menu .Slide .PageContainer .PageWrap .MenuWrap div {
			float: left;
			width: 50%;
		}
		.Menu .Slide .PageContainer .PageWrap .MenuWrap div.Row {
			width: 100% !important;
		}
		.Menu .Slide .PageContainer .PageWrap p.MenuTitle {
			font-size: 3.5vw;
			color: #fff;
			font-weight: 400;
			line-height: 5.5vw;
			word-break: keep-all;
		}
		.Menu .Slide .PageContainer .PageWrap p.MenuTitle span {
			margin-left: 0 !important;
		}
		.Menu .Slide .PageContainer .PageWrap p.Caution {
			display: inline-block;
			font-size: 2.9vw;
			color: #7e93a9;
			font-weight: 400;
			margin-top: 2%;
			word-break: break-all;
			letter-spacing: -0.5px;
		}
		.Menu .Slide .PageContainer .PageWrap button.More {
			display: block;
			font-size: 3vw;
			color: #999;
			font-weight: 400;
			background-image: url("../images/brand/m/btn-more.png");
			background-repeat: no-repeat;
			background-position: 100%;
			width: 14vw;
			text-align: left;
			position: absolute;
			bottom: 0;
			right: 0;
		}
		.Menu .Slide .PageContainer .PageWrap button.More:hover {
			background-color: rgba(153,153,153,1);
			color: #fff;
		}
		.Menu .Right {
			width: 100%;
			height: 100%;
			background-color: #fff;
			text-align: center;
		}
		.Menu .Right .Wrap {
			position: relative;
			margin-top: 6vw;
		}
		.Menu .Right .Wrap ul {
			padding-top: 6vw;
			display: inline-block;
		}
		.Menu .Right .Wrap ul.on {
			opacity: 1;
			-webkit-transition: opacity 0.5s ease;
			transition: opacity 0.5s ease;
		}
		.Menu .Right .Wrap ul.off {
			opacity: 0;
			-webkit-transition: opacity 0.5s ease;
			transition: opacity 0.5s ease;
		}
		.Menu .Right .Wrap ul li {
			display: inline-block;
			cursor: pointer;
			position: relative;
		}
		.Menu .Right .Wrap ul li button {
			display: inline-block;
			text-align: center;
			font: normal 4vw 'Noto Sans KR';
			text-transform: uppercase;
			font-weight: 300;
			color: #142435;
			line-height: 4vw;
			padding: 0 1px;
		}
		.Menu .Right .Wrap ul li button::before {
			display: block;
			content: attr(title);
			font-weight: 600;
			height: 0;
			overflow: hidden;
			visibility: hidden;
		}
		.Menu .Right .Wrap ul li:not(:last-child) {
			padding-right: 3vw;
		}
		.Menu .Right .Wrap ul li.active button,
		.Menu .Right .Wrap ul li:hover button {
			font-weight: 600;
			border-bottom: 0.7vw solid #faa29e;
		}
		.Menu .Right .Wrap ul li .Underline {
			opacity: 0;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			height: 0.7vw;
			background-color: #faa29e;
			z-index: 1;
		}
		.Menu .Right .Wrap .ElementWrap {
			width: 100%;
			height: 100%;
			position: relative;
			background-color: red;
		}
		.Menu .Right .Wrap .Element {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}
		.Menu .Right .Wrap .Element img {
			object-fit: contain;
			width: 50%;
			margin: 5vw 0;
		}
		.Menu .Right .Wrap .Element.on {
			opacity: 1;
			-webkit-transition: opacity 0.5s ease;
			transition: opacity 0.5s ease;
		}
		.Menu .Right .Wrap .Element.off {
			opacity: 0;
			-webkit-transition: opacity 0.5s ease;
			transition: opacity 0.5s ease;
		}
		.Menu .Right .Wrap .Element p {
			margin: 0.2vw 0;
		}
		.Menu .Right .Wrap .Element .Title {
			color: #f5453d;
			font-size: 4vw;
			line-height: 4vw;
			font-weight: 600;
		}
		.Menu .Right .Wrap .Element .Content {
			color: #142435;
			font-size: 4vw;
			line-height: 4vw;
			font-weight: 600;
		}
		.Menu .Right .Wrap .Element .Sub {
			color: #142435;
			font-size: 3vw;
			font-weight: 300;
			line-height: 3vw;
			display: block;
			margin: 1.6vw 0;
		}
		.Menu .Right .Wrap .Element .Desc {
			font-size: 4vw;
			color: #555;
			font-weight: 300;
			line-height: 5.5vw;
			margin-top: 3vw;
			word-break: keep-all;
		}
		.Menu .Right .Wrap .Element:nth-child(1) p.FirstRow {
			margin-top: 4vw !important;
		}
		.Menu .Right .Wrap .Element:nth-child(2) img {
			margin-top: 1vw !important;
		}
		.Menu .Right .Wrap .Element:nth-child(2) p.FirstRow {
			margin-top: 3.6vw !important;
		}
		.Menu .Right .Wrap .Element:nth-child(3) p.FirstRow {
			margin-top: 3.5vw !important;
		}
		.Menu .Right .Wrap .Element:nth-child(4) img {
			margin-top: 12vw !important;
		}
		.Menu .Right .Wrap .Element:nth-child(4) p.FirstRow {
			margin-top: 5vw !important;
		}
		.Menu .Right .Wrap .Element:nth-child(5) img {
			margin-top: 5.5vw !important;
		}
		.Menu .Right .Wrap .Element:nth-child(5) p.FirstRow {
			margin-top: 1.5vw !important;
		}
		.Menu .Right .Wrap .Element:nth-child(6) img {
			margin-top: 10vw !important;
		}
		.Menu .Right .Wrap .Element:nth-child(6) p.FirstRow {
			margin-top: 6vw !important;
		}
		.Menu .Right .Wrap .Element:nth-child(7) img {
			margin-top: 10vw !important;
		}
		.Menu .Right .Wrap .Element:nth-child(7) p.FirstRow {
			margin-top: 6vw !important;
		}
	
		.Store {
			background-color: #eee;
			height: 190vw;
			position: relative;
		}
		.Store .TitleWrap {
			margin-top: 11vw;
		}
		.Store .TitleWrap p:first-child {
			font-size: 5.3vw;
			line-height: 7vw;
			color: #142435;
			font-weight: 700;
			text-align: left;
			word-break: keep-all;
		}
		.Store .TitleWrap p:first-child span {
			color: #f5453d;
		}
		.Store .TitleWrap p:last-child {
			margin-top: 4vw;
			font-size: 3.3vw;
			line-height: 6vw;
			color: #142435;
			font-weight: 400;
			word-break: keep-all;
			text-align: left;
		}
		.Store ul.NavContainer {
			width: 100%;
			height: 5vw;
			margin-top: 10vw;
		}
		.Store ul.NavContainer li {
			float: left;
			margin-right: 4vw;
		}
		.Store ul.NavContainer li button {
			width: 5vw;
			height: 5vw;
			border-radius: 50%;
			border: none;
			background: rgb(255,51,51);
			opacity: 0.3;
			-webkit-transition: opacity 0.5s ease;
			transition: opacity 0.5s ease;
		}
		.Store ul.NavContainer li button.active {
			width: 5vw;
			height: 5vw;
			border-radius: 50%;
			border: none;
			background: rgb(255,51,51);
			opacity: 1;
			-webkit-transition: opacity 0.5s ease;
			transition: opacity 0.5s ease;
		}
		.Store .PageContainer {
			width: 100%;
			margin-top: 5vw;
		}
		.Store .PageContainer .PageWrap p.Title {
			font-size: 6vw;
			color: #142435;
			font-weight: 700;
			margin-bottom: 5vw;
		}
		.Store .PageContainer .PageWrap p.Desc {
			font-size: 3.6vw;
			color: #142435;
			font-weight: 300;
			line-height: 5vw;
			word-break: keep-all;
		}
	
		.Store .Left .ElementWrap {
			position: absolute;
			left: 0;
			bottom: 10vw;
			width: 100%;
			height: 29.1vw;
		}
		.Store .Left .ElementWrap .slick-slide {
			width: 29vw;
			height: 29.1vw;
		}
		.Store .Left .ElementWrap .Img {
			background-repeat: no-repeat;
			background-size: 97%;
			width: 29vw;
			height: 29.1vw;
		}
		.Store .Left .ElementWrap .Thumb1 {
			background-image: url("../images/landing/store/thumb/black-1.png");
		}
		.Store .Left .ElementWrap .Thumb1.Active {
			background-image: url("../images/landing/store/thumb/color-1.png");
		}
		.Store .Left .ElementWrap .Thumb2 {
			background-image: url("../images/landing/store/thumb/black-2.png");
		}
		.Store .Left .ElementWrap .Thumb2.Active {
			background-image: url("../images/landing/store/thumb/color-2.png");
		}
		.Store .Left .ElementWrap .Thumb3 {
			background-image: url("../images/landing/store/thumb/black-3.png");
		}
		.Store .Left .ElementWrap .Thumb3.Active {
			background-image: url("../images/landing/store/thumb/color-3.png");
		}
		.Store .Left .ElementWrap .Thumb4 {
			background-image: url("../images/landing/store/thumb/black-4.png");
		}
		.Store .Left .ElementWrap .Thumb4.Active {
			background-image: url("../images/landing/store/thumb/color-4.png");
		}
		.Store .Left .ElementWrap .Thumb5 {
			background-image: url("../images/landing/store/thumb/black-5.png");
		}
		.Store .Left .ElementWrap .Thumb5.Active {
			background-image: url("../images/landing/store/thumb/color-5.png");
		}
		.Store .Left .ElementWrap .Thumb6 {
			background-image: url("../images/landing/store/thumb/black-6.png");
		}
		.Store .Left .ElementWrap .Thumb6.Active {
			background-image: url("../images/landing/store/thumb/color-6.png");
		}
		.Store .Left .ElementWrap .Thumb7 {
			background-image: url("../images/landing/store/thumb/black-7.png");
		}
		.Store .Left .ElementWrap .Thumb7.Active {
			background-image: url("../images/landing/store/thumb/color-7.png");
		}
		.Store .Left .ElementWrap .Thumb8 {
			background-image: url("../images/landing/store/thumb/black-8.png");
		}
		.Store .Left .ElementWrap .Thumb8.Active {
			background-image: url("../images/landing/store/thumb/color-8.png");
		}
		.Store .Left .ElementWrap .Thumb9 {
			background-image: url("../images/landing/store/thumb/black-9.png");
		}
		.Store .Left .ElementWrap .Thumb9.Active {
			background-image: url("../images/landing/store/thumb/color-9.png");
		}
		.Store .Left .ElementWrap .Thumb10 {
			background-image: url("../images/landing/store/thumb/black-10.png");
		}
		.Store .Left .ElementWrap .Thumb10.Active {
			background-image: url("../images/landing/store/thumb/color-10.png");
		}

		.Store .Right .Wrap .ElementWrap {
			position: relative;
			margin-top: 6vw;
		}
		.Store .Right .Wrap .ElementWrap .Img {
			background-repeat: no-repeat;
			background-size: cover;
			width: 91vw;
			height: 93vw;
		}
		.Store .Right .Wrap .ElementWrap .Element1 {
			background-image: url("../images/landing/store/store1.png");
		}
		.Store .Right .Wrap .ElementWrap .Element2 {
			background-image: url("../images/landing/store/store2.png");
		}
		.Store .Right .Wrap .ElementWrap .Element3 {
			background-image: url("../images/landing/store/store3.png");
		}
		.Store .Right .Wrap .ElementWrap .Element4 {
			background-image: url("../images/landing/store/store4.png");
		}
		.Store .Right .Wrap .ElementWrap .Element5 {
			background-image: url("../images/landing/store/store5.png");
		}
		.Store .Right .Wrap .ElementWrap .Element6 {
			background-image: url("../images/landing/store/store6.png");
		}
		.Store .Right .Wrap .ElementWrap .Element7 {
			background-image: url("../images/landing/store/store7.png");
		}
		.Store .Right .Wrap .ElementWrap .Element8 {
			background-image: url("../images/landing/store/store8.png");
		}
		.Store .Right .Wrap .ElementWrap .Element9 {
			background-image: url("../images/landing/store/store9.png");
		}
		.Store .Right .Wrap .ElementWrap .Element10 {
			background-image: url("../images/landing/store/store10.png");
		}

		.Faq {
			position: relative;
			min-height: 14vw;
			background-color: #0e1c2c;
		}
		.Faq .SubContainer {
			height: 100%;
			position: relative;
		}
		.Faq .TitleWrap {
			margin-top: 11vw;
		}
		.Faq .TitleWrap p:first-child {
			color: #fff;
			font-size: 3.6vw;
			font-weight: 500;
		}
		.Faq .TitleWrap p:last-child {
			color: #fff;
			font-size: 7vw;
			font-weight: 700;
		}
		.Faq .ContentRow {
			width: 100%;
			display: flex;
			flex-direction: row;
		}
		.Faq .ContentRow:first-child {
			margin-top: 7vw;
		}
		.Faq .Line {
			width: 100%;
			height: 2px;
			background-image: url(../images/landing/dot.png);
			background-repeat: repeat-x;
			margin: 7vw 0;
		}
		.Faq .Line:last-child {
			margin-bottom: 10vw;
		}
		.Faq .ContentRow .No {
			min-width: 15vw;
			max-width: 15vw;
			color: #fff;
			font-size: 7vw;
			line-height: 7vw;
			font-weight: 400;
		}
		.Faq .ContentRow .DescWrap .Title {
			color: #fff;
			font-size: 4vw;
			font-weight: 500;
			margin-bottom: 2vw;
			word-wrap: break-word;
		}
		.Faq .ContentRow .DescWrap p {
			max-width: 71vw;
			color: #fff;
			font-size: 2.8vw;
			font-weight: 300;
			line-height: 5vw;
			word-wrap: break-word;
		}

		.Open {
			position: relative;
			height: 280vw;
			background-color: #fff;
		}
		.Open .Bg {
			background-color: #efefef;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 26vw;
		}
		.Open .SubContainer {
			height: 100%;
			position: relative;
		}
		.Open .TitleWrap {
			text-align: left;
			margin-top: 12vw;
		}
		.Open .TitleWrap p:first-child {
			color: #142435;
			font-size: 9vw;
			line-height: 9vw;
			font-weight: 700;
		}
		.Open .TitleWrap p:last-child {
			color: #000;
			font-size: 3vw;
			font-weight: 400;
			margin-top: 3vw;
			word-break: keep-all;
			text-align: left;
		}
		.Open .ContentWrap {
			margin-top: 4vw;
			width: 100%;
			height: 100%;
		}
		.Open .ContentWrap .Row .RowContent {
			position: relative;
			width: 50%;
			height: 60vw;
			float: left;
		}
		.Open .ContentWrap .Row .RowContent .Content {
			position: absolute;
			background-color: #fff;
			border: 1px solid #142435;
			top: 4vw;
			bottom: 0;
		}
		.Open .ContentWrap .Row .RowContent:nth-child(1) .Content {
			left: 0;
			right: 2vw;
		}
		.Open .ContentWrap .Row .RowContent:nth-child(2) .Content {
			left: 2vw;
			right: 0;
		}
		.Open .ContentWrap .Row .RowContent .Content .Detail {
			margin: 2vw 4vw;
		}
		.Open .ContentWrap .Row .RowContent .Content .Detail .Top {
			text-align: center;
		}
		.Open .ContentWrap .Row .RowContent .Content .Detail .Top .Img {
			display: inline-block;
			width: 16vw;
			height: 16vw;
			border-radius: 50%;
			background-color: #f5f5f5;
			background-position: center;
			background-repeat: no-repeat;
			background-size: 60%;
		}
		.Open .Row:nth-child(1) .RowContent:nth-child(1) .Img {
			background-image: url("../images/franchise/img-openstep1.png");
		}
		.Open .Row:nth-child(1) .RowContent:nth-child(2) .Img {
			background-image: url("../images/franchise/img-openstep2.png");
		}
		.Open .Row:nth-child(2) .RowContent:nth-child(1) .Img {
			background-image: url("../images/franchise/img-openstep3.png");
		}
		.Open .Row:nth-child(2) .RowContent:nth-child(2) .Img {
			background-image: url("../images/franchise/img-openstep4.png");
		}
		.Open .Row:nth-child(3) .RowContent:nth-child(1) .Img {
			background-image: url("../images/franchise/img-openstep5.png");
		}
		.Open .Row:nth-child(3) .RowContent:nth-child(2) .Img {
			background-image: url("../images/franchise/img-openstep6.png");
		}
		.Open .Row:nth-child(4) .RowContent:nth-child(1) .Img {
			background-image: url("../images/franchise/img-openstep7.png");
		}
		.Open .Row:nth-child(4) .RowContent:nth-child(2) .Img {
			background-image: url("../images/franchise/img-openstep8.png");
		}

		.Open .ContentWrap .Row .RowContent .Content .Detail .Top,
		.Open .ContentWrap .Row .RowContent .Content .Detail .Bottom {
			height: 50%;
		}
		.Open .ContentWrap .Row .RowContent .Content .Detail .Top p.Title {
			text-align: left;
			font-size: 3.5vw;
			color: #f5453d;
			font-weight: 700;
		}
		.Open .ContentWrap .Row .RowContent .Content .Detail .Bottom p.Title {
			font-size: 3.8vw;
			color: #142435;
			font-weight: 700;
			line-height: 5.3vw;
		}
		.Open .ContentWrap .Row .RowContent .Content .Detail .Bottom p.Desc {
			font-size: 3.1vw;
			color: #142435;
			font-weight: 400;
			line-height: 4.8vw;
		}

		.Consulting {
			height: 160vw;
			padding-bottom: 50vw;
		}
		.Consulting .SubContainer {
			max-width: 100%;
			min-width: 100%;
		}
		.Consulting .TitleWrap {
			text-align: center;
			max-width: 90%;
			min-width: 90%;
			margin: 0 auto;
		}
		.Consulting .TitleWrap p:first-child {
			margin-top: 8vw;
			font-size: 9vw;
			line-height: 9vw;
			color: #f6453d;
			font-weight: 700;
			text-align: left;
		}
		.Consulting .TitleWrap p:last-child {
			font-size: 3.5vw;
			line-height: 6.5vw;
			color: #303030;
			font-weight: 400;
			text-align: left;
		}
		.Consulting .MainContent {
			width: 100%;
			height: 100%;
		}
		.Consulting .MainContent .Request {
			width: 100%;
		}
		.Consulting .MainContent .Request .Content {
			margin-top: 4vw;
		}
		.Consulting .MainContent .Request .Row {
			width: 100%;
			height: 9vw;
			margin-bottom: 2vw;
			display: inline-block;
			margin: 0 auto;
		}
		.Consulting .MainContent .Request .Row .Title,
		.Consulting .MainContent .Request .Row .Input {
			float: left;
		}
		.Consulting .MainContent .Request .Row .Title {
			width: 25%;
			line-height: 9vw;
			color: #333;
			font-size: 3.8vw;
			font-weight: 400;
			letter-spacing: -1px;
		}
		.Consulting .MainContent .Request .Row .Input {
			width: 75%;
			height: 100%;
		}
		.Consulting .MainContent .Request .Row .Input input {
			border: 0;
			width: 65vw;
			height: 100%;
			background-color: rgba(241, 241, 241, 0.8);
			font-size: 3.8vw;
			color: #000;
			padding: 0 2vw;
			outline: none;
			display: inline-block;
		}
		.Consulting .MainContent .Request .Row .Radio {
			color: #333;
			font-size: 3.8vw;
			font-weight: 400;
			line-height: 9vw;
		}
		.Consulting .MainContent .Request .Row .Radio label:first-child {
			margin-right: 4vw;
		}
		.Consulting .Radio .Checkmark {
			position: absolute;
			top: 50%;
			left: 0;
			width: 4vw;
			height: 4vw;
			border-radius: 50%;
			transform: translateY(-50%);
			background-color: #fff;
			border: 1px solid #000;
		}
		.Consulting .Radio .Checkmark:after {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 2vw;
			height: 2vw;
			border-radius: 50%;
			transform: translate(-50%, -50%);
			background-color: #000;
			border: 1px solid #000;
		}
		.Consulting .Radio .Label {
			position: relative;
			padding-left: 6vw;
			cursor: pointer;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
		.Consulting .Radio .Label input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
		}
		.Consulting .Radio .Label .Checkmark:after {
			content: "";
			position: absolute;
			display: none;
		}
		.Consulting .Radio .Label input:checked ~ .Checkmark:after {
			display: block;
		}
		.Consulting .MainContent .Request .Row textarea {
			width: 93%;
			height: 35vw;
			background-color: rgba(241, 241, 241, 0.8);
			font-size: 3.8vw;
			color: #000;
			margin-top: 2vw;
			padding: 2vw 3vw;
			outline: none;
			border: none;
		}

		.Consulting .Request .Checkbox {
			margin-top: 5vw !important;
		}
		.Consulting .Request .Checkbox .Label {
			position: relative;
		}
		.Consulting .Request .Checkbox .Checkmark {
			position: absolute;
			top: 50%;
			left: 0;
			width: 4vw;
			height: 4vw;
			transform: translateY(-50%);
			background-color: #fff;
			border: 1px solid #000;
		}
		.Consulting .Request .Checkbox .Checkmark:after {
			position: absolute;
			top: 0.8vw;
			left: 0.8vw;
			width: 2.4vw;
			height: 2.4vw;
			background-color: #000;
		}
		.Consulting .Request .Checkbox .Label {
			color: #333;
			font-size: 3.8vw;
			font-weight: 400;
			position: relative;
			padding-left: 7vw;
			cursor: pointer;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
		.Consulting .Request .Checkbox .Label input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
		}
		.Consulting .Request .Checkbox .Label .Checkmark:after {
			content: "";
			position: absolute;
			display: none;
		}
		.Consulting .Request .Checkbox .Label input:checked ~ .Checkmark:after {
			display: block;
		}
		.Consulting .Request .Checkbox .PrivacyLink {
			font-size: 3.8vw;
			font-weight: 700;
			text-decoration: underline;
			margin-left: 3vw;
			color: #303030;
			cursor: pointer;
		}
		.Consulting .Request .BtnArea {
			position: relative;
			margin-top: 5vw;
		}
		.Consulting .Request .BtnArea button {
			display: inline-block;
			width: 46vw;
			height: 11vw;
			color: #fff;
			font-size: 4.5vw;
			font-weight: 600;
			transition: all 0.3s ease;
			position: absolute;
			top: 4vw;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
		}
		.Consulting .Request .BtnArea button.Request {
			background-color: #222;
		}
		.Consulting .Request .BtnArea button.Request:hover {
			background-color: #444;
		}

		.BottomFloat {
			z-index: 99;
			height: 37vw;
			position: fixed;
			left: 0;
			right: 0;
			bottom: -37vw;
			opacity: 0;
			transition-property: opacity, bottom;
			transition-duration: 0.3s;
			transition-timing-function: ease-in;
			filter: drop-shadow(0 -10px 10px rgba(42, 42, 42, .2));
			
			display: flex;
			flex-direction: column;
			justify-content: space-evenly;
		}
		.BottomFloat.show {
			bottom: 0;
			opacity: 1;
			transition-property: opacity, bottom;
			transition-duration: 0.3s;
			transition-timing-function: ease-out;
		}
		.BottomFloat .Info {
			flex: 5;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			justify-items: center;
		}
		.BottomFloat .Info p {
			font-family: 'S-CoreDream-9Black';
			color: #fff;
			font-size: 5.5vw;
			line-height: 5.5vw;
		}
		.BottomFloat .Info .InfoLeft,
		.BottomFloat .Info .InfoRight {
			display: flex;
			flex: 1;
			height: 100%;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
		.BottomFloat .InfoLeft {
			background-color: #ef4840;
		}
		.BottomFloat .InfoRight {
			background-color: #ffd500;
			position: relative;
		}
		.BottomFloat .Info p.InfoTop,
		.BottomFloat .Info p.InfoBottom {
			margin-top: 1.5vw;
		}
		.BottomFloat .InfoRight p {
			color: #3b2c30;
		}
		.BottomFloat .InfoRight p:nth-child(2) {
			font-family: 'S-CoreDream-5Medium';
		}
		.BottomFloat .InfoRight img {
			position: absolute;
			top: -1.6vw;
			right: 5vw;
			width: 7vw;
			height: 7.5vw;
		}
		.BottomFloat .Contact {
			flex: 5;
			background-color: #142435;
			display: flex;
		}
		.BottomFloat .Contact .Channel {
			display: flex;
			flex: 1;
			justify-content: space-evenly;
			align-items: center;
		}
		.BottomFloat .Contact .Channel a {
			font-size: 3vw;
			color: #ccc;
			font-weight: 500;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
		.BottomFloat .Contact .Channel a:hover {
			font-weight: 700;
			text-decoration: underline;
			filter: brightness(0) invert(1);
		}
		.BottomFloat .Contact .Channel button {
			background-repeat: no-repeat;
			background-position: center center;
			background-size: contain;
			min-width: 30px;
			min-height: 30px;
		}
		.BottomFloat .Contact .Channel button.Instagram {
			background-image: url("../images/brand/m/icon-instagram.png");
		}
		.BottomFloat .Contact .Channel button.Youtube {
			background-image: url("../images/brand/m/icon-youtube.png");
		}
		.BottomFloat .Contact .Channel button.Naver {
			background-image: url("../images/brand/m/icon-naver.png");
		}

		.ConsultingFloat {
			z-index: 98;
			position: fixed;
			left: 0;
			right: 0;
			bottom: -135vw;
			width: 100vw;
			height: 135vw;
			opacity: 0;
			transition-property: opacity, bottom;
			transition-duration: 0.3s;
			transition-timing-function: ease-out;
			background-color: #fff;
			border-top-left-radius: 18px;
			border-top-right-radius: 18px;
			filter: drop-shadow(0 -10px 10px rgba(42, 42, 42, .2));
		}
		.ConsultingFloat.show {
			bottom: -84vw;
			opacity: 1;
			transition-property: opacity, bottom;
			transition-duration: 0.3s;
			transition-timing-function: ease-in;
		}

		.ConsultingFloat.up {
			bottom: 0;
			transition-property: bottom;
			transition-duration: 0.3s;
			transition-timing-function: ease-in;
		}
		
		.ConsultingFloat .Content {
			position: absolute;
			left: 4vw;
			top: 0;
			right: 4vw;
			bottom: 4vw;
		}
		.ConsultingFloat .Content .Top {
			width: 100%;
			height: 14vw;
			position: relative;
		}
		.ConsultingFloat .Content .Title {
			font-family: 'S-CoreDream-9Black';
			color: #0e1c2c;
			font-size: 6vw;
			line-height: 15vw;
			text-align: left;
			letter-spacing: 2px;
			cursor: pointer;
		}
		.ConsultingFloat .Content .Arrow {
			width: 6.5vw;
			height: 4.2vw;
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
		}
		.ConsultingFloat .Content .Arrow.Up {
			background-image: url('../images/landing/arrow-up.png');
			background-size: 100% 100%;
		}
		.ConsultingFloat .Content .Arrow.Down {
			background-image: url('../images/landing/arrow-down.png');
			background-size: 100% 100%;
		}

		.ConsultingFloat .Content input[type="text"],
		.ConsultingFloat .Content input[type="tel"] {
			width: 100%;
			height: 11vw;
			background: none;
			border: none;
			border-bottom: 1px solid #6f6f6f;
			font-weight: 700;
			color: #6f6f6f;
			font-size: 4.4vw;
			margin-bottom: 2vw;
		}
		.ConsultingFloat .Content input[type="text"]:focus,
		.ConsultingFloat .Content input[type="tel"]:focus {
			outline: none;
		}
		.ConsultingFloat .Content input[type="text"]::placeholder,
		.ConsultingFloat .Content input[type="tel"]::placeholder {
			color: #9e9d9d;
		}
		.ConsultingFloat .Content .Sub {
			font-size: 2.7vw;
			color: #818181;
			font-weight: 500;
			word-break: keep-all;
			margin-top: 2vw;
		}
		.ConsultingFloat .Content .Desc {
			background: #ebebeb;
			border-radius: 8px;
			font-size: 2.7vw;
			color: #818181;
			font-weight: 500;
			word-break: keep-all;
			margin-top: 1.3vw;
			padding: 3vw 3.4vw;
		}
		.ConsultingFloat .Content .LabelWrap {
			margin-top: 3vw;
		}
		.ConsultingFloat .Content .Label {
			font-size: 3.3vw;
			font-weight: 500;
			color: #575757;
			position: relative;
			padding-left: 5.5vw;
			cursor: pointer;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
		.ConsultingFloat .Content .Label input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
		}
		.ConsultingFloat .Content .Label .Checkmark {
			position: absolute;
			top: 0.1vw;
			left: 0;
			width: 3.1vw;
			height: 3vw;
			background-color: #fff;
			border: 2px solid #575757;
		}
		.ConsultingFloat .Content .Label .Checkmark:after {
			position: absolute;
			top: 0.4vw;
			left: 0.4vw;
			width: 2.3vw;
			height: 2.2vw;
			background-color: #575757;
			content: "";
			display: none;
		}
		.ConsultingFloat .Content .Label input:checked ~ .Checkmark:after {
			display: block;
		}
		.ConsultingFloat .Content button {
			width: 100%;
			height: 15vw;
			background-color: #0e1c2c;
			border-radius: 8px;
			font-family: 'S-CoreDream-9Black';
			color: #fff;
			font-size: 7vw;
			line-height: 15vw;
			letter-spacing: 2px;
			margin-top: 4vw;
		}
		.ConsultingFloat .Content button:hover {
			background-color: #162c45;
		}

		.LayerWrap {
			z-index: 999;
			display: block;
			position: fixed;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			text-align: center;
			background-color: rgba(0, 0, 0, 0.5);
			visibility: hidden;
			opacity: 0;
			-webkit-transition: opacity 0.5s ease, visibility 0.5s ease;
			transition: opacity 0.5s ease, visibility 0.5s ease;
		}
		.LayerWrap.on {
			visibility: visible;
			opacity: 1;
			-webkit-transition: opacity 0.5s ease, visibility 0.5s ease;
			transition: opacity 0.5s ease, visibility 0.5s ease;
		}
		.LayerWrap:before {
			content: "";
			display: inline-block;
			height: 100%;
			vertical-align: middle;
		}
		.PopLayer .BtnClose {
			background-image: url("../images/franchise/m/btn-close.png");
			background-repeat: no-repeat;
			background-size: 50%;
			background-position: center;
			position: absolute;
			width: 9vw;
			height: 9vw;
			top: 2vw;
			right: 2vw;
		}
		.PopLayer {
			display: inline-block;
			vertical-align: middle;
			background-color:#fff;
			border: 4px solid #001f32;
			z-index: 10;
			position: relative;
		}
		.PopLayer .PopContainer {
			position: absolute;
			top: 38%;
			transform: translateY(-38%);
			left: 6vw;
			right: 6vw;
			text-align: center;
			color: #303030;
			word-break: keep-all;
			letter-spacing: -1px;
		}

		#Layer .PopLayer {
			width: 91vw;
			height: 90vw;
		}
		#Layer .PopLayer .PopContainer {
			font-size: 4vw;
			line-height: 5.5vw;
		}
		#Layer .PopLayer .PopContainer p.Title {
			font-size: 5vw;
			font-weight: 700;
		}
		#Layer .PopLayer .PopContainer p.Sub {
			margin: 3vw 0;
		}
		#Layer .PopLayer .PopContainer p.Desc span {
			font-weight: 700;
		}

		#Dialog .PopLayer {
			position: relative;
			width: 91vw;
			min-height: 50vw;
		}
		#Dialog .PopLayer .PopContainer {
			left: 0;
			right: 0;
			font-size: 6vw;
			line-height: 7vw;
		}
		#Dialog .PopLayer .PopContainer p.Msg {
			font-weight: 700;
		}
		#Dialog .PopLayer .BtnGroup {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 5vw;
		}
		#Dialog .PopLayer .BtnGroup button {
			width: 25vw;
			height: 10vw;
			color: #fff;
			font-size: 4vw;
			font-weight: 600;
			margin: 0 1vw;
		}
		#Dialog .PopLayer .BtnGroup button.Ok {
			background-color: #001f32;
		}
		#Dialog .PopLayer .BtnGroup button.Cancel {
			background-color: #333;
			display: none;
		}
		#Dialog .PopLayer .BtnGroup button {
			filter: invert(10%);
		}
		#Dialog .PopLayer .BtnGroup button.Cancel.Confirm {
			display: inline-block;
		}
	}
}
