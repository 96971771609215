@charset "utf-8";

html {
	// background-color: #E9481E;
}

#ChecklistComponent {

	.ChecklistEnterWrap {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100vw;
		height: 100vh;
	}

	.ChecklistEnterWrap select {
		width: 70vw;
		height: 50px;
		font-size: 17px;
		border: 1px solid #000;
		color: #555;
		font-weight: 600;
		text-align: center;
		text-align-last: center;
		text-overflow: ellipsis;
		outline: none;
		-webkit-appearance: none;
		appearance: none;
		background-color: rgba(0,0,0,0);
		background-image: url('../images/landing/arrow-down.png');
		background-repeat: no-repeat;
		background-position: right 10px center;
		background-size: 20px;
	}

	.ChecklistEnterWrap button {
		width: 70vw;
		height: 50px;
		margin-top: 10px;
		background-color: #E9481E;
		color: #fff;
		font-weight: 700;
		font-size: 20px;
	}

	.ChecklistHeader {
		padding: 0 20px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		height: 150px;
		background-color: #fff;
		border-bottom: 1px solid #000;
	}

	.ChecklistHeader p.Title {
		font-size: 18px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		font-weight: 700;
		width: 100%;
		border-bottom: 1px solid #000;
		text-align: center;
		padding-bottom: 5px;
	}

	.ChecklistHeader select {
		font-size: 17px;
		color: #555;
		font-weight: 600;
		border-top: none;
		border-left: none;
		border-right: none;
		border-bottom: 1px solid #000;
		padding: 5px;
		width: 100%;
		text-align: center;
		text-align-last: center;
		text-overflow: ellipsis;
		margin-top: 10px;
		background-color: rgba(0,0,0,0);
		background-image: url('../images/landing/arrow-down.png');
		background-repeat: no-repeat;
		background-position: right 10px center;
		background-size: 20px;
		-webkit-appearance: none;
		appearance: none;
	}
	.ChecklistHeader select:focus {
		outline: none;
	}

	.ChecklistHeader div.Info {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
		margin-top: 10px;
	}

	.ChecklistHeader div.Info p {
		font-size: 15px;
		font-weight: 500;
	}

	.ChecklistWrap {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
		height: 100%;
		margin: 150px 0 50px 0;
	}

	.ChecklistCategory {
		border-bottom: 2px solid #E9481E;
		width: 100vw;
	}

	.ChecklistCategory > .Title {
		width: 100%;
		background-color: #FF5858;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.ChecklistCategory > .Title p {
		font-size: 5.5vw;
		font-weight: 600;
		color: #fff;
		padding: 2vw 0;
	}

	.ChecklistRow {
		width: 100vw;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		border-bottom: 1px solid #000;
	}

	.ChecklistRow > .Title {
		width: 100%;
		min-height: 50px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		background-color: #fff;
	}

	.ChecklistRow > .Title p {
		font-weight: 600;
		font-weight: 700;
		font-size: 5vw;
		line-height: 5.5vw;
		background-color: #fff;
		text-align: center;
		word-break: keep-all;
		padding: 10px;
	}

	.ChecklistRow .CheckWrap {
		width: 100%;
		height: 40px;
		font-weight: 500;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		border-top: 1px solid #ccc;
	}

	.ChecklistRow .CheckWrap {
		input[type=radio] {
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			display: none;
		}
	
		input[type=radio] + label {
			background-color: #eee;
			color: #000;
			font-weight: 600;
		}
	
		input[type=radio]:checked + label {
			background-color: #E9481E;
			color: #fff;
			font-weight: 700;
		}

		label {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 42%;
			height: 100%;
			font-size: 4vw;
			border-right: 1px solid #ccc;
		}

		button.FileUpload {
			width: 16%;
			height: 100%;
			font-size: 4vw;
			background-color: #256D85;
			color: #fff;
			text-align: center;
		}
		button.FileUpload.Exist {
			background-color: #5C2E7E;
		}

		input[type=file] {
			display: none;
		}
	}

	div.ButtonGroupBottom {
		width: 100vw;
		height: 50px;
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		border-top: 1px solid #000;
	}

	button.SubmitChecklist {
		width: 80%;
		height: 100%;
		background-color: #E9481E;
		font-size: 18px;
		font-weight: 600;
		color: #fff;
	}

	button.CancelChecklist {
		width: 20%;
		height: 100%;
		background-color: #000;
		font-size: 18px;
		font-weight: 600;
		color: #fff;
	}
}