@charset "utf-8";

#Popup {

	.Hide {
		opacity: 0;
	}

	.Show {
		opacity: 1;
	}

	@media only screen and (min-width: 1024px) {
		.MOBILE { display: none !important; }

		.ImageWrap {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 10%;
			height: 735px;
			z-index: 9998;
			display: flex;
			flex-direction: column;
		}

		.Image {
			height: 700px;
			display: flex;
			flex-direction: row;
		}
		.Image img {
			// width: 402px;
			height: 700px;
		}
		.Bottom {
			width: 100%;
			height: 35px;
			background-color: #eee;
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
		}
		.Bottom button {
			font-size: 16px;
			background-color: 'red';
			font-weight: 500;
			margin: 0 20px;
		}
	}
	
	@media only screen and (max-width : 1023px) {
		.PC { display: none !important; }

		.Bg {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(0, 0, 0, .8); 
			z-index: 9997;
		}

		.ImageWrap {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 82vw;
			max-width: 593px;
			height: 112vw;
			max-height: 737px;
			z-index: 9998;
			display: flex;
			flex-direction: column;
			background-color: #eee;
		}

		img {
			width: 100%;
			height: 100%;
		}
		
		.PopupSlide .slick-list {
			height: 100%;
		}
		.PopupSlide .slick-arrow {
			display: none !important;
		}

		.Bottom {
			width: 100%;
			height: 10%;
			background-color: #eee;
			display: flex;
			flex-direction: row;
			justify-content: space-around;
		}
		.Bottom button {
			font-size: 4vw;
			background-color: 'red';
			font-weight: 500;
		}

		ul.NavContainer {
			position: absolute;
			left: 1vw;
			right: 0;
			top: -8vw;
			z-index: 3;
		}
		ul.NavContainer li {
			float: left;
			margin-right: 2vw;
		}
		ul.NavContainer li button {
			width: 7vw;
			height: 7vw;
			border-radius: 50%;
			border: none;
			background: rgb(255,51,51);
			opacity: 0.4;
			-webkit-transition: opacity 0.5s ease;
			transition: opacity 0.5s ease;
		}
		ul.NavContainer li button.active {
			width: 7vw;
			height: 7vw;
			border-radius: 50%;
			border: none;
			background: rgb(255,51,51);
			opacity: 1;
			-webkit-transition: opacity 0.5s ease;
			transition: opacity 0.5s ease;
		}
	}
}
