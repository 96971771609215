@charset "utf-8";
@font-face {
	font-family: 'S-CoreDream-9Black';
	src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-9Black.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@media only screen and (min-width: 1024px) {
	.Footer.Mobile {
		display: none;
	}
	#Footer button {
		background-repeat: no-repeat;
		background-size: contain;
	}
	#Footer button.Instagram {
		background-image: url("../images/brand/icon-instagram.png");
		width: 30px;
		height: 30px;
	}
	#Footer button.Youtube {
		background-image: url("../images/brand/icon-youtube.png");
		width: 30px;
		height: 30px;
	}
	#Footer button.Naver {
		background-image: url("../images/brand/icon-naver.png");
		width: 30px;
		height: 30px;
	}
	#Footer button.Facebook {
		background-image: url("../images/brand/icon-facebook.png");
		width: 16px;
		height: 27px;
	}
	#Footer button.Blog {
		background-image: url("../images/brand/icon-blog.png");
		width: 20px;
		height: 25px;
	}
	#Footer a {
		text-decoration: none;
	}
	#Footer a:hover {
		font-weight: 700;
		text-decoration: underline;
		filter: invert(48%);
	}
}

@media only screen and (max-width : 1023px) {
	#Footer button {
		background-repeat: no-repeat;
		background-size: contain;
	}
	#Footer button.Instagram {
		background-image: url("../images/brand/icon-instagram.png");
		width: 30px;
		height: 30px;
	}
	#Footer button.Youtube {
		background-image: url("../images/brand/icon-youtube.png");
		width: 30px;
		height: 30px;
	}
	#Footer button.Naver {
		background-image: url("../images/brand/icon-naver.png");
		width: 30px;
		height: 30px;
	}
	#Footer button.Facebook {
		background-image: url("../images/brand/icon-facebook.png");
		width: 16px;
		height: 27px;
	}
	#Footer button.Blog {
		background-image: url("../images/brand/icon-blog.png");
		width: 20px;
		height: 25px;
	}
	#Footer a {
		text-decoration: none;
	}
	#Footer a:hover {
		font-weight: 700;
		text-decoration: underline;
		filter: invert(48%);
	}

	// #Footer .fs-3 {
	// 	font-size: 4vw !important;
	// }

	// #Footer .fs-4 {
	// 	font-size: 3vw !important;
	// }

	// #Footer .fs-5 {
	// 	font-size: 2vw !important;
	// }
}
