@charset "utf-8";

#BrandComponent {
	@media only screen and (min-width: 1024px) {
		.MOBILE { display: none !important; }

		.Partner {
			min-height: 1150px;
		}
		.Partner .SubContainer {
			overflow: hidden;
			max-width: 1000px;
			min-width: 1000px;
		}

		.Partner .TitleWrap {
			display: inline-block;
			width: 100%;
			margin: 150px 0 30px 0;
			position: relative;
		}
		.Partner .TitleWrap p.Title {
			font-size: 52px;
			font-weight: 700;
			line-height: 52px;
			color: #142436;
			letter-spacing: -2px;
		}
		.Partner .TitleWrap p.TitleSub {
			font-size: 24px;
			font-weight: 400;
			line-height: 24px;
			color: #142436;
			letter-spacing: -2px;
			margin-top: 20px;
		}
		.Partner .MainContent {
			width: 100%;
			height: 100%;
			padding-top: 60px;
			border-top: 2px solid #152435;
		}
		.Partner .MainContent .Request {
			width: 480px;
			height: 100%;
			float: left;
		}
		.Partner .MainContent .Request .Content {
			margin-top: 40px;
		}
		.Partner .MainContent p.MainTitle {
			font-size: 36px;
			line-height: 36px;
			font-weight: 600;
			color: #000;
			letter-spacing: -2px;
		}
		.Partner .MainContent p.MainTitle span {
			font-size: 16px;
			font-weight: 400;
			color: #333;
		}
		.Partner .MainContent .Request .Row:not(.Textarea) {
			width: 100%;
			height: 42px;
			display: flex;
			justify-content: space-between;
			margin-bottom: 5px;
		}
		.Partner .MainContent .Request .Row .Title {
			width: 200px;
			line-height: 42px;
			color: #333;
			font-size: 20px;
			font-weight: 400;
		}
		.Partner .MainContent .Request .Row .Title.Wide {
			width: 100%;
		}
		.Partner .MainContent .Request .Row .Input {
			width: 100%;
			height: 100%;
			background-color: rgba(241, 241, 241, 0.8);
			overflow: hidden;
			text-align: center;
		}
		.Partner .MainContent .Request .Row .Input input,
		.Partner .MainContent .Request .Row .Input select {
			border: 0;
			width: 90%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0);
			font-size: 16px;
			color: #000;
			outline: none;
		}
		.Partner .MainContent .Request .Row .Input select {
			width: 94%;
		}
		.Partner .MainContent .Request .Row .Input.File {
			display: flex;
			justify-content: space-between;
		}
		.Partner .MainContent .Request .Row .Input.File span {
			font-size: 16px;
			color: #000;
			line-height: 42px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			padding-left: 20px;
		}
		.Partner .MainContent .Request .Row .Input.File Label.InputFile {
			flex-basis: 80px;
			flex-shrink: 0;
			width: 80px;
			height: 100%;
			background-color: #999;
			text-align: center;
			font-size: 16px;
			color: #fff;
			line-height: 42px;
		}
		.Partner .MainContent .Request .Row .Input.File input#File {
			display: none;
		}
		.Partner .MainContent .Request .Row .Radio {
			color: #333;
			font-size: 20px;
			font-weight: 400;
			line-height: 42px;
		}
		.Partner .MainContent .Request .Row .Radio label:first-child {
			margin-right: 20px;
		}
		.Partner .Radio .Checkmark {
			position: absolute;
			top: 1px;
			left: 3px;
			width: 22px;
			height: 22px;
			border-radius: 50%;
			background-color: #fff;
			border: 1px solid #000;
		}
		.Partner .Radio .Checkmark:after {
			position: absolute;
			top: 4px;
			left: 4px;
			width: 12px;
			height: 12px;
			border-radius: 50%;
			background-color: #000;
			border: 1px solid #000;
		}
		.Partner .Radio .Label {
			position: relative;
			padding-left: 35px;
			cursor: pointer;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
		.Partner .Radio .Label input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
		}
		.Partner .Radio .Label .Checkmark:after {
			content: "";
			position: absolute;
			display: none;
		}
		.Partner .Radio .Label input:checked ~ .Checkmark:after {
			display: block;
		}
		.Partner .MainContent .Request .Row textarea {
			width: 424px;
			height: 190px;
			background-color: rgba(241, 241, 241, 0.8);
			font-size: 16px;
			color: #000;
			padding: 25px 28px;
			outline: none;
			border: none;
		}

		.Partner .MainContent .Privacy {
			width: 480px;
			height: 100%;
			float: right;
		}
		.Partner .MainContent .Privacy .Content {
			margin-top: 40px;

		}
		.Partner .MainContent .Privacy .Content .Row {
			width: 480px;
		}
		.Partner .MainContent .Privacy .Content .Row:nth-child(2) {
			margin-top: 20px;
		}
		.Partner .MainContent .Privacy .Content .Row p {
			font-size: 20px;
			color: #333;
			font-weight: 400;
			word-break: keep-all;
			line-height: 30px;
			padding-top: 4px;
			letter-spacing: -1px;
		}
		.Partner .MainContent .Privacy .Content .Row p span {
			font-weight: 600;
		}
		.Partner .Privacy .Checkmark {
			position: absolute;
			top: 1px;
			left: 3px;
			width: 22px;
			height: 22px;
			background-color: #fff;
			border: 1px solid #000;
		}
		.Partner .Privacy .Checkmark:after {
			position: absolute;
			top: 4px;
			left: 4px;
			width: 12px;
			height: 12px;
			background-color: #000;
			border: 1px solid #000;
		}
		.Partner .Privacy .Label {
			color: #333;
			font-size: 20px;
			font-weight: 400;
			position: relative;
			padding-left: 35px;
			cursor: pointer;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
		.Partner .Privacy .Label input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
		}
		.Partner .Privacy .Label .Checkmark:after {
			content: "";
			position: absolute;
			display: none;
		}
		.Partner .Privacy .Label input:checked ~ .Checkmark:after {
			display: block;
		}
		.Partner .Privacy .BtnArea {
			margin-top: 30px;
		}
		.Partner .Privacy .BtnArea button {
			width: 230px;
			height: 68px;
			text-align: center;
			color: #fff;
			font-size: 24px;
			font-weight: 400;
			transition: all 0.3s ease;
		}
		.Partner .Privacy .BtnArea button.Request {
			background-color: #222;
			float: left;
		}
		.Partner .Privacy .BtnArea button.Request:hover {
			background-color: #444;
		}
		.Partner .Privacy .BtnArea button.Cancel {
			background-color: #999;
			float: right;
		}
		.Partner .Privacy .BtnArea button.Cancel:hover {
			background-color: #7d7b7b;
		}

		.LayerWrap {
			z-index: 10;
			display: block;
			position: fixed;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			text-align: center;
			background-color: rgba(0, 0, 0, 0.5);
			visibility: hidden;
			opacity: 0;
			-webkit-transition: opacity 0.5s ease, visibility 0.5s ease;
			transition: opacity 0.5s ease, visibility 0.5s ease;
		}
		.LayerWrap.on {
			visibility: visible;
			opacity: 1;
			-webkit-transition: opacity 0.5s ease, visibility 0.5s ease;
			transition: opacity 0.5s ease, visibility 0.5s ease;
		}
		.LayerWrap:before {
			content: "";
			display: inline-block;
			height: 100%;
			vertical-align: middle;
		}
		.PopLayer .BtnClose {
			background-image: url("../images/franchise/m/btn-close.png");
			background-repeat: no-repeat;
			background-size: 50%;
			background-position: center;
			position: absolute;
			width: 50px;
			height: 50px;
			top: 10px;
			right: 10px;
		}
		.PopLayer {
			display: inline-block;
			vertical-align: middle;
			background-color:#fff;
			border: 4px solid #001f32;
			z-index: 10;
			position: relative;
		}
		.PopLayer .PopContainer {
			position: absolute;
			top: 38%;
			transform: translateY(-38%);
			left: 6vw;
			right: 6vw;
			text-align: center;
			color: #303030;
			word-break: keep-all;
			letter-spacing: -1px;
		}

		#Dialog .PopLayer {
			position: relative;
			width: 500px;
			min-height: 300px;
		}
		#Dialog .PopLayer .PopContainer {
			left: 0;
			right: 0;
			font-size: 30px;
			line-height: 40px;
		}
		#Dialog .PopLayer .PopContainer p.Msg {
			font-weight: 700;
		}
		#Dialog .PopLayer .BtnGroup {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 40px;
		}
		#Dialog .PopLayer .BtnGroup button {
			width: 150px;
			height: 50px;
			color: #fff;
			font-size: 25px;
			font-weight: 600;
			margin: 0 15px;
		}
		#Dialog .PopLayer .BtnGroup button.Ok {
			background-color: #001f32;
		}
		#Dialog .PopLayer .BtnGroup button.Cancel {
			background-color: #333;
			display: none;
		}
		#Dialog .PopLayer .BtnGroup button {
			filter: invert(10%);
		}
		#Dialog .PopLayer .BtnGroup button.Cancel.Confirm {
			display: inline-block;
		}
	}
	
	@media only screen and (max-width : 1023px) {
		.PC { display: none !important; }

		.Partner {
			height: 230vw;
		}
		.Partner .SubContainer {
			overflow: hidden;
			max-width: 90vw;
			min-width: 90vw;
		}

		.Partner .TitleWrap {
			display: inline-block;
			width: 100%;
			margin: 10vw 0 3vw 0;
			position: relative;
		}
		.Partner .TitleWrap p.Title {
			font-size: 6.5vw;
			font-weight: 700;
			line-height: 7.5vw;
			color: #142436;
			letter-spacing: -2px;
		}
		.Partner .TitleWrap p.TitleSub {
			font-size: 4.4vw;
			font-weight: 400;
			line-height: 4.4vwx;
			color: #142436;
			letter-spacing: -1px;
			margin-top: 2vw;
		}
		.Partner .TitleWrap p:last-child {
			font-size: 3.5vw;
			line-height: 6.5vw;
			color: #303030;
			font-weight: 400;
		}

		.Partner .MainContent {
			min-width: 100%;
			height: 100%;
			padding-top: 30px;
			border-top: 2px solid #152435;
		}
		.Partner .MainContent .Request {
			width: 100%;
		}
		.Partner .MainContent .Request .Row:not(.Textarea) {
			width: 100%;
			height: 9vw;
			margin-bottom: 2vw;
			display: flex;
			justify-content: space-between;
		}
		.Partner .MainContent .Request .Row .Title {
			width: 25%;
			line-height: 9vw;
			color: #333;
			font-size: 3.8vw;
			font-weight: 400;
			letter-spacing: -1px;
		}
		.Partner .MainContent .Request .Row .Title.Wide {
			width: 100%;
		}
		.Partner .MainContent .Request .Row .Input {
			width: 75%;
			height: 100%;
			background-color: rgba(241, 241, 241, 0.8);
			overflow: hidden;
			text-align: center;
		}
		.Partner .MainContent .Request .Row .Input input,
		.Partner .MainContent .Request .Row .Input select {
			border: 0;
			width: 90%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0);
			font-size: 3.8vw;
			color: #000;
			outline: none;
		}
		.Partner .MainContent .Request .Row .Input select {
			width: 94%;
		}
		.Partner .MainContent .Request .Row .Input.File {
			display: flex;
			justify-content: space-between;
		}
		.Partner .MainContent .Request .Row .Input.File span {
			font-size: 3.8vw;
			color: #000;
			line-height: 9vw;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			padding: 0 3.5vw;
		}
		.Partner .MainContent .Request .Row .Input.File Label.InputFile {
			flex-basis: 17vw;
			flex-shrink: 0;
			width: 17vw;
			height: 100%;
			background-color: #999;
			text-align: center;
			font-size: 3.8vw;
			color: #fff;
			line-height: 9vw;
		}
		.Partner .MainContent .Request .Row .Input input#File {
			display: none;
		}
		.Partner .MainContent .Request .Row .Radio {
			color: #333;
			font-size: 3.8vw;
			font-weight: 400;
			line-height: 9vw;
		}
		.Partner .MainContent .Request .Row .Radio label:first-child {
			margin-right: 4vw;
		}
		.Partner .Radio .Checkmark {
			position: absolute;
			top: 50%;
			left: 0;
			width: 4vw;
			height: 4vw;
			border-radius: 50%;
			transform: translateY(-50%);
			background-color: #fff;
			border: 1px solid #000;
		}
		.Partner .Radio .Checkmark:after {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 2vw;
			height: 2vw;
			border-radius: 50%;
			transform: translate(-50%, -50%);
			background-color: #000;
			border: 1px solid #000;
		}
		.Partner .Radio .Label {
			position: relative;
			padding-left: 6vw;
			cursor: pointer;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
		.Partner .Radio .Label input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
		}
		.Partner .Radio .Label .Checkmark:after {
			content: "";
			position: absolute;
			display: none;
		}
		.Partner .Radio .Label input:checked ~ .Checkmark:after {
			display: block;
		}
		.Partner .MainContent .Request .Row textarea {
			width: 93%;
			height: 35vw;
			background-color: rgba(241, 241, 241, 0.8);
			font-size: 3.8vw;
			color: #000;
			padding: 2vw 3vw;
			outline: none;
			border: none;
		}

		.Partner .Request .Checkbox {
			margin-top: 5vw !important;
		}
		.Partner .Request .Checkbox .Label {
			position: relative;
		}
		.Partner .Request .Checkbox .Checkmark {
			position: absolute;
			top: 50%;
			left: 0;
			width: 4vw;
			height: 4vw;
			transform: translateY(-50%);
			background-color: #fff;
			border: 1px solid #000;
		}
		.Partner .Request .Checkbox .Checkmark:after {
			position: absolute;
			top: 0.8vw;
			left: 0.8vw;
			width: 2.4vw;
			height: 2.4vw;
			background-color: #000;
		}
		.Partner .Request .Checkbox .Label {
			color: #333;
			font-size: 3.8vw;
			line-height: 3.8vw;
			font-weight: 400;
			position: relative;
			padding-left: 7vw;
			margin: auto 0;
			cursor: pointer;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
		.Partner .Request .Checkbox .Label input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
		}
		.Partner .Request .Checkbox .Label .Checkmark:after {
			content: "";
			position: absolute;
			display: none;
		}
		.Partner .Request .Checkbox .Label input:checked ~ .Checkmark:after {
			display: block;
		}
		.Partner .Request .Checkbox .PrivacyLink {
			font-size: 3.8vw;
			font-weight: 700;
			text-decoration: underline;
			margin-left: 3vw;
			color: #303030;
			cursor: pointer;
		}
		.Partner .Request .BtnArea {
			position: relative;
			margin-top: 5vw;
		}
		.Partner .Request .BtnArea button {
			display: inline-block;
			width: 46vw;
			height: 11vw;
			color: #fff;
			font-size: 4.5vw;
			font-weight: 600;
			transition: all 0.3s ease;
			position: absolute;
			top: 4vw;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
		}
		.Partner .Request .BtnArea button.Request {
			background-color: #222;
		}
		.Partner .Request .BtnArea button.Request:hover {
			background-color: #444;
		}

		.LayerWrap {
			z-index: 10;
			display: block;
			position: fixed;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			text-align: center;
			background-color: rgba(0, 0, 0, 0.5);
			visibility: hidden;
			opacity: 0;
			-webkit-transition: opacity 0.5s ease, visibility 0.5s ease;
			transition: opacity 0.5s ease, visibility 0.5s ease;
		}
		.LayerWrap.on {
			visibility: visible;
			opacity: 1;
			-webkit-transition: opacity 0.5s ease, visibility 0.5s ease;
			transition: opacity 0.5s ease, visibility 0.5s ease;
		}
		.LayerWrap:before {
			content: "";
			display: inline-block;
			height: 100%;
			vertical-align: middle;
		}
		.PopLayer .BtnClose {
			background-image: url("../images/franchise/m/btn-close.png");
			background-repeat: no-repeat;
			background-size: 50%;
			background-position: center;
			position: absolute;
			width: 9vw;
			height: 9vw;
			top: 2vw;
			right: 2vw;
		}
		.PopLayer {
			display: inline-block;
			vertical-align: middle;
			background-color:#fff;
			border: 4px solid #001f32;
			z-index: 10;
			position: relative;
		}
		.PopLayer .PopContainer {
			position: absolute;
			top: 38%;
			transform: translateY(-38%);
			left: 6vw;
			right: 6vw;
			text-align: center;
			color: #303030;
			word-break: keep-all;
			letter-spacing: -1px;
		}

		#Layer .PopLayer {
			width: 91vw;
			height: 90vw;
		}
		#Layer .PopLayer .PopContainer {
			font-size: 4vw;
			line-height: 5.5vw;
		}
		#Layer .PopLayer .PopContainer p.Title {
			font-size: 5vw;
			font-weight: 700;
		}
		#Layer .PopLayer .PopContainer p.Sub {
			margin: 3vw 0;
		}
		#Layer .PopLayer .PopContainer p.Desc span {
			font-weight: 700;
		}

		#Dialog .PopLayer {
			position: relative;
			width: 91vw;
			min-height: 50vw;
		}
		#Dialog .PopLayer .PopContainer {
			left: 0;
			right: 0;
			font-size: 6vw;
			line-height: 7vw;
		}
		#Dialog .PopLayer .PopContainer p.Msg {
			font-weight: 700;
		}
		#Dialog .PopLayer .BtnGroup {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 5vw;
		}
		#Dialog .PopLayer .BtnGroup button {
			width: 25vw;
			height: 10vw;
			color: #fff;
			font-size: 4vw;
			font-weight: 600;
			margin: 0 1vw;
		}
		#Dialog .PopLayer .BtnGroup button.Ok {
			background-color: #001f32;
		}
		#Dialog .PopLayer .BtnGroup button.Cancel {
			background-color: #333;
			display: none;
		}
		#Dialog .PopLayer .BtnGroup button {
			filter: invert(10%);
		}
		#Dialog .PopLayer .BtnGroup button.Cancel.Confirm {
			display: inline-block;
		}
	}
}
