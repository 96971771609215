@charset "utf-8";

#BrandComponent {
	@media only screen and (min-width: 1024px) {
		body { overflow-y: hidden; }
	
		.MOBILE { display: none !important; }

		.Anchor {
			// scroll-snap-align: start;
			// scroll-snap-stop: always;
			display: block;
			visibility: hidden;
			height: 96px;
			margin-top: -96px;
		}
	
		.Container {
			overflow-y: auto;
			// scroll-snap-type: y mandatory;
			width: 100%;
			height: 100vh;
			scroll-behavior: smooth;
			-webkit-overflow-scrolling: touch;
		}
	
		.SubContainer {
			min-width: 1320px;
			max-width: 1320px;
			height: 100%;
			overflow-y: hidden;
			margin: 0 auto;
			position: relative;
		}
	
		.Header {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 95px;
			background-color: #fff;
			border-bottom: 1px solid #eeeeee;
			z-index: 9;
		}
		.Header .HeaderArea {
			min-width: 1320px;
			max-width: 1320px;
			height: 100%;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		.Header .HeaderArea .Logo {
			width: 300px;
		}
		.Header .HeaderArea .MenuWrap {
			height: 100%;
			float: right;
			display: table;
		}
		.Header .HeaderArea .MenuWrap ul {
			display: table-cell;
			vertical-align: middle;
		}
		.Header .HeaderArea .MenuWrap li {
			display: inline-block;
			font-size: 0;
			cursor: pointer;
		}
		.Header .HeaderArea .MenuWrap li a {
			display: inline-block;
			text-align: center;	
			font: normal 18px 'Noto Sans KR';
			text-transform: uppercase;
			font-weight: 600;
		}
		.Header .HeaderArea .MenuWrap li:not(:last-child) a {
			color: #142436;
			font-weight: 600;
			-webkit-transition: color 0.5s ease;
			transition: color 0.5s ease;
		}
		.Header .HeaderArea .MenuWrap li a::before {
			display: block;
			content: attr(title);
			font-weight: 700;
			height: 0;
			overflow: hidden;
			visibility: hidden;
		}
		.Header .HeaderArea .MenuWrap li:not(:last-child) {
			margin-right: 4.35vw;
		}
		.Header .HeaderArea .MenuWrap li:not(:last-child):hover a, .Header .HeaderArea .MenuWrap li:not(:last-child).select a {
			color: #f5453d;
			font-weight: 700;
			-webkit-transition: color 0.5s ease;
			transition: color 0.5s ease;
		}
		.Header .HeaderArea .MenuWrap li:last-child a {
			color: #fff;
		}
		.Header .HeaderArea .MenuWrap li:last-child a span {
			background-color: #f5453d;
			width: 87px;
			height: 32px;
			text-align: center;
			padding: 7px 10px;
			font-weight: 600;
			-webkit-transition: padding 0.3s ease;
			transition: padding 0.3s ease;
			-webkit-transition: background-color 0.3s linear;
			transition: background-color 0.3s linear;
		}
		.Header .HeaderArea .MenuWrap li:last-child:hover a span {
			background-color: #142436;
			-webkit-transition: background-color 0.3s linear;
			transition: background-color 0.3s linear;
		}
	
		#Home {
			height: 192px !important;
		}
		.Home {
			position: relative;
		}
		.Home .slick-slide img {
			display: inherit !important;
			width: 100%;
		}
		.Home .ArrowLeft {
			left: 20px;
		}
		.Home .ArrowRight {
			right: 20px;
		}
		.Home .ArrowLeft,
		.Home .ArrowRight {
			position: absolute;
			top: 50%;
			transform: translate(0, -50%);
			width: 60px;
			height: 60px;
			background: rgba(255, 255, 255, 0.15);
			border-radius: 100%;
			text-align: center;
		}

		.Story {
			position: relative;
			padding: 100px 0 130px 0;
		}
		.Story .Wrap {
			display: flex;
			flex-direction: column;
		}
		.Story .Wrap .TitleWrap p {
			font-size: 56px;
			color: #F6453D;
			font-weight: 700;
			text-align: left;
		}
		.Story .Wrap .TitleWrap p span {
			color: #f5453d;
		}
		.Story .Wrap .TitleWrap:last-child {
			margin-top: 50px;
		}
		.Story .Wrap .TitleWrap:last-child div.Logo {
			width: 217px;
			height: 67px;
			background-image: url('../images/brand/img-logo-story.png');
			background-repeat: no-repeat;
			background-size: auto;
		}
		.Story .Wrap .TitleWrap:last-child p:last-child {
			font-weight: 700;
			color: #142435;
			font-size: 32px;
			margin-top: 30px;
		}
		.Story .Wrap .Content {
			display: flex;
			flex-direction: column;
		}
		.Story .Wrap .Content .ContentWrap {
			width: 100%;
			margin-top: 50px;
			display: flex;
			flex-direction: row;
		}
		.Story .Wrap .Content .ContentWrap .ImgWrap {
			flex: 4;
			margin-right: 30px;
			position: relative;
		}
		.Story .Wrap .Content .ContentWrap .ImgWrap img {
			width: 100%;
		}
		.Story .Wrap .Content .ContentWrap .ImgWrap .ImgBg {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			background-color: rgb(0, 0, 0);
			opacity: 0.4;
			transition: opacity 0.5s;
		}
		.Story .Wrap .Content .ContentWrap .ImgWrap .ImgBg:hover {
			opacity: 0;
		}
		.Story .Wrap .Content .ContentWrap .DescWrap {
			flex: 6;
		}
		.Story .Wrap .Content .ContentWrap .DescWrap p.Title {
			color: #142435;
			font-size: 35px;
			line-height: 40px;
			font-weight: 700;
			letter-spacing: -1px;
			text-align: left;
			word-break: keep-all;
		}
		.Story .Wrap .Content .ContentWrap .DescWrap p.Title span {
			color: #f6453d;
		}
		.Story .Wrap .Content .ContentWrap .DescWrap p.Desc {
			margin-top: 30px;
			color: #142435;
			font-size: 22px;
			line-height: 30px;
			font-weight: 500;
			word-break: break-word;
			text-align: justify;
		}

		.Brand {
			overflow: hidden;
			position: relative;
			// height: 620px;
		}
		.Brand .Slide {
			z-index: 2;
		}
		.Brand .SubContainer {
			min-width: 100%;
			max-width: 100%;
		}
		.Brand .MainContent {
			max-width: 1600px;
			position: relative;
		}
		.Brand ul.NavContainer {
			position: absolute;
			right: 0;
			top: 42px;
			z-index: 3;
		}
		.Brand ul.NavContainer li {
			float: left;
			margin-right: 26px;
		}
		.Brand ul.NavContainer li:last-child {
			margin-right: 12px;
		}
		.Brand ul.NavContainer li button {
			width: 23px;
			height: 23px;
			border-radius: 23px;
			border: none;
			background: rgb(255,51,51);
			opacity: 0.3;
			-webkit-transition: opacity 0.5s ease;
			transition: opacity 0.5s ease;
		}
		.Brand ul.NavContainer li button.active {
			width: 23px;
			height: 23px;
			border-radius: 23px;
			border: none;
			background: rgb(255,51,51);
			opacity: 1;
			-webkit-transition: opacity 0.5s ease;
			transition: opacity 0.5s ease;
		}
		.Brand .MainContent .BrandImg {
			max-width: 1600px;
		}
		
	
		.Menu {
			background-color: #142435;
			background-image: url("../images/brand/bg-menu.png");
			background-repeat: no-repeat;
			background-size: cover;
			height: 918px;
			position: relative;
		}
		.Menu ul.NavContainer {
			position: absolute;
			left: 0;
			top: 258px;
			z-index: 3;
		}
		.Menu ul.NavContainer li {
			float: left;
			margin-right: 26px;
		}
		.Menu ul.NavContainer li button {
			width: 23px;
			height: 23px;
			border-radius: 23px;
			border: none;
			background: rgb(255,51,51);
			opacity: 0.3;
			-webkit-transition: opacity 0.5s ease;
			transition: opacity 0.5s ease;
		}
		.Menu ul.NavContainer li button.active {
			width: 23px;
			height: 23px;
			border-radius: 23px;
			border: none;
			background: rgb(255,51,51);
			opacity: 1;
			-webkit-transition: opacity 0.5s ease;
			transition: opacity 0.5s ease;
		}
		.Menu .Slide {
			z-index: 2;
			width: 50%;
		}
		.Menu .Slide .slick-list {
			height: 918px;
		}
		.Menu .Slide .PageContainer {
			position: relative;
			width: 100%;
			height: 100%;
		}
		.Menu .Slide .PageContainer .PageWrap {
			position: absolute;
			left: 0;
			top: 300px;
			width: 580px;
			height: 100%;
		}
		.Menu .Slide .PageContainer .PageWrap p.Title {
			font-size: 46px;
			color: #fff;
			font-weight: 700;
			margin-bottom: 25px;
		}
		.Menu .Slide .PageContainer .PageWrap p.TitleSub {
			font-size: 24px;
			color: #fff;
			font-weight: 700;
			margin: 30px 0 20px 0;
		}
		.Menu .Slide .PageContainer .PageWrap p.Desc {
			font-size: 18px;
			color: #fff;
			font-weight: 500;
			line-height: 30px;
			letter-spacing: -0.5px;
			word-break: keep-all;
		}
		.Menu .Slide .PageContainer .PageWrap .MenuWrap {
			display: block;
		}
		.Menu .Slide .PageContainer .PageWrap .MenuWrap div {
			float: left;
		}
		.Menu .Slide .PageContainer .PageWrap .MenuWrap div:first-child {
			width: 270px;
		}
		.Menu .Slide .PageContainer .PageWrap p.MenuTitle {
			font-size: 18px;
			color: #fff;
			font-weight: 500;
			line-height: 30px;
			letter-spacing: -0.5px;
			word-break: keep-all;
		}
		.Menu .Slide .PageContainer .PageWrap p.Caution {
			display: inline-block;
			font-size: 13px;
			color: #7e93a9;
			font-weight: 400;
			margin-top: 20px;
			width: 100%;
		}
		.Menu .Slide .PageContainer .PageWrap button.More {
			font-size: 18px;
			color: #999;
			font-weight: 400;
			background-color: rgba(0,0,0,0);
			border: 1px solid #999;
			width: 120px;
			margin-top: 23px;
			padding: 2px 0 5px 0;
		}
		.Menu .Slide .PageContainer .PageWrap button.More:hover {
			background-color: rgba(153,153,153,1);
			color: #fff;
		}
		.Menu .Right {
			width: 660px;
			height: 675px;
			background-color: #fff;
			position: absolute;
			top: 110px;
			right: 0;
			overflow: hidden;
		}
		.Menu .Right .Wrap {
			position: relative;
			margin: 40px 0 0 80px;
		}
		.Menu .Right .Wrap ul {
			display: inline-block;
		}
		.Menu .Right .Wrap ul.on {
			opacity: 1;
			-webkit-transition: opacity 0.5s ease;
			transition: opacity 0.5s ease;
		}
		.Menu .Right .Wrap ul.off {
			opacity: 0;
			-webkit-transition: opacity 0.5s ease;
			transition: opacity 0.5s ease;
		}
		.Menu .Right .Wrap ul li {
			display: inline-block;
			cursor: pointer;
			position: relative;
			padding: 0 18px;
			border-bottom: 0;
			transition: border-bottom 0.5 ease;
		}
		.Menu .Right .Wrap ul li button {
			display: inline-block;
			text-align: center;
			font: normal 18px 'Noto Sans KR';
			text-transform: uppercase;
			font-weight: 400;
			color: hsl(211, 45%, 14%);
			line-height: 8px;
			padding: 0 3px;
			border-bottom: 5px solid rgba(250, 162, 158, 0);
			transition: all 0.5s ease;
		}
		.Menu .Right .Wrap ul li button::before {
			display: block;
			content: attr(title);
			height: 0;
			overflow: hidden;
			visibility: hidden;
		}
		.Menu .Right .Wrap ul li:first-child {
			padding-left: 0;
		}
		.Menu .Right .Wrap ul li.active button,
		.Menu .Right .Wrap ul li:hover button {
			font-weight: 600;
			border-bottom: 5px solid rgba(250, 162, 158, 1);
		}
		.Menu .Right .Wrap ul li:first-child:after {
			display: none;
		}
		.Menu .Right .Wrap ul li:after {
			content: '';
			display: inline-block;
			width: 1px;
			height: 16px;
			background: #ccc;
			position: absolute;
			top: 50%;
			left: 0;
			margin-top: -10px;
		}
		.Menu .Right .Wrap .ElementWrap {
			width: 100%;
			height: 100%;
			position: relative;
			background-color: red;
		}
		.Menu .Right .Wrap .Element {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}
		.Menu .Right .Wrap .Element.on {
			opacity: 1;
			-webkit-transition: opacity 0.5s ease;
			transition: opacity 0.5s ease;
		}
		.Menu .Right .Wrap .Element.off {
			opacity: 0;
			-webkit-transition: opacity 0.5s ease;
			transition: opacity 0.5s ease;
		}
		.Menu .Right .Wrap .Element p {
			margin: 5px 0;
		}
		.Menu .Right .Wrap .Element .Title {
			color: #f5453d;
			font-size: 18px;
			font-weight: 600;
		}
		.Menu .Right .Wrap .Element .Content {
			color: #142435;
			font-size: 18px;
			font-weight: 600;
		}
		.Menu .Right .Wrap .Element .Sub {
			color: #142435;
			font-size: 16px;
			font-weight: 400;
		}
		.Menu .Right .Wrap .Element .Desc {
			font-size: 18px;
			color: #555;
			max-width: 430px;
			font-weight: 400;
			line-height: 29px;
			margin-top: 20px;
			word-break: keep-all;
		}
		.Menu .Right .Wrap .Element:nth-child(1) img {
			margin: 26px 0 0 70px !important;
		}
		.Menu .Right .Wrap .Element:nth-child(1) p.FirstRow {
			margin-top: 20px !important;
		}
		.Menu .Right .Wrap .Element:nth-child(2) img {
			margin: 15px 0 0 120px !important;
		}
		.Menu .Right .Wrap .Element:nth-child(2) p.FirstRow {
			margin-top: 39px !important;
		}
		.Menu .Right .Wrap .Element:nth-child(3) img {
			margin: 70px 0 0 130px !important;
		}
		.Menu .Right .Wrap .Element:nth-child(3) p.FirstRow {
			margin-top: 50px !important;
		}
		.Menu .Right .Wrap .Element:nth-child(4) img {
			margin: 47px 0 0 120px !important;
		}
		.Menu .Right .Wrap .Element:nth-child(4) p.FirstRow {
			margin-top: 40px !important;
		}
		.Menu .Right .Wrap .Element:nth-child(5) img {
			margin: 40px 0 0 80px !important;
		}
		.Menu .Right .Wrap .Element:nth-child(5) p.FirstRow {
			margin-top: 45px !important;
		}
		.Menu .Right .Wrap .Element:nth-child(6) img {
			margin: 40px 0 0 80px !important;
		}
		.Menu .Right .Wrap .Element:nth-child(6) p.FirstRow {
			margin-top: 45px !important;
		}
		.Menu .Right .Wrap .Element:nth-child(7) img {
			margin: 40px 0 0 80px !important;
		}
		.Menu .Right .Wrap .Element:nth-child(7) p.FirstRow {
			margin-top: 45px !important;
		}
	
		.Store .SubContainer {
			display: flex;
			flex-direction: row;
			margin-top: 100px;
			margin-bottom: 100px;
		}
		.Store img {
			width: 50%;
		}
		.Store .SubContainer .MainContent {
			width: 50%;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
		.Store .SubContainer .MainContent .Content {
			margin-left: 80px;
		}
		.Store .SubContainer .MainContent .Content p.Title {
			font-size: 25px;
			color: #142435;
			line-height: 40px;
			letter-spacing: -1px;
			font-weight: 500;
		}
		.Store .SubContainer .MainContent .Content p.Title:first-child {
			line-height: 23px;
		}
		.Store .SubContainer .MainContent .Content p.Title.Bold {
			font-weight: 700;
		}
		.Store .SubContainer .MainContent .Content p.TitleBottom {
			font-size: 56px;
			color: #f5453d;
			line-height: 56px;
			letter-spacing: -2px;
			font-weight: 700;
			margin-bottom: 30px;
		}
		.Store .SubContainer .MainContent .Content a {
			font-size: 18px;
			line-height: 40px;
			font-weight: 600;
			color: #fff;
			text-align: center;
			position: relative;
			margin-top: 65px;
			display: inline-block;
			width: 130px;
			height: 40px;
			background-color: #f5453d;
			border-radius: 30px;
		}
		.Store .SubContainer .MainContent .Content a:hover {
			background-color: #142435;
		}
		.Store .SubContainer .Desc {
			display: none;
		}
		.Store .SubContainer .MainContent .Content .Desc {
			display: block;
			font-size: 24px;
			color: #142435;
			font-weight: 600;
			letter-spacing: -1px;
			margin-top: 100px;
		}
	
		.With {
			background-color: #fcf8ef;
			height: 442px;
		}
		.With .MainContent {
			height: 100%;
		}
		.With .Wrap {
			width: 100%;
			height: 100%;
			display: table;
		}
		.With .Wrap .Left {
			width: 580px;
			height: 170px;
			display: table-cell;
			vertical-align: middle;
		}
		.With .Wrap .Left p.Title {
			font-size: 56px;
			color: #f5453d;
			font-weight: 700;
			line-height: 56px;
		}
		.With .Wrap .Left p.Desc1 {
			font-size: 24px;
			color: #333333;
			font-weight: 400;
			line-height: 24px;
			letter-spacing: 0px;
			margin-top: 12px;
		}
		.With .Wrap .Left p.Desc2 {
			font-size: 30px;
			color: #142435;
			font-weight: 700;
			line-height: 30px;
			margin-top: 20px;
		}
		.With .Wrap .Left p.Desc3 {
			font-size: 18px;
			color: #142435;
			font-weight: 400;
			line-height: 18px;
			margin-top: 10px;
		}
		.With .Wrap .Right {
			text-align: center;
			display: table-cell;
			vertical-align: middle;
		}
		.With .Wrap .Right button {
			display: inline-block;
			background-color: #142435;
			width: 200px;
			height: 200px;
			border-radius: 200px;
			transition: background-color 0.5s ease;
		}
		.With .Wrap .Right button:hover {
			background-color: #f5453d;
		}
		.With .Wrap .Right button p {
			font-size: 24px;
			color: #fff;
			font-weight: 600;
			margin-top: 10px;
		}
		.With .Wrap .Right button.Franchise {
			float: left;
		}
		.With .Wrap .Right button.Consulting {
			float: right;
		}
		.News {
			background-color: #fff;
			height: 560px;
		}
		.News .SubContainer {
			overflow: hidden;
		}
		.News .MainContent {
			width: 100%;
			height: 100%;
			display: table;
		}
		.News .MainContent .Left {
			height: 260px;
			display: table-cell;
			vertical-align: middle;
		}
		.News .MainContent .Left .TitleWrap {
			position: absolute;
			top: 50%;
			left: 55px;
			transform: translate(0, -50%);
		}
		.News .TitleWrap p:first-child {
			font-size: 35px;
			font-weight: 300;
			line-height: 35px;
			color: #fff;
		}
		.News .TitleWrap p:last-child {
			margin-top: 10px;
			font-size: 48px;
			font-weight: 700;
			line-height: 48px;
			color: #fff;
		}
	
		.News .MainContent .Right {
			height: 260px;
			display: table-cell;
			vertical-align: middle;
		}
		.News .MainContent .Right p.Title {
			font-size: 56px;
			font-weight: 700;
			line-height: 56px;
		}
		.News .MainContent .Right p.Title span.News {
			color: #142435;
		}
		.News .MainContent .Right p.Title span.Event {
			color: #f5453d;
		}
		.News .MainContent .Right .More span {
			color: #142436;
			font-size: 18px;
			float: right;
			font-weight: 600;
		}
		.News .MainContent .Right .More:hover span {
			text-decoration: underline;
		}
		.News .MainContent .Right .SubWrap {
			width: 580px;
			height: 260px;
			border-bottom: 1px solid #282728;
			float: right;
			position: relative;
		}
		.News .MainContent .Right .SubWrap .Nodata {
			font-size: 1.5vw;
			width: 100%;
			text-align: center;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
		.News .MainContent .Right .SubWrap .TitleWrap {
			position: relative;
		}
		.News .MainContent .Right .SubWrap .TitleWrap .TitleLine {
			position: absolute;
			top: 50%;
			right: 65px;
			width: 120px;
			height: 1px;
			background-color: #555;
		}
		.News .MainContent .Right .SubWrap ul {
			position: relative;
			margin-top: 50px;
		}
		.News .MainContent .Right .SubWrap ul li {
			padding: 23px 0 !important;
		}
		.News .MainContent .Right .SubWrap ul li:first-child {
			padding-top: 0 !important;
		}
		.News .MainContent .Right .SubWrap ul li a {
			cursor: pointer;
			display: block;
			position: relative;
			font-size: 18px;
			width: 100%;
		}
		.News .MainContent .Right .SubWrap ul li a >* {
			float: left;
			line-height: normal;
		}
		.News .MainContent .Right .SubWrap ul li a:hover .Title {
			font-weight: 500;
			text-decoration: underline;
		}
		.News .MainContent .Right .SubWrap ul li .Type {
			color: #f5453d;
			font-weight: 400;
			width: 17%;
			font-size: 18px;
		}
		.News .MainContent .Right .SubWrap ul li .Title {
			color: #333;
			font-weight: 400;
			font-size: 18px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: 66%;
		}
		.News .MainContent .Right .SubWrap ul li .Date {
			color: #666;
			font-weight: 300;
			font-size: 18px;
			width: 17%;
			text-align: right;
		}
	
		.LayerWrap {
			z-index: 10;
			display: block;
			position: fixed;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			text-align: center;
			background-color: rgba(0, 0, 0, 0.5);
			visibility: hidden;
			opacity: 0;
			-webkit-transition: opacity 0.5s ease, visibility 0.5s ease;
			transition: opacity 0.5s ease, visibility 0.5s ease;
		}
		.LayerWrap.on {
			visibility: visible;
			opacity: 1;
			-webkit-transition: opacity 0.5s ease, visibility 0.5s ease;
			transition: opacity 0.5s ease, visibility 0.5s ease;
		}
		.LayerWrap:before {
			content: "";
			display: inline-block;
			height: 100%;
			vertical-align: middle;
			margin-right: -.25em;
		}
		.PopLayer {
			display: inline-block;
			vertical-align: middle;
			width: 1000px;
			height: auto;
			background-color:#fff;
			border: 1px solid #142435;
			z-index: 10;
		}
		.PopLayer .PopContainer {
			background-color: #fff;
		}
		.PopLayer .PopTitle {
			height: 100px;
			background-color: #142435;
			font-size: 46px;
			color: #fff;
			text-align: left;
			font-weight: 500;
			letter-spacing: -2px;
			line-height: 100px;
			padding-left: 50px;
		}
		.PopLayer .PopConts {
			min-height: 300px;
			max-height: 600px;
			overflow-y: auto;
			padding: 50px 50px 70px 50px !important;
			text-align: left;
		}
		.PopLayer .PopConts .TitleRow p {
			float: left;
		}
		.PopLayer .PopConts .TitleRow p:last-child {
			float: right;
		}
		.PopLayer .PopConts .TitleRow p.Type {
			width: 80px;
			font-size: 18px;
			color: #f5453d;
			font-weight: 700;
		}
		.PopLayer .PopConts .TitleRow p.Title {
			font-size: 24px;
			color: #142435;
			font-weight: 400;
		}
		.PopLayer .PopConts .TitleRow p.Date {
			font-size: 18px;
			color: #666666;
			font-weight: 400;
		}
		.PopLayer .PopConts .Border {
			width: 100%;
			height: 2px;
			background-color: #142435;
			margin-top: 55px;
		}
		.PopLayer .PopConts .ContentRow {
			width: 100%;
			margin: 40px 0;
			min-height: 200px;
			font-size: 18px;
			color: #555;
			font-weight: 400;
		}
		.PopLayer .PopContainer .BtnArea {
			text-align: center;
		}
		.PopLayer .PopContainer .BtnArea button {
			width: 140px;
			height: 56px;
			color: #fff;
			font-size: 24px;
			font-weight: 400;
			background-color: #142435;
		}
		.PopLayer .PopContainer .BtnArea button:hover {
			background-color: #304356;
		}
	
		@media only screen and (max-width : 1320px) {
			.SubContainer,
			.Header .HeaderArea {
				min-width: 95%;
				max-width: 95%;
			}
		
			.Brand {
				height: 53vw;
			}
			.Brand img.Bg {
				position: absolute;
				bottom: 0;
				left: 62vw;
				width: 38vw;
				height: 27vw;
			}
			.Brand .Slide .slick-list {
				height: 53vw;
			}
			.Brand .Slide .PageContainer .PageWrap {
				position: absolute;
				left: 53vw;
				top: 9vw;
				width: 32vw;
			}
			.Brand .Slide .PageContainer .PageWrap p.TitleTop,
			.Brand .Slide .PageContainer .PageWrap p.TitleBottom {
				font-size: 45px;
				line-height: 45px;
			}
			.Brand .Slide .PageContainer .PageWrap p.Desc {
				width: unset;
				font-size: 15px;
				margin-top: 23px;
				line-height: 21px;
				word-break: keep-all;
			}
			.Brand .BgContainer {
				z-index: 3;
				position: absolute;
				top: 30%;
				transform: translateY(-30%);
				left: 0;
				width: 50%;
				height: 73%;
			}
			.Brand .BgContainer .Brand1,
			.Brand .BgContainer .Brand2,
			.Brand .BgContainer .Brand3 {
				background-size: cover;
			}
			
			.Menu {
				height: 710px;
			}
			.Menu ul.NavContainer {
				top: 160px;
			}
			.Menu .Slide .slick-list {
				height: 710px;
			}
			.Menu .Slide .PageContainer .PageWrap p.Title {
				font-size: 35px;
				margin-bottom: 20px;
			}
			.Menu .Slide .PageContainer .PageWrap p.TitleSub {
				font-size: 20px;
				margin: 23px 0 15px 0;
			}
			.Menu .Slide .PageContainer .PageWrap {
				top: 200px;
				width: 87%;
			}
			.Menu .Slide .PageContainer .PageWrap p.Desc {
				font-size: 15px;
				line-height: 25px;
			}
			.Menu .Slide .PageContainer .PageWrap .MenuWrap div:first-child {
				width: 50%;
			}
			.Menu .Slide .PageContainer .PageWrap p.MenuTitle {
				font-size: 15px;
				line-height: 25px;
			}
			.Menu .Right {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				right: 0;
				width: 50%;
				height: 45vw;
			}
			.Menu .Right .Wrap {
				position: relative;
				margin: 6% 10%;
			}
			.Menu .Right .Wrap .Element .Title {
				font-size: 15px;
			}
			.Menu .Right .Wrap .Element .Content {
				font-size: 15px;
			}
			.Menu .Right .Wrap .Element .Sub {
				font-size: 12px;
				word-break: keep-all;
			}
			.Menu .Right .Wrap .Element .Desc {
				font-size: 15px;
				max-width: 100%;
				line-height: 24px;
				margin-top: 20px;
			}
			.Menu .Right .Wrap .Element img {
				max-width: 50%;
			}
			.Menu .Right .Wrap .Element:nth-child(1) img {
				max-width: 55%;
				margin: 2vw 0 0 7.5vw !important;
			}
			.Menu .Right .Wrap .Element:nth-child(1) p.FirstRow {
				margin-top: 2vw !important;
			}
			.Menu .Right .Wrap .Element:nth-child(2) img {
				max-width: 60%;
				margin: 3vw 0 0 9vw !important;
			}
			.Menu .Right .Wrap .Element:nth-child(2) p.FirstRow {
				margin-top: 2.2vw !important;
			}
			.Menu .Right .Wrap .Element:nth-child(3) img {
				max-width: 45%;
				margin: 3.4vw 0 0 10.5vw !important;
			}
			.Menu .Right .Wrap .Element:nth-child(3) p.FirstRow {
				margin-top: 2.85vw !important;
			}
			.Menu .Right .Wrap .Element:nth-child(4) img {
				margin: 4.5vw 0 0 9.5vw !important;
			}
			.Menu .Right .Wrap .Element:nth-child(4) p.FirstRow {
				margin-top: 4.6vw !important;
			}
			.Menu .Right .Wrap .Element:nth-child(5) img {
				margin: 1vw 0 0 9vw !important;
			}
			.Menu .Right .Wrap .Element:nth-child(5) p.FirstRow {
				margin-top: 2.6vw !important;
			}
			
			.Store {
				height: 38vw;
			}
			// .Store .ImgContainer {
			.Store img {
				width: 50%;
				height: unset;
			}
			.Store .SubContainer .Desc {
				display: none;
			}
			.Store .SubContainer .MainContent .Content {
				margin: 14vw 0 0 4vw;
			}
			.Store .SubContainer .MainContent .Content p.TitleTop {
				font-size: 3vw;
				color: #142435;
				line-height: 3vw;
			}
			.Store .SubContainer .MainContent .Content p.TitleBottom {
				font-size: 3vw;
				line-height: 3vw;
			}
			.Store .SubContainer .MainContent .Content .Desc {
				display: block;
				font-size: 2vw;
				margin-top: 3vw;
			}
		
			.With {
				height: 23vw
			}
			.With .Wrap .Left {
				width: 47%;
				height: 9vw;
				display: table-cell;
				vertical-align: middle;
			}
			.With .Wrap .Left p.Title {
				font-size: 4vw;
				line-height: 4vw;
			}
			.With .Wrap .Left p.Desc1 {
				font-size: 1.65vw;
				line-height: 18px;
			}
			.With .Wrap .Left p.Desc2 {
				font-size: 2.1vw;
				line-height: 23px;
			}
			.With .Wrap .Left p.Desc3 {
				font-size: 1.2vw;
				line-height: 1.2vw;
			}
			.With .Wrap .Right button {
				width: 13vw;
				height: 13vw;
			}
			.With .Wrap .Right button img {
				max-width: 30%;
			}
			.With .Wrap .Right button p {
				font-size: 2vw;
			}
		
			.News {
				height: 30vw;
			}
			.News .MainContent .Left {
				width: 50%;
			}
			.News .MainContent .Left img {
				width: 100%;
			}
			.News .MainContent .Left .TitleWrap {
				left: 22px;
			}
			.News .TitleWrap p:first-child {
				font-size: 2.8vw;
				line-height: 2.8vw;
			}
			.News .TitleWrap p:last-child {
				font-size: 3.8vw;
				line-height: 3.8vw;
			}
			.News .MainContent .Right p.Title {
				font-size: 3vw;
				line-height: 1.5vw;
			}
			.News .MainContent .Right .More span {
				font-size: 1.5vw;
				margin-top: 0.1vw;
			}
			.News .MainContent .Right .More:hover span {
				text-decoration: underline;
			}
			.News .MainContent .Right {
				width: 100%;
			}
			.News .MainContent .Right .SubWrap {
				width: 90%;
				height: 18.5vw;
			}
			.News .MainContent .Right .SubWrap ul {
				margin-top: 3vw;
			}
			.News .MainContent .Right .SubWrap ul li a,
			.News .MainContent .Right .SubWrap ul li .Type,
			.News .MainContent .Right .SubWrap ul li .Title,
			.News .MainContent .Right .SubWrap ul li .Date {
				font-size: 1.5vw;
			}
			.News .MainContent .Right .SubWrap .TitleWrap .TitleLine {
				width: 15vw;
				right: 5vw;
			}
		}
	}
	
	@media only screen and (max-width : 1023px) {
		.PC { display: none !important; }

		.Anchor {
			display: block;
			visibility: hidden;
			height: 25.2vw;
			margin-top: -25.2vw;
		}
		.Anchor:first-child {
			margin-top: 0;
		}
	
		.Container {
			overflow-y: auto;
			width: 100%;
			height: 100vh;
			scroll-behavior: smooth;
			-webkit-overflow-scrolling: touch;
		}

		.SubContainer {
			min-width: 87%;
			max-width: 87%;
			height: 100%;
			overflow: hidden;
			margin: 0 auto;
			position: relative;
		}
	
		.MainContent {
			min-width: 90%;
			max-width: 90%;
			height: 100%;
			overflow: hidden;
			margin: 0 auto;
			position: relative;
		}
	
		.Header {
			position: fixed;
			top: 0;
			width: 100%;
			background-color: #fff;;
			z-index: 9;
		}
		.Header .HeaderArea {
			min-width: 100vw;
			max-width: 100vw;
			height: 100%;
			margin: 0 auto;
		}
	
		.Header .HeaderArea .LogoLink {
			width: 100vw;
			height: 12vw;
			border-bottom: 2px solid #eeeeee;
			cursor: pointer;
			display: flex;
			justify-content: center;
		}
		.Header .HeaderArea a.LogoLink img.Logo {
			width: 60%;
			object-fit: contain;
		}

		.Header .HeaderArea .MenuWrap ul {
			width: 100%;
			display: table;
			line-height: 13vw
		}
		.Header .HeaderArea .MenuWrap li {
			display: table-cell;
			width: auto;
			font-size: 4vw;
			font-weight: 600;
			cursor: pointer;
			text-align: center;
		}
		.Header .HeaderArea .MenuWrap li:first-child {
			display: none;
		}
		.Header .HeaderArea .MenuWrap li:not(:last-child) a {
			color: #142436;
			-webkit-transition: color 0.5s ease;
			transition: color 0.5s ease;
		}
		.Header .HeaderArea .MenuWrap li:not(:last-child):hover a, .Header .HeaderArea .MenuWrap li:not(:last-child).select a {
			color: #f5453d;
			-webkit-transition: color 0.5s ease;
			transition: color 0.5s ease;
		}
		.Header .HeaderArea .MenuWrap li:last-child a {
			color: #fff;
		}
		.Header .HeaderArea .MenuWrap li:last-child a span {
			background-color: #f5453d;
			text-align: center;
			margin: 0;
			padding: 0 2px;
			-webkit-transition: padding 0.3s ease;
			transition: padding 0.3s ease;
		}
		.Header .HeaderArea .MenuWrap li:last-child:hover a span {
			background-color: #142436;
			-webkit-transition: background-color 0.3s linear;
			transition: background-color 0.3s linear;
		}
	
		.Home {
			position: relative;
		}
		.Home img {
			width: 100%;
		}
		.Home .slick-slide img {
			display: initial !important;
		}
		.Home .ArrowLeft {
			left: 2vw;
		}
		.Home .ArrowRight {
			right: 2vw;
		}
		.Home .ArrowLeft,
		.Home .ArrowRight {
			position: absolute;
			top: 50%;
			transform: translate(0, -50%);
			width: 8vw;
			height: 8vw;
			background: rgba(255, 255, 255, 0.15);
			border-radius: 100%;
			text-align: center;
		}
		.Home .ArrowLeft img,
		.Home .ArrowRight img {
			width: 2vw;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	
		.Story {
			position: relative;
			margin: 10vw 0 15vw 0;
		}
		.Story .Wrap {
			display: flex;
			flex-direction: column;
		}
		.Story .Wrap .TitleWrap p {
			font-size: 8vw;
			color: #f5453d;
			font-weight: 700;
			text-align: center;
			letter-spacing: -1px;
			word-break: keep-all;
		}
		.Story .Wrap .TitleWrap:last-child p {
			font-size: 5.5vw;
		}
		.Story .Wrap .TitleWrap:last-child div.Logo {
			width: 100%;
			height: 10vw;
			background-image: url('../images/brand/img-logo-story.png');
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center;

		}
		.Story .Wrap .TitleWrap:last-child p:last-child {
			color: #142435;
			font-size: 4.8vw;
			font-weight: 700;
			margin-top: 2vw;
		}
		.Story .Wrap .TitleWrap p span {
			color: #f5453d;
		}
		.Story .Wrap .TitleWrap:last-child {
			margin-top: 10vw;
		}
		.Story .Wrap .Content {
			display: flex;
			flex-direction: column;
		}
		.Story .Wrap .Content .ContentWrap {
			margin-top: 8vw;
		}
		.Story .Wrap .Content .ContentWrap .ImgWrap {
			position: relative;
		}
		.Story .Wrap .Content .ContentWrap .ImgWrap img {
			width: 100%;
		}
		.Story .Wrap .Content .ContentWrap .ImgWrap .ImgBg {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			background-color: rgb(0, 0, 0);
			opacity: 0.4;
			transition: opacity 0.5s;
		}
		.Story .Wrap .Content .ContentWrap .DescWrap {
			margin-top: 5vw;
		}
		.Story .Wrap .Content .ContentWrap .DescWrap p.Title {
			color: #142435;
			font-size: 4.8vw;
			line-height: 6.5vw;
			font-weight: 700;
			letter-spacing: -1px;
			text-align: center;
		}
		.Story .Wrap .Content .ContentWrap .DescWrap p.Title span {
			color: #f6453d;
		}
		.Story .Wrap .Content .ContentWrap .DescWrap p.Desc {
			margin-top: 3vw;
			color: #142435;
			font-size: 4vw;
			line-height: 6vw;
			font-weight: 500;
			word-break: break-word;
			text-align: justify;
		}

		.Brand {
			overflow: hidden;
			position: relative;
		}
		.Brand .SubContainer {
			min-width: 100%;
			max-width: 100%;
		}
		.Brand .MainContent {
			min-width: 100%;
			max-width: 100%;
			position: relative;
		}
		.Brand ul.NavContainer {
			position: absolute;
			left: 8.5%;
			right: 0;
			top: 4%;
			z-index: 3;
		}
		.Brand ul.NavContainer li {
			float: left;
			margin-right: 4vw;
		}
		.Brand ul.NavContainer li button {
			width: 5vw;
			height: 5vw;
			border-radius: 50%;
			border: none;
			background: rgb(255,51,51);
			opacity: 0.3;
			-webkit-transition: opacity 0.5s ease;
			transition: opacity 0.5s ease;
		}
		.Brand ul.NavContainer li button.active {
			width: 5vw;
			height: 5vw;
			border-radius: 50%;
			border: none;
			background: rgb(255,51,51);
			opacity: 1;
			-webkit-transition: opacity 0.5s ease;
			transition: opacity 0.5s ease;
		}
		.Brand .Slide {
			z-index: 2;
		}

		.Menu {
			background-color: #fff;
			background-image: url("../images/brand/bg-menu.png");
			background-repeat: no-repeat;
			background-size: 100% 62%;
			height: 230vw;
			position: relative;
		}
		.Menu ul.NavContainer {
			position: absolute;
			left: 0;
			right: 0;
			top: 6%;
			z-index: 3;
		}
		.Menu ul.NavContainer li {
			float: left;
			margin-right: 4vw;
		}
		.Menu ul.NavContainer li button {
			width: 5vw;
			height: 5vw;
			border-radius: 50%;
			border: none;
			background: rgb(255,51,51);
			opacity: 0.3;
			-webkit-transition: opacity 0.5s ease;
			transition: opacity 0.5s ease;
		}
		.Menu ul.NavContainer li button.active {
			width: 5vw;
			height: 5vw;
			border-radius: 50%;
			border: none;
			background: rgb(255,51,51);
			opacity: 1;
			-webkit-transition: opacity 0.5s ease;
			transition: opacity 0.5s ease;
		}
		.Menu .Slide {
			z-index: 2;
		}
		.Menu .Slide .PageContainer .PageWrap {
			position: relative;
			margin-top: 23vw;
			height: 68vw;
		}
		.Menu .Slide .PageContainer .PageWrap p.Title {
			font-size: 6vw;
			color: #fff;
			font-weight: 700;
			margin-bottom: 5vw;
		}
		.Menu .Slide .PageContainer .PageWrap p.TitleSub {
			font-size: 4.4vw;
			color: #fff;
			font-weight: 600;
			margin: 6vw 0 3vw 0;
		}
		.Menu .Slide .PageContainer .PageWrap p.Desc {
			font-size: 3.6vw;
			color: #fff;
			font-weight: 300;
			line-height: 5vw;
			word-break: keep-all;
		}
		.Menu .Slide .PageContainer .PageWrap .MenuWrap {
			display: block;
		}
		.Menu .Slide .PageContainer .PageWrap .MenuWrap div {
			float: left;
			width: 50%;
		}
		.Menu .Slide .PageContainer .PageWrap .MenuWrap div.Row {
			width: 100% !important;
		}
		.Menu .Slide .PageContainer .PageWrap p.MenuTitle {
			font-size: 3.5vw;
			color: #fff;
			font-weight: 400;
			line-height: 5.5vw;
			word-break: keep-all;
		}
		.Menu .Slide .PageContainer .PageWrap p.MenuTitle span {
			margin-left: 0 !important;
		}
		.Menu .Slide .PageContainer .PageWrap p.Caution {
			display: inline-block;
			font-size: 2.9vw;
			color: #7e93a9;
			font-weight: 400;
			margin-top: 2%;
			word-break: break-all;
			letter-spacing: -0.5px;
		}
		.Menu .Slide .PageContainer .PageWrap button.More {
			display: block;
			font-size: 3vw;
			color: #999;
			font-weight: 400;
			background-image: url("../images/brand/m/btn-more.png");
			background-repeat: no-repeat;
			background-position: 100%;
			width: 14vw;
			text-align: left;
			position: absolute;
			bottom: 0;
			right: 0;
		}
		.Menu .Slide .PageContainer .PageWrap button.More:hover {
			background-color: rgba(153,153,153,1);
			color: #fff;
		}
		.Menu .Right {
			width: 100%;
			height: 100%;
			background-color: #fff;
			text-align: center;
		}
		.Menu .Right .Wrap {
			position: relative;
			margin-top: 6vw;
		}
		.Menu .Right .Wrap ul {
			padding-top: 6vw;
			display: inline-block;
		}
		.Menu .Right .Wrap ul.on {
			opacity: 1;
			-webkit-transition: opacity 0.5s ease;
			transition: opacity 0.5s ease;
		}
		.Menu .Right .Wrap ul.off {
			opacity: 0;
			-webkit-transition: opacity 0.5s ease;
			transition: opacity 0.5s ease;
		}
		.Menu .Right .Wrap ul li {
			display: inline-block;
			cursor: pointer;
			position: relative;
		}
		.Menu .Right .Wrap ul li button {
			display: inline-block;
			text-align: center;
			font: normal 4vw 'Noto Sans KR';
			text-transform: uppercase;
			font-weight: 300;
			color: #142435;
			line-height: 4vw;
			padding: 0 1px;
		}
		.Menu .Right .Wrap ul li button::before {
			display: block;
			content: attr(title);
			font-weight: 600;
			height: 0;
			overflow: hidden;
			visibility: hidden;
		}
		.Menu .Right .Wrap ul li:not(:last-child) {
			padding-right: 3vw;
		}
		.Menu .Right .Wrap ul li.active button,
		.Menu .Right .Wrap ul li:hover button {
			font-weight: 600;
			border-bottom: 0.7vw solid #faa29e;
		}
		.Menu .Right .Wrap ul li .Underline {
			opacity: 0;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			height: 0.7vw;
			background-color: #faa29e;
			z-index: 1;
		}
		.Menu .Right .Wrap .ElementWrap {
			width: 100%;
			height: 100%;
			position: relative;
		}
		.Menu .Right .Wrap .Element {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}
		.Menu .Right .Wrap .Element img {
			object-fit: contain;
			width: 50%;
			margin: 5vw 0;
		}
		.Menu .Right .Wrap .Element.on {
			opacity: 1;
			-webkit-transition: opacity 0.5s ease;
			transition: opacity 0.5s ease;
		}
		.Menu .Right .Wrap .Element.off {
			opacity: 0;
			-webkit-transition: opacity 0.5s ease;
			transition: opacity 0.5s ease;
		}
		.Menu .Right .Wrap .Element p {
			margin: 0.2vw 0;
		}
		.Menu .Right .Wrap .Element .Title {
			color: #f5453d;
			font-size: 4vw;
			line-height: 4vw;
			font-weight: 600;
		}
		.Menu .Right .Wrap .Element .Content {
			color: #142435;
			font-size: 4vw;
			line-height: 4vw;
			font-weight: 600;
		}
		.Menu .Right .Wrap .Element .Sub {
			color: #142435;
			font-size: 3vw;
			font-weight: 300;
			line-height: 3vw;
			display: block;
			margin: 1.6vw 0;
		}
		.Menu .Right .Wrap .Element .Desc {
			font-size: 4vw;
			color: #555;
			font-weight: 300;
			line-height: 5.5vw;
			margin-top: 3vw;
			word-break: keep-all;
		}
		.Menu .Right .Wrap .Element:nth-child(1) p.FirstRow {
			margin-top: 4vw !important;
		}
		.Menu .Right .Wrap .Element:nth-child(2) img {
			margin-top: 1vw !important;
		}
		.Menu .Right .Wrap .Element:nth-child(2) p.FirstRow {
			margin-top: 3.6vw !important;
		}
		.Menu .Right .Wrap .Element:nth-child(3) p.FirstRow {
			margin-top: 2.9vw !important;
		}
		.Menu .Right .Wrap .Element:nth-child(4) p.FirstRow {
			margin-top: 1.3vw !important;
		}
		.Menu .Right .Wrap .Element:nth-child(5) img {
			margin-top: 10vw !important;
		}
		.Menu .Right .Wrap .Element:nth-child(5) p.FirstRow {
			margin-top: 6vw !important;
		}
		.Menu .Right .Wrap .Element:nth-child(6) img {
			margin-top: 10vw !important;
		}
		.Menu .Right .Wrap .Element:nth-child(6) p.FirstRow {
			margin-top: 6vw !important;
		}
		.Menu .Right .Wrap .Element:nth-child(7) img {
			margin-top: 10vw !important;
		}
		.Menu .Right .Wrap .Element:nth-child(7) p.FirstRow {
			margin-top: 6vw !important;
		}
	
		.Store {
			// height: 105vw;
			position: relative;
			background-color: #eee;
		}
		.Store .SubContainer {
			padding-top: 10vw;
			padding-bottom: 10vw;
		}
		.Store img {
			// display: none;
			object-fit: contain;
			width: 100%;
		}
		.Store .SubContainer .MainContent .Content {
			margin-top: 5vw;
		}
		.Store .SubContainer .MainContent .Content p.Title {
			font-size: 4vw;
			color: #142435;
			line-height: 7vw;
			letter-spacing: 0px;
			font-weight: 500;
		}
		.Store .SubContainer .MainContent .Content p.Title.Bold {
			font-weight: 700;
		}
		.Store .SubContainer .MainContent .Content .Location {
			font-size: 4vw;
			line-height: 7vw;
			font-weight: 600;
			color: #fff;
			background-color: #f5453d;
			text-align: center;
			width: 24vw;
			height: 7vw;
			border-radius: 30px;
			display: inline-block;
			margin-top: 5vw;
		}
		.Store .SubContainer .Desc {
			display: block;
			position: absolute;
			bottom: 13vw;
			right: 0;
			font-size: 3.5vw;
			line-height: 8vw;
			color: #fff;
			font-weight: 600;
			letter-spacing: -1px;
			background-color: #142435;
			width: 48vw;
			height: 8vw;
			text-align: center;
		}
		.Store .SubContainer .MainContent .Content .Desc {
			display: none;
		}
	
		.With {
			background-color: #fcf8ef;
			height: 75vw;
		}
		.With .Wrap {
			position: absolute;
			left: 0;
			right: 0;
			top: 50%;
			transform: translate(0, -50%);
		}
		.With .Wrap .Left p.Title {
			font-size: 7vw;
			color: #f5453d;
			font-weight: 700;
			line-height: 7vw;
		}
		.With .Wrap .Left p.Desc1 {
			font-size: 4vw;
			color: #333333;
			font-weight: 400;
			line-height: 4vw;
			letter-spacing: 0px;
			margin-top: 2vw;
		}
		.With .Wrap .Left p.Desc2 {
			font-size: 4.5vw;
			color: #142435;
			font-weight: 700;
			line-height: 4.5vw;
			margin-top: 2vw;
		}
		.With .Wrap .Left p.Desc3 {
			font-size: 3.5vw;
			color: #142435;
			font-weight: 400;
			line-height: 3.5vw;
			margin-top: 1.5vw;
		}
		.With .Wrap .Right {
			margin-top: 6vw;
			text-align: center;
		}
		.With .Wrap .Right button {
			background-color: #142435;
			width: 22vw;
			height: 22vw;
			border-radius: 50%;
			transition: background-color 0.5s ease;
		}
		.With .Wrap .Right button:hover {
			background-color: #f5453d;
		}
		.With .Wrap .Right button p {
			font-size: 3.5vw;
			color: #fff;
			font-weight: 500;
			margin-top: 1vw;
		}
		.With .Wrap .Right button img {
			width: 7vw;
		}
		.With .Wrap .Right button.Franchise {
			float: left;
		}
		.With .Wrap .Right button.Consulting {
			float: right;
		}
	
		.News {
			background-color: #fff;
		}
		.News .SubContainer {
			margin-top: 10vw;
			margin-bottom: 12vw;
		}
		.News .Left img {
			width: 100%;
			object-fit: contain;
		}
		.News .Left .TitleWrap {
			position: absolute;
			top: 50%;
			left: 4vw;
			transform: translate(0, -50%);
		}
		.News .TitleWrap p:first-child {
			font-size: 5vw;
			font-weight: 300;
			line-height: 5vw;
			color: #fff;
		}
		.News .TitleWrap p:last-child {
			margin-top: 1vw;
			font-size: 7vw;
			font-weight: 700;
			line-height: 7vw;
			color: #fff;
		}
		.News .Right {
			display: inline-block;
			width: 100%;
			margin-top: 7vw;
		}
		.News .Right p.Title {
			font-size: 7vw;
			font-weight: 700;
			line-height: 7vw;
		}
		.News .Right p.Title span.News {
			color: #142435;
		}
		.News .Right p.Title span.Event {
			color: #f5453d;
		}
		.News .Right .More span {
			font-size: 3.2vw;
			color: #142435;
			float: right;
		}
		.News .Right .SubWrap {
			margin: 0 !important;
			border-bottom: 1px solid #282728;
			position: relative;
		}
		.News .Right .SubWrap .Nodata {
			font-size: 4vw;
			width: 100%;
			text-align: center;
			position: absolute;
			top: 55%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
		.News .Right .SubWrap .TitleWrap {
			position: relative;
		}
		.News .Right .SubWrap .TitleWrap .TitleLine {
			position: absolute;
			top: 50%;
			right: 11vw;
			width: 17vw;
			height: 1px;
			background-color: #555;
		}
		.News .Right .SubWrap ul {
			margin: 5vw 0;
			min-height: 20vw;
		}
		.News .Right .SubWrap ul li {
			padding: 4vw 0 !important;
		}
		.News .Right .SubWrap ul li:first-child {
			padding-top: 0 !important;
		}
		.News .Right .SubWrap ul li a {
			cursor: pointer;
			display: block;
			position: relative;
			font-size: 4vw;
			width: 100%;
		}
		.News .Right .SubWrap ul li a >* {
			float: left;
			line-height: normal;
		}
		.News .Right .SubWrap ul li a:hover .Title {
			font-weight: 500;
		}
		.News .Right .SubWrap ul li .Type {
			color: #f5453d;
			font-weight: 400;
			width: 20%;
			font-size: 3.5vw;
			line-height: 4vw;
		}
		.News .Right .SubWrap ul li .Title {
			color: #333;
			font-weight: 400;
			font-size: 4vw;
			line-height: 4vw;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: 80%;
		}
		.News .Right .SubWrap ul li .Date {
			display: none;
		}
	
		.LayerWrap {
			z-index: 10;
			display: block;
			position: fixed;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			text-align: center;
			background-color: rgba(0, 0, 0, 0.5);
			visibility: hidden;
			opacity: 0;
			-webkit-transition: opacity 0.5s ease, visibility 0.5s ease;
			transition: opacity 0.5s ease, visibility 0.5s ease;
		}
		.LayerWrap.on {
			visibility: visible;
			opacity: 1;
			-webkit-transition: opacity 0.5s ease, visibility 0.5s ease;
			transition: opacity 0.5s ease, visibility 0.5s ease;
		}
		.LayerWrap:before {
			content: "";
			display: inline-block;
			height: 100%;
			vertical-align: middle;
			margin-right: -.25em;
		}
		.PopLayer {
			display: inline-block;
			vertical-align: middle;
			min-width: 90%;
			max-width: 90%;
			height: auto;
			background-color:#fff;
			border: 1px solid #142435;
			z-index: 10;
		}
		.PopLayer .PopContainer {
			background-color: #fff;
		}
		.PopLayer .PopTitle {
			height: 15vw;
			background-color: #142435;
			font-size: 7vw;
			color: #fff;
			text-align: left;
			font-weight: 500;
			letter-spacing: -2px;
			line-height: 15vw;
			padding-left: 3vw;
		}
		.PopLayer .PopConts {
			min-height: 50vh;
			max-height: 70vh;
			overflow-y: auto;
			padding: 5vw 3vw !important;
			text-align: left;
		}
		.PopLayer .PopConts .TitleRow p {
			float: left;
		}
		.PopLayer .PopConts .TitleRow p:last-child {
			float: right;
		}
		.PopLayer .PopConts .TitleRow p.Type {
			width: 17%;
			font-size: 3vw;
			line-height: 4vw;
			color: #f5453d;
			font-weight: 700;
		}
		.PopLayer .PopConts .TitleRow p.Title {
			width: 63%;
			font-size: 4vw;
			line-height: 4vw;
			color: #142435;
			font-weight: 400;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		.PopLayer .PopConts .TitleRow p.Date {
			width: 20%;
			font-size: 3vw;
			line-height: 4vw;
			color: #666666;
			font-weight: 400;
			text-align: right;
		}
		.PopLayer .PopConts .Border {
			width: 100%;
			height: 2px;
			background-color: #142435;
			margin-top: 7vw;
		}
		.PopLayer .PopConts .ContentRow {
			width: 100%;
			margin: 5vw 0;
			min-height: 30vw;
			font-size: 4vw; 
			line-height: 4vw;
			color: #555;
			font-weight: 400;
		}
		.PopLayer .PopContainer .BtnArea {
			text-align: center;
		}
		.PopLayer .PopContainer .BtnArea button {
			width: 20vw;
			height: 9vw;
			color: #fff;
			font-size: 4vw;
			font-weight: 400;
			background-color: #142435;
		}
		.PopLayer .PopContainer .BtnArea button:hover {
			background-color: #304356;
		}
	}
}
